<template>
    <div>
      <label>CompanyManagement</label>
    </div>
</template>

<script>
    export default {
       name: 'CompanyManagement',
       props: {
       msg: String
     }   
    }
</script>

<style lang="scss" scoped>

</style>