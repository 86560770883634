<template>
  <div class="container-table">
    <div class="company-title only-desktop">
            <img :src="mediaURL + company.logoPath"  class="logo-company-header"/>
            <span>
                <img src="@/assets/images/globe.svg" class="img-globe">
                <label>{{ company.name}} </label>
            </span>
            <span >
            <div @click="BackAllPlans()">
               <label class="lable-back">חזרה לכל התוכניות</label>
            </div>
               <button class="del-btn-ideal" @click="deletePlan()">מחיקה</button>                
               <button class="btn-ideal" @click="savePlan()">שמירה</button>  
            </span>   
    </div>
    <div class="company-title only-mobile">
        <span  @click="BackAllPlans()">
            <img src="@/assets/images/arrow-black.svg" class="arrow-black">
        </span>   
        <img :src="mediaURL + company.logoPath"  class="logo-company-header"/>
          <button class="del-btn-ideal" @click="deletePlan()">x
         <!--  <img src="@/assets/images/delete-row.svg" >-->
          </button>                
          <button class="btn-ideal" @click="savePlan()">
          v
           </button>  
    </div>
    <div class="form-container">
    <UICollapsiblePanel title="סוג תשלום" class="agent-details">
      <div class="container-input">
             <div > 
               <div class="selectBox">
                 <DxSelectBox
                   :items="feeList"
                   :key="componentKey"
                   :search-enabled="true"
                   searchExpr="feeName"
                   placeholder="בחר סוג תשלום"
                   :rtl-enabled="true"
                   v-model:value="plan.feeID"
                   value-expr="feeID"
                   display-expr="feeName"
                   :fullScreen="false"
                   width="250"
                   drop-down-button-template="imageIcon"
                >
                <template #imageIcon="{}">
                  <img
                   src="@/assets/images/chevron-down.svg"
                    class="custom-icon"
                  >
                </template>
              </DxSelectBox> 
             </div>
          </div>
      </div>
    </UICollapsiblePanel>
     <UICollapsiblePanel title="פרטי תוכנית" class="agent-details">
     <div class="container-input">
            <div >
              <label>שם תוכנית</label>
              <input v-model="plan.name" placeholder="הכנס שם תוכנית"/>
            </div>
             <div >
              <label>תיאור </label>
              <input v-model="plan.description" placeholder="תיאור"/>
            </div>
             <div >
              <label>מספר קוים מקסימום </label>
              <input v-model="plan.maximumNumberOfLines" placeholder="הכנס מספר קוים מקסחמלי"/>
            </div>
             <div >
              <label>מחיר </label>
              <input v-model="plan.price" placeholder="הכנס מחיר"/>
            </div>
             <div >
              <label>קוד באתר חברת הסלולאר </label>
              <input v-model="plan.websiteCode" placeholder="הכנס קוד באתר חברת הסלולאר"/>
            </div>
             <div >
              <label>תגמול לנציג לפי קוד </label>
              <input v-model="plan.rewardCode" placeholder="הכנס תגמול לנציג לפי קוד"/>
            </div> 
             <span v-if="plan.isKosher" class="radio-checkbox">
               <img src="@/assets/images/checked.svg"  @click="AddNote()" >
               <label>מסלול כשר</label>
             </span>   
     </div>
    </UICollapsiblePanel>
    <UICollapsiblePanel title="דגשים" class="agent-details">
       <div class="container-inp">
            <div  v-for="(item,index) in boldPoints" :key="index" class="container-input" >
               <div >
                  <label>הדגש מספר {{index+1}}</label>
                  <div>
                    <input v-model="item.value" :placeholder="'הדגש מספר '+ (index+1)"/> 
                    <button >
                      <img src="@/assets/images/delete-row.svg"  @click="delBoldPoint(index, $event)"/>
                    </button> 
                  </div>
               </div>
            </div>
             <button  @click="AddBoldPoint()" class="btn-add">
               <img src="@/assets/images/fi_plus.png" class="plus">
            </button>
       </div>     
    </UICollapsiblePanel>
    <UICollapsiblePanel title="הערות" class="agent-details" >
      <div class="container-inp">
           <div  v-for="(item,index) in listToNotes" :key="index" class="container-input" >
              <div>
                  <label>הערה מספר {{index+1}}</label>
                  <div>
                    <input v-model="item.value" :placeholder="'הערה מספר '+ (index+1)"/> 
                    <button >
                       <img src="@/assets/images/delete-row.svg"  @click="delNote(index, $event)"/>
                    </button>  
                  </div>
             </div>
           </div>
          <button  @click="AddNote()" class="btn-add">
            <img src="@/assets/images/fi_plus.png" class="plus">
          </button>
       </div>   
    </UICollapsiblePanel>
   </div>
  </div>
</template>

<script>
import UICollapsiblePanel from "../UIComponents/UICollapsiblePanel.vue";
import axios from "axios";
import { DxLookup,DxDropDownOptions  } from "devextreme-vue/lookup";

import { DxSelectBox } from 'devextreme-vue/select-box';



  export default {
       components: { UICollapsiblePanel, DxSelectBox},
       title:'פרטי תוכניות',
       name: 'PlansDetails',
       props:{
        msg: String,
        planID:Number,
        companyID:Number
    },
   emits: ['saved','deleted','update','duplicate'],
    data() {
      return {
        mediaURL:process.env.VUE_APP_BASE_MEDIA_URL ,
        company:{},
        feeList: [],
        plan:{},
        listToNotes:[],
        // FormIndex:1,
        boldPoints:[],
        componentKey: 0,
        duplicate:0
      }
    },
    mounted() {
      this.getPlanDetails(); 
      console.log(this.plan);
      this.getAllFees();
    },
    methods: {
       getPlanDetails(){
         let planID=this.planID;
         if (planID>0)
          {          
            axios.get(process.env.VUE_APP_BASE_URL + `/Plan/GetPlan/${planID}`)
                      .then(res => {
                        this.plan = res.data;
                        this.company = res.data.company;
                        this.listToNotes=res.data.notes.map(x=>({ value: x }));
                        this.boldPoints=res.data.boldPoints.map(x=>({ value: x }));
                        this.plan.company=null;
                        if (this.$route.query.duplicate==1){                 
                              this.$router.push({ query: { duplicate  : this.duplicate} }); 
                              console.log("duplicatePlan");
                              console.log( this.$route.query.duplicate);
                              this.duplicatePlan();
                        }
                  });  
          }
          else {
                 
                axios.get(process.env.VUE_APP_BASE_URL + `/Company/GetCompany/${this.companyID}`)
                      .then(res => {
                        this.company = res.data;
                          console.log( this.company);   
                  });  
                  this.plan={ 
                            name:'',
                            description:'',
                            maximumNumberOfLines:null,
                            price:null,
                            websiteCode:'',
                            rewardCode:'',
                            companyID:this.companyID,
                            planID:0
                  };
          }
      this.componentKey++;

    },
    savePlan(){
           this.plan.notes=this.listToNotes.map(x=>x.value);
           this.plan.boldPoints=this.boldPoints.map(x=>x.value);
           if(this.plan.planID>0)
           {
            axios.put(process.env.VUE_APP_BASE_URL + "/Plan/PutPlan", this.plan)
                .then((res)=>{
                   this.$emit('saved');
                  console.log( res.data);
                    })
                  .catch(
                   (e)=>{   
                  });
           }
           else{
              axios.post(process.env.VUE_APP_BASE_URL + "/Plan/PostPlan",this.plan) 
                .then((res)=>{
                  console.log( res.data);
                   this.$emit('saved');
                  
                  })
                .catch(
                  (e)=>{
                   console.log("error plan");
             });

           }
     
    },
     getAllFees() {
      axios.get(process.env.VUE_APP_BASE_URL + "/Fee/GetAllFees").then((res) => {
        console.log(res);
        this.feeList = res.data;
        this.componentKey++;
      });
    },
     BackAllPlans(){
        this.$emit('update');
    },
    duplicatePlan(){
        this.plan.name='';
        this.plan.planID=0;
        this.plan.websiteCode='';    
    },
     deletePlan(){
        let planID=this.planID;  
         console.log(this.planID);

           axios.delete(process.env.VUE_APP_BASE_URL + `/Plan/DeletePlan/${planID}`)
           .then((res)=>{
             this.$emit('deleted');
             console.log( res.data);
               })
             .catch(
              (e)=>{   
             });
     },
     AddNote(){
       console.log("ADD NOTE");
        this.listToNotes.push({ value:''});       
      },
       AddBoldPoint(){
       console.log("ADD NOTE");
        this.boldPoints.push({ value:''});
      },
      delNote(index){
        this.listToNotes.splice(index, 1);
      },
      delBoldPoint(index){
        this.boldPoints.splice(index, 1);
      }  
      },
      
  }
</script>

<style lang="scss" scoped>

</style>