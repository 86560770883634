<template>
    <div class="container-content">
         <UISelect  placeholder="בחירת חברה" ref="companiesSelect" :list="companies" 
             v-model:val="company.name" v-model:value="company.companyID" :key="index"  @update:value="RefreshComponent(company)">
        </UISelect>
        <img src="@/assets/images/export.svg"  @click="uploadFile()" > 
        <img src="@/assets/images/excel-file.svg" class="exel-file">   
        <label  v-if="selected" class="ideal">תגמול  {{company.name}}</label> 
        <div class="container-btn">
          <button class="btn-ideal" @click="addCompany()">בדיקת התאמה</button>
        </div>
        <DxPopup
      v-model:visible="popupVisible"
      :drag-enabled="false"
      :close-on-outside-click="true"
      :show-close-button="false" 
      :show-title="true"
      :width="300"
      :height="400"
      container=".id-popup"
      title="העלה קובץ" 
       rtl-enabled="true"
    >
      <p>
        שם:
        <input>
      </p>
      <p>
      <DxFileUploader
        :rtl-enabled="true"
        :show-file-list="false"
        :upload-url="uploadURL + uploadQueryURL"
         @uploaded='fileReceived'
      />
      <img v-if="uploaded" :src="uploadAddress" style="max-width:200px;max-height:200px" />
       </p>
      <div class="btn-popup">
         <button @click="closePopup()"> סגור</button>
         <button @click="addNewCompany()"> הוסף</button>
      </div>
    </DxPopup>
    </div>
</template>

<script>
import UISelect from "./UIComponents/UISelect.vue";
import { DxPopup } from 'devextreme-vue/popup';
import { DxFileUploader } from 'devextreme-vue/file-uploader';

import axios from "axios";

    export default {
       title: "ניהול תגמול",
       components: { UISelect,DxFileUploader,DxPopup },
       name: 'RemunerationManagement',
       props: {
       msg: String
     },
     data() {
         return {
        companies:[],
        company:{
          companyID:null,
          name:""
        },
      
        selected:false,
         popupVisible:false,
         index:1,
         componentKey:0,
         uploadURL:process.env.VUE_APP_BASE_URL,
         uploadQueryURL:"/FileUploader/Upload", 
         uploaded:false, 
         uploadAddress:'',     
           }
     },
     mounted() {
         this.getAllCompanies();
     },
     methods: {
      RefreshComponent(company){
       console.log("RefreshComponent");
       console.log(company);
       this.company.name=company.text;
       console.log(company);
       this.index++;
       this.selected=true;
    },
      fileReceived(e){ 
            this.uploaded=true;
            this.uploadAddress=process.env.VUE_APP_BASE_MEDIA_URL + e.request.responseText; 
            console.log(this.uploadAddress);
            this.newCompany.logoPath=e.request.responseText;
            console.log(this.logoPath);           
         },
     uploadFile(){
        this.popupVisible=true;
     },
    closePopup(){
       this.popupVisible=false;
     },
     addCompany(){
       this.popupVisible=true;
     },
      getAllCompanies() {
      axios.get(process.env.VUE_APP_BASE_URL + "/Company/GetAllCompanies").then((res) => {
        this.companies = res.data.map(function (s) {
          return { text: s.name, key: s.companyID };
        });
          console.log(this.companies );
           this.index++;
      });
    },
     },       
    }
</script>

<style lang="scss" scoped>

</style>