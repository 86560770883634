import { createApp, resolveTransitionHooks } from 'vue'
import App from './App.vue'
import Login from './components/Loginnn/Login.vue'
import Layout from './components/Layout.vue'
import Register from './components/Register.vue'
import HelloWorld from './components/HelloWorld.vue'
import NewSale from './components/NewSale.vue'
import Index from './components/Index.vue'
import ResetPassword from './components/Loginnn/ResetPassword.vue'
import ConfirimEmail from './components/ConfirimEmail.vue'
import NewPassword from './components/NewPassword.vue'
import MySales from './components/MySales.vue'
import RewardAndExpectation from './components/RewardAndExpectation.vue'
import MainPlans from './components/MainPlans.vue'
import Forms from './components/Forms.vue'
import InformationAndActions from './components/InformationAndActions.vue'
import Settings from './components/Settings.vue'
import Links from './components/Links.vue'
import InventoryManagement from './components/InventoryManagement.vue'
import BackOffice from './components/BackOffice.vue'
import BackOfficeSales from './components/BackOfficeSales.vue'
import UpsAndDowns from './components/UpsAndDowns.vue'
import Plans from './components/PlansEditing/Plans.vue'
import UserManagement from './components/UserManagement.vue'
import RewardPatterns from './components/RewardTemplates/RewardPatterns.vue'
import RemunerationManagement from './components/RemunerationManagement.vue'
import Messages from './components/Messages.vue'
import CompanyManagement from './components/CompanyManagement.vue'
import OrderManagement from './components/OrderManagement.vue'
import Company from './components/Company.vue'
import SaleDetails from './components/SaleDetails.vue'
import PersonalInformation from './components/PersonalInformation.vue'
import Contact from './components/Contact.vue'



    import { createRouter, createWebHistory } from "vue-router"
    const routeInfos = [
        {
            path : "/Login",
            components : {
                login:Login
            }
        }, 
        {
            path : "/",
            components : {
                index: Index
            }
        }, 
        {
            path : "/ConfirimEmail",
            components : {
                confirimEmail: ConfirimEmail
            }
        }, 
        {
            path : "/Register",
            components : {
                register : Register
            }
        },
        {
            path : "/NewPassword",
            components : {
                newPassword: NewPassword
            }
        },  
        {
            path : "/ResetPassword",
            components : {
                resetPassword : ResetPassword
            }
        }, 
        {
            path : "/app",
            components : {
                app: Layout
            },
            children: [
                   { path: 'personalInformation', component: PersonalInformation
                    },
                   { path: 'Contact', component: Contact
                    },
                   { path: 'hello', component: HelloWorld
                    ,props : {msg:"HELLLO!" }
                        },
                   {
                     path: 'sendIktov', component: NewSale
                    ,props : {activeIcon:"sale"}
                    },
                   { 
                     path: 'mySales', component: MySales
                    ,props : {activeIcon:"mySales"}
                    },
                    {
                        path:'backOfficeSales', component: BackOfficeSales 
                    },
                    {
                        path : 'SaleDetails', component: SaleDetails
                
                   },
                   {
                     path: 'rewardAndExpectation', component: RewardAndExpectation
                    ,props : {activeIcon:"rewardAndExpectation"}
                   },
                   { 
                    path: 'mainPlans', component: MainPlans
                    ,props : {activeIcon:"MainPlans"}
                   },
                   {
                        path : 'Company', component: Company
                        ,props : {activeIcon:"MainPlans"}
                   },
                   {
                    path: 'forms', component: Forms
                   ,props : {activeIcon:"forms"}
                   },
                  
                   {
                    path: 'informationAndActions', component: InformationAndActions
                   ,props : {activeIcon:"informationAndActions"}
                   },
                   {
                    path: 'settings', component: Settings,
                     children:[
                     {
                      path:'backOffice', component: BackOffice ,
                     },
                    
                     {
                      path:'upsAndDowns', component: UpsAndDowns 
                     },
                     {
                      path:'plans', component: Plans 
                     },
                     {
                      path:'userManagement', component: UserManagement 
                     },
                     {
                      path:'rewardPatterns', component: RewardPatterns 
                     },
                     {
                      path:'remunerationManagement', component: RemunerationManagement 
                     }, 
                     {
                      path:'messages', component: Messages 
                     },
                     {
                      path:'companyManagement', component: CompanyManagement 
                     },
                     {
                      path:'orderManagement', component: OrderManagement 
                     }
                   ]
                   },
                   {
                    path: 'links', component: Links
                   ,props : {msg:"links"}
                   }, 
                   {
                    path: 'inventoryManagement', component: InventoryManagement
                   ,props : {msg:"inventoryManagement"}
                   },                                                    
                            
              ]
          },
          {
            path : "/NewSale",
            components : {
                newSale : NewSale
            }
        }, 
      
    ]
    
    const router = createRouter({
        history : createWebHistory(),
        routes : routeInfos
    })
 
    router.beforeEach((to, from, next) => {
       if (to.path.startsWith("/app") &&
        router.loggedInInfo.isLogged === false  ) next({ name: 'Index' })
        else next()
    })
        
    export default router;