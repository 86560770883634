<template>    
  <div>
    <router-view name="index"/>
    <router-view name="login"/>
    <router-view name="register"/>
    <router-view name="app"/>
    <router-view name="newSale"/>
    <router-view name="resetPassword"/>
    <router-view name="confirimEmail"/>
    <router-view name="newPassword"/>
</div>
</template>

<script> 
import FormLogin from './components/Register.vue'
export default {
  name: 'App',
  components: {
   
  },
   
   

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50; 
}
</style>
