<template>
       <div class="content-container">
        <div class="header-container">
           <label class="main-title">טפסים להורדה ולהדפסה</label>
           <button class="btn-idl only-desktop" v-if="!isEditMode  && edit" @click="ChangeMode()">
               <img src="@/assets/images/edit.svg" class="edit">
                ערוך
           </button>
           <button class="btn-idl only-desktop" v-if="isEditMode  && edit" @click="addForm()">
               <img src="@/assets/images/fi_plus.png" class="plus">
               טופס חדש
           </button>
           <button class="btn-idl only-mobile" v-if="!isEditMode  && edit" @click="ChangeMode()">
               <img src="@/assets/images/edit.svg" class="edit">
           </button>
           <button class="btn-idl only-mobile" v-if="isEditMode  && edit" @click="addForm()">
               <img src="@/assets/images/fi_plus.png" class="plus">
           </button>

           <DxPopup
               v-model:visible="popupVisible"
               :drag-enabled="false"
               :close-on-outside-click="true"
               :show-close-button="false"
               :show-title="true"
               :width="310"
               :height="280"
               :rtl-enabled="true"
              container=".id-popup"
               title="הוספת טופס" 
             >
               <p>
                 שם חברה:
                 <input v-model="newForm.name">
               </p>
               <p>
                 FilePath: 
                 <input v-model="newForm.FilePath">
               </p>
               <div class="btn-popup">
                <button @click="addNewForm()"> הוסף</button>
                <button @click="closePopup()"> סגור</button> 
               </div>
           </DxPopup>
       </div>
       <DxSortable :key="componentKey"
              :data="list"
              class="links"      
              @reorder="onDrop($event)" 
              :drag-direction="both"
              >
 
           <div v-for="form in getFormArray()" :key="form.formID">  
              <div style='background-image: url("@/assets/images/")' class="body-form">
              <button class="btn-del" @click="deleteForm(form)" v-if="edit">
                    <img src="@/assets/images/delete.svg" class="del"> 
              </button>
              </div>
              <div >
                  <img src="@/assets/images/pdf.svg" class="img-link">
                  <a class="link-name" v-if="!isEditInput">{{form.name}}</a> 
                  <input type="text" v-model="form.name" v-if="isEditInput" class="edit-input" @change="editInput()">
              </div>
           </div> 
        </DxSortable>
   </div>   
   <div  class="id-popup"></div>
</template>

<script>
import axios from "axios";
import { DxSortable} from 'devextreme-vue/sortable';
import { DxPopup } from 'devextreme-vue/popup';
import { EnumManager, UserGroupsEnum } from "../code/enums";

    export default {
      title:'טפסים',
       components: { 
          DxPopup,
          DxSortable
        },
     name: 'Forms',
     props: {
       msg: String
    },
      data(){
        return{
           forms:[],
            // userInfo: {
            //         fullName: "",
            //         groupName: ""
            //     },
           componentKey:0,
           popupVisible:false,
           isEditMode:false,
           isEditInput:false,
           newForm:{
              formId:0,
              name:'',
              FilePath:'',
              sortOrder:10000,
              imagePath:''
            },
             edit:false
        }
    },
       mounted() {
        this.getUserGroup();
        this.getAllForms();
      },
     methods: {
         getAllForms(){
              axios.get(process.env.VUE_APP_BASE_URL + "/Forms/GetAllForms/")
              .then(res => {
                this.forms = res.data;
                console.log(res.data);   
         });
         },
         getUserGroup(){
           axios.get(process.env.VUE_APP_BASE_URL + "/User/GetUserGroupLoggedIn")
              .then(res => {
                 console.log(res);
                 if(res.data==1)
                   this.edit=true;        
         });
         },
          getFormArray(){
            var res= this.forms.sort((a,b)=>a.sortOrder - b.sortOrder); 
            var index=1;
            res.forEach(element => {
               element.sortOrder=index++; 
            }); 
            return res;  
         },
         onDrop(e){
             var i;
             console.log(e);
             if (e.fromIndex<e.toIndex){
                 this.forms[e.fromIndex].sortOrder=this.forms[e.toIndex].sortOrder+0.1;
                } else if (e.fromIndex>e.toIndex) {
                 this.forms[e.fromIndex].sortOrder=this.forms[e.toIndex].sortOrder-0.1;
                }
             console.log(this.forms);
             this.updateForms();
             this.componentKey++;
         },
          ChangeMode(){
          this.isEditMode=true;
          this.isEditInput=true;
         },
         editInput(){
             this.isEditInput=false;
             this.isEditMode=false;
         },
         addForm(){
          this.popupVisible=true;
         },
         closePopup(){
           this.popupVisible=false;
         },
         deleteForm(form){
           axios.delete(process.env.VUE_APP_BASE_URL + "/Forms/DeleteForm/" + form.formID)
           .then((res)=>{
            this.getAllForms();
             console.log( res.data);

               })
             .catch(
              (e)=>{
             
            
             });
         },
          addNewForm(){
            axios.post(process.env.VUE_APP_BASE_URL + "/Forms/PostForm",this.newForm) 
             .then((res)=>{
               console.log("added form");
               console.log(res);
               this.popupVisible=false;
               this.getAllForms();
               })
             .catch(
              (e)=>{
               console.log("error form");
               console.log(e);
                   
             });
         },
         updateForms() {
                axios.put(process.env.VUE_APP_BASE_URL + "/Forms/PutForm" , this.forms)
                        .then(res => {
                            console.log('updated');
                        })
                        .catch(error => {
                            console.error(error);
                        });
            }
     }, 
      
    }
</script>

<style lang="scss" scoped>

</style>