<template>
<div :key="state">
   <companies v-if="state==1" @update:value="selectedCompany()"
    v-model:value="companyID" ></companies>
   <plans-sheet v-if="state==2" @update:value="selectedPlan()" 
     :companyID="companyID" v-model:value="planID" @update:val="BackAllCompanies()" 
   ></plans-sheet>
    <Plan-Details v-if="state==3" :companyID="companyID" :planID="planID"
     @saved="BackAllPlans()" @deleted="BackAllPlans()" @update="BackAllPlans()">
     </Plan-Details>
</div>
</template>

<script>
import Companies from './Companies.vue';
import PlanDetails from './PlanDetails.vue';
import PlansSheet from './PlansSheet.vue';


  export default {
  components: {Companies,PlanDetails, PlansSheet },
      title:'תוכניות',
       name: 'Plans',
       props:{
        msg: String
    },
     data() {
       return {
         state:1,
         companyID:0,
         planID:0,
       }
     },
     methods: {
       selectedCompany(){
          this.state=2;
     },
     selectedPlan(){
          this.state=3;
     },
     BackAllCompanies(){
        this.state=1;
     },
     BackAllPlans(){
        this.state=2;
     }
     }
    }
</script>

<style lang="scss" scoped>

</style>