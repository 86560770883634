<template>
  <div :key="componentKey">
    <div class="company-title">
        <img   :src="mediaURL + company.logoPath"  class="logo-company-header"/>
        <span class="only-desktop">
            <img src="@/assets/images/globe.svg" class="img-globe">
            <label>{{ company.name}} </label>
        </span>
        <span @click="BackToCompanies()">
            <img src="@/assets/images/arrow-black.svg" class="arrow-black">
           <label class="lable-back only-desktop">חזרה לכל החברות</label>  
        </span>   
    </div>
    <div @click="getCurrentFilter()" class="exportButton"> 
        <div>ייצוא לאקסל</div>  
        <img src="@/assets/images/exel.svg" />
    </div>
    <DxDataGrid 
       ref="dGrid"
      :data-source="dataSource"
      :show-borders="false"
      :rtlEnable="true" 
      :allowColumnResizing="true"
      :show-column-lines="false"
      :show-row-lines="true"
      :enable-grouping="true"
      :column-auto-width="true"
      :word-wrap-enabled="true"    
      :column-hiding-enabled="true"
      class="tree-list"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :selection="{ mode: 'single' }"
     
       >  
     <DxEditing
        :allow-updating="true"
        mode="cell"
      />
     <DxFilterRow :visible="true" />
      <DxSearchPanel :visible="true"/>
      <DxHeaderFilter :visible="true"/>
     
     <DxGroupPanel :visible="true"/>
      <DxGrouping :auto-expand-all="autoExpandAll"/>
      <DxColumn
        :width="110"
        data-field="saleSummary"
        caption="מכירה"
        :group-index=0
        alignment="right"
        :hiding-priority="6"
        :allow-editing="false"
      > 
      </DxColumn>
       <DxColumn :width="60" cell-template="chat" data-field="saleID" caption="התכתבות" :allowFiltering="false" alignment="right" :allow-editing="false">   
       </DxColumn>
       <template #chat="{data}">
        <img src="@/assets/images/message-square.svg" @click="getMessagesForSale(data)"/>
      </template> 
      <DxColumn
        width="115px"
        data-field="userName"
        caption="שם נציג"
        alignment="right"
        :allow-editing="false"
      > 
      </DxColumn>
     
      <!-- <DxColumn
        :min-width="120"
        data-field="totalLines"       
      >  
      </DxColumn> -->
      <DxColumn
       :hiding-priority="6"
        width="130px"
       data-field="nameCustomer" 
       caption="שם לקוח"
       alignment="right"
      :allow-editing="false"
      >
       </DxColumn>
      <DxColumn
        :hiding-priority="3"
         width="130px"
        data-field="personalID" 
        caption="תעודת זהות"
        alignment="right"
        :allow-editing="false"
      > 
      </DxColumn>
      <DxColumn
        :hiding-priority="5"
         width="130px"
        data-field="companyID" 
        caption="חברה"
        alignment="right"
        :allow-editing="false"
      > 
        <DxLookup :data-source="companyDataSource" value-expr="companyID" display-expr="name"  searchPlaceholder="חפש"/>
      </DxColumn>
      <DxColumn data-field="saleDetailStatus" caption="סטטוס" alignment="right" :hiding-priority="7"  width="130px">
        <DxLookup :data-source="SaleDetailStatusMainEnum" value-expr="value" display-expr="text" searchPlaceholder="חפש"/>
      </DxColumn>
       <DxColumn
        data-field="planID" 
        caption="תוכנית"
        width="120px"
        alignment="right"
        :hiding-priority="4"
        :allow-editing="false"
      >
        <DxLookup :data-source="planDataSource" value-expr="planID" display-expr="name"  searchPlaceholder="חפש"/>
       </DxColumn>
        <DxColumn
          :hiding-priority="1"
          width="130px"
          data-type="date"
          data-field="createdAt"
          caption="תאריך שליחה"
          alignment="right" 
          picker-type="rollers"
          :allow-editing="false"
          > 
        </DxColumn>   
        <DxColumn
        :hiding-priority="0"
        width="130px"
        data-type="date"
        data-field="lastUpdatedAt" 
        caption="תאריך עדכון"
        alignment="right"
        :allow-editing="false"
      > </DxColumn>
       <DxColumn :width="60" cell-template="edit" caption="ערוך" :allowFiltering="false" type="buttons" alignment="right" :allow-editing="false">           
       </DxColumn>
       <template #edit="{data}">
          <img src="@/assets/images/edit.svg" class="custom-icon" @click="getSaleDetailes(data)"    />
      </template>
       <DxColumn :width="60" cell-template="delete" caption="מחק" :allowFiltering="false" type="buttons" alignment="right" :allow-editing="false">   
       </DxColumn>
       <template #delete="{data}">
           <img src="@/assets/images/delete-row.svg" class="custom-icon" @click="delSale(data)" />
      </template> 
         <DxColumn :width="60" cell-template="edit-sale" data-field="saleID" caption="עריכת פרטי תשלום" :allowFiltering="false" alignment="right" :allow-editing="false">   
       </DxColumn>
       <template #edit-sale="{data}">
          <img src="@/assets/images/credit-card-search.svg" @click="editPaymentDetails(data)" class="icon"/>
      </template> 
     
       <DxPopup
        :key="popupKey"
        v-model:visible="popupVisible"
        :drag-enabled="false"
        :close-on-outside-click="true"
        :show-close-button="false"
        :show-title="true"
        width="40vw"
        height="50vh"
        :rtl-enabled="true"
        container=".dx-viewport"
        title="עדכון פרטי תשלום"
        :scrolling="true"
       >
         <DxScrollView
           width="100%"
           height="100%"
         >
         <div class="container-popup">
          <div class="panel" >
            <div class="container-radios">
              <label class="container-radio">
                <input
                  type="radio"
                  name="payType"
                  value="cc"
                  v-model="payType"
                />
                <span class="radio-text">אשראי</span>
                <span class="checkmark"></span>
              </label>
              <label class="container-radio">
                <input
                 type="radio"
                 name="payType" 
                 value="bank"
                 v-model="payType"
                />
                <span class="radio-text">הוראת קבע</span>
                <span class="checkmark"></span>
              </label>
            </div>
            <div  v-if="payType=='cc'">
              <input placeholder="מספר כרטיס" v-model="paymentDetails.cC_INFO"
               required @change="checkValidCard()" :class="isValidCard?'':'red-border'"
             />
              <input placeholder="cvv" v-model="paymentDetails.cvv"
              required @change="checkValidCVV()" :class="isValidCVV?'':'red-border'" />
              <UISelect
                placeholder="בחירת שנה"
                :list="years"
                v-model:value="paymentDetails.expirationYear"
              >
              </UISelect>
              <UISelect
                v-model:value="paymentDetails.expirationMonth"
                placeholder="בחירת חודש"
                :list="[
                  { key: '01', text: '01' },
                  { key: '02', text: '02' },
                  { key: '03', text: '03' },
                  { key: '04', text: '04' },
                  { key: '05', text: '05' },
                  { key: '06', text: '06' },
                  { key: '07', text: '07' },
                  { key: '08', text: '08' },
                  { key: '09', text: '09' },
                  { key: '10', text: '10' },
                  { key: '11', text: '11' },
                  { key: '12', text: '12' },
                ]"
              >
              </UISelect>
            </div>
            <div  v-if="payType=='bank'">
              <UISelect
                v-model:value="paymentDetails.bankID"
                placeholder="הקלד שם בנק"
                :initialTextProp="paymentDetails.bank"
                :list="banks"
              >
              </UISelect>
              <input placeholder="סניף" v-model="paymentDetails.branch"
                required @change="checkValidateNumBranch()" :class="isValidateNumBranch?'':'red-border'"
              />
              <input placeholder="מספר חשבון" v-model="paymentDetails.accountNumber" 
               required @change="checkValidateAccountNumber()" :class="isValidateAccountNumber?'':'red-border'"/>
            </div>
          </div>
         </div>
           </DxScrollView>
       </DxPopup>
    </DxDataGrid>
     <UIChat v-if="showChat" :saleID="saleID"></UIChat>
  </div>
</template>
 

<script>
import { Validations } from "../code/validations";
import axios from "axios";
import { DxDataGrid, DxGrouping, DxGroupPanel,DxColumn,DxHeaderFilter,DxSearchPanel,DxFilterRow,DxLookup,DxEditing} from "devextreme-vue/data-grid";
import { DxPopup} from 'devextreme-vue/popup';
import UISelect from "./UIComponents/UISelect.vue";
import { DxScrollView } from 'devextreme-vue/scroll-view';
import { createStore } from "devextreme-aspnet-data-nojquery";
import { SaleDetailStatusMainEnum} from "../code/enums";

const url = process.env.VUE_APP_BASE_URL + "/Sale";
// const dataSource = createStore({
//   key: "saleDetailID",
//   loadUrl: `${url}/GetSaleBackOfficeGrid`,
//   onBeforeSend: (method, ajaxOptions) => {
//     ajaxOptions.xhrFields = { withCredentials: true };
//   },
// });

const urlPlans = process.env.VUE_APP_BASE_URL + "/Plan";
const planDataSource = createStore({
  key: "planID",
  loadUrl: `${urlPlans}/GetAllPlans`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers =     { "Authorization" : localStorage.getItem('jwtToken') };
  },
});

const urlCompanies = process.env.VUE_APP_BASE_URL + "/Company";
const companyDataSource = createStore({
  key: "companyID",
  loadUrl: `${urlCompanies}/GetAllCompanies`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers =     { "Authorization" : localStorage.getItem('jwtToken') };
    
  },
});
export default {
  name: "BackOfficeSales",
  props: {
    msg: String,
  },
  components: {
    DxDataGrid, 
    DxGrouping,
    DxGroupPanel,
    DxColumn,
    DxHeaderFilter,
    DxSearchPanel, 
    DxLookup,
    DxFilterRow,
    DxPopup,
    DxScrollView,
    DxEditing
  },
  data() {
    return {
      userID:'',
      currentUserInfo: { userID: ""},
      managerLevel:4,
      componentKey:0,
      planDataSource,
      companyDataSource,
      SaleDetailStatusMainEnum,
      mediaURL:process.env.VUE_APP_BASE_MEDIA_URL ,
      company: {},
      companyID:0,
      saleID:0,
      dataSource:this.getDataSource(),
      popupVisible:false,
      sale:{},
      Validations,
      years: [],
      banks: [],
      popupKey:0,
      paymentDetails:{
        saleID:null,
        lastFourDigits: "",
        cC_INFO: "",
        cvv: null,
        expirationYear: null,
        expirationMonth: null,
        remarks: "",
        bankID: null,
        bank: "",
        branch: null,
        accountNumber: "",
      },
      isValidCard:true,
      isValidCVV:true,
      isValidateNumBranch:true,
      isValidateAccountNumber:true,
      payType:'cc',
      showChat:false
    };
  },
  mounted() {
    this.companyID = this.$route.query.companyID;
    let year = new Date().getYear() + 1900;
       for (var i = 0; i < 8; i++) {
          this.years.push({ key: String(year + i), text: String(year + i) });
        }
    this.getCompanyByID();
    this.getAllBanks();
    this.currentUserInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.userID = this.currentUserInfo.userID;
  },
  methods: {
      getDataSource(){
          var urlCompany='';
          if (this.$route.query.companyID>0)
              urlCompany='companyID=' + this.$route.query.companyID;
          var urlStatus='';
          if (this.$route.query.status>0)
              urlStatus='saleDetailStatus=' + this.$route.query.status;
          let firstDate= new Date(new Date().getFullYear(), new Date().getMonth(),1,12,0);
          return createStore({
              key: "saleDetailID",
              updateUrl: process.env.VUE_APP_BASE_URL + "/StatusSale/Update",
              loadUrl: `${url}/GetSaleBackOfficeGrid?${urlCompany}&firstDate=${firstDate.toISOString().slice(0, 10)}&${urlStatus}`,
              onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.headers =     { "Authorization" : localStorage.getItem('jwtToken') };
              },
            });
      },
      getCompanyByID(){
        axios.get(process.env.VUE_APP_BASE_URL + "/Company/GetCompany/" + this.companyID)
          .then(res => {
            this.company = res.data;  
            console.log(res.data);
        });
     },
    BackToCompanies(){
         this.$router.push({ path: "/app/settings/backOffice"});  
         },
    getAllBanks() {
      axios
        .get(process.env.VUE_APP_BASE_URL + "/Bank/GetAllBanks")
        .then((res) => {
          this.banks = res.data.map(function (b) {
            return { text: b.name, key: b.bankID };
          });
        });
    },
    getSaleDetailes(data){
      let saleID= data.data.saleID;
      this.$router.push({ path: "/app/sendIktov",
                           query: { saleID :saleID } });

      },
    editPaymentDetails(data){
      let saleID= data.data.saleID;
       axios
        .get(process.env.VUE_APP_BASE_URL + "/Sale/GetSaleComplete/" + saleID)
        .then((res) => {
          this.paymentDetails=res.data;
          console.log(res.data);
          if (this.paymentDetails.lastFourDigits<4)
             this.payType='bank';
          else
           this.paymentDetails.cC_INFO=res.data.unEncodedCCInfo;

          // this.paymentDetails.cC_INFO=this.paymentDetails.lastFourDigits.toString();
          if (!this.paymentDetails.cC_INFO)
            this.payType='bank';
            this.popupVisible=true;
            this.popupKey++;
        });
      },
    updatePaymentDetails(){
     this.paymentDetails.lastFourDigits = +this.paymentDetails.cC_INFO.substring(
      this.paymentDetails.cC_INFO.length - 4
    );
    if (this.paymentDetails.cC_INFO>0) {
      this.paymentDetails.branch = "";
      this.paymentDetails.accountNumber = "";
      this.paymentDetails.bankID = null;
      this.paymentDetails.bank = "";
      this.paymentDetails.remarks = "";
    }
    if (this.paymentDetails.cC_INFO.length <= 4) {
      this.paymentDetails.cC_INFO = "";
    }
       axios
      .put(process.env.VUE_APP_BASE_URL + "/Sale/PutSale",this.paymentDetails)
      .then((res) => {
        console.log(res.data);
        this.popupVisible=false;
        this.popupKey++;
      })
      .catch(() => {
        console.log("לא נשלח");
      });
    },
    delSale(data){
      this.popupVisible=true;
      this.sale=data.data;
      // axios.delete(process.env.VUE_APP_BASE_URL + `/Sale/DeleteSale/${saleID}`)
      //   .then((res)=>{
      //     console.log( res.data);
      //      this.popupVisible=false;
      //        })
      //      .catch(
      //       (e)=>{   
      //      });
      //  this.componentKey++;
    },
     deleteSale(){
      let saleID=this.sale.saleID;
      axios.delete(process.env.VUE_APP_BASE_URL + `/Sale/DeleteSale/${saleID}`)
        .then((res)=>{
          console.log( res.data);
           this.popupVisible=false;
             })
           .catch(
            (e)=>{   
           });
       this.componentKey++;
    },
    getCurrentFilter(){
       console.log("getCurrentFilter");
       var dataGrid =this.$refs.dGrid.instance;
       console.log(dataGrid);
       var currentFilter = dataGrid.getCombinedFilter();
       console.log(currentFilter);
       var ReplacedFilter = this.RecurseFilter(currentFilter, dataGrid);
       var jsonFilter = JSON.stringify(ReplacedFilter);
       console.log("jsonFilter" + jsonFilter);
       var urlCompany='';
          if (this.$route.query.companyID>0)
              urlCompany='companyID=' + this.$route.query.companyID;
       var urlStatus='';
       if (this.$route.query.status>0)
           urlStatus='saleDetailStatus=' + this.$route.query.status;
       let firstDate= new Date(new Date().getFullYear(),new Date().getMonth(), 1);
        axios
         .get(process.env.VUE_APP_BASE_URL + `/Sale/ExportSalesBackOffice?${urlCompany}&firstDate=${firstDate.toISOString().slice(0, 10)}&${urlStatus}&filter=${jsonFilter}` ,{responseType:'blob'})
         .then((res) => {
             const url = window.URL.createObjectURL(new Blob([res.data]));
             const link = document.createElement('a');
             link.href = url;
             link.setAttribute('download', 'מכירות-בק אופיס.xlsx');
             document.body.appendChild(link);
             link.click();
         });
     },
      RecurseFilter(filterArray, dataGrid) {
         if (Array.isArray(filterArray)) {
             var result = new Array();
             for (var i = 0; i < filterArray.length; i++) {
                 if (filterArray[i].columnIndex != null && !Array.isArray(filterArray[i]))
                     result.push(dataGrid.getVisibleColumns()[filterArray[i].columnIndex].dataField);
                 else
                     result.push(this.RecurseFilter(filterArray[i], dataGrid));
             }
             return result;
         }
         else
             return filterArray;
     },
     closePopup(){
      this.popupVisible=false;
    },
     checkValidCard(){
      this.isValidCard=this.paymentDetails.cC_INFO =='' || this.Validations.is_valid_card_genery(this.paymentDetails.cC_INFO);
    },
      checkValidCVV(){
      this.isValidCVV=this.paymentDetails.cvv=='' || this.Validations.is_valid_cvv(this.paymentDetails.cvv);
    },
     checkValidateNumBranch(){
      this.isValidateNumBranch=this.paymentDetails.branch=='' || this.Validations.validateNumber(this.paymentDetails.branch);
    },
     checkValidateAccountNumber(){
      this.isValidateAccountNumber=this.paymentDetails.accountNumber=='' || this.Validations.validateNumber(this.paymentDetails.accountNumber);
    },
    getMessagesForSale(data){
      this.saleID= data.data.saleID;
      this.showChat=!this.showChat;  
    }
  },
};
</script>

<style lang="scss" scoped></style>
