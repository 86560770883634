<template>
<div class="container-chat" v-if="showChat">
<div @click="closeChat()" class="exitChat">
  <img src="@/assets/images/close.svg" class="close-chat"/>
</div>
 <div class="chat"> 
    <div class="messageHolder" >
      <div  v-for="(message, messIndex) in messages" :key="messIndex" 
      :class="message.createdByUserID== currentUserID ? 'chat-me':'chat-other'">
        <div class="messageUser">
          <span > 
           {{ message.createdByUser.fullName }}
          </span>
        <!--  v-if="message.createdByUserID != currentUserID"-->
        </div>
        {{ message.messageText }} 
        <div class="txt-date"> 
           {{ format_date(message.createdAt) }}
       </div> 
      </div>
      <div style="height:0px" ref="bottomMark"></div>
    </div>    
  </div>
  <div class="bottomChat">
      <div class="newMessage" >
        <textarea class="taxtMessage" v-model="newMassage.messageText"></textarea>
          <div class="sendMessage">
            <span type="button" @click="sendMessage()" class="send-message">
              <svg viewBox="0 0 24 24" width="24px" height="24px" class="GfYBMd v6TC2e"><path d="M6.7,17.9v-3.7l4-1.8c0.4-0.2,0.4-0.7,0-0.9l-4-1.8V6.1L19.8,12L6.7,17.9z M23.7,11.5L5.4,3.3  c-0.1,0-0.1,0-0.2,0C5,3.3,4.7,3.5,4.7,3.8v5.9v4.8v5.9c0,0.3,0.2,0.5,0.5,0.5c0.1,0,0.1,0,0.2,0l18.3-8.2  C24.1,12.3,24.1,11.7,23.7,11.5z"></path></svg>
            </span>
          </div>
      </div>
    </div> 
</div>  
</template>

<script>

import axios from "axios";
import DxPopup, {DxPosition} from 'devextreme-vue/popup';
import moment from 'moment';
 
export default {
    name:'UIChat',
    props: {
        saleID: Number,
        popupChat:Boolean,
        
    },
    //  components: {
    //     DxPopup,
        
    // },
    emits:['update:popupChat','update:saleID'],
    data() {
        return {
          showChat:true,
          messages:[],
          currentUserID:0,
          intervalHandle:0,
          popupChatInt:false,
          popupKey:0,
          newMassage:{
            messageText:"",
            saleID:0
          }
        }
    },
    watch:{
        popupChat(val){
            console.log("popupChat change");
            console.log(val);
            this.popupChatInt=val;
            this.popupKey++;
        },
    },
    mounted() {
    this.currentUserID = JSON.parse(localStorage.getItem("userInfo")).userID;
    this.RefreshMessages();
    console.log("RefreshMessages");
    this.intervalHandle= setInterval(()=>this.RefreshMessages(),5000);
    
 },
 beforeUnmount(){
    clearInterval(this.intervalHandle);
 },
 methods: {
      RefreshMessages(){
    axios.get(process.env.VUE_APP_BASE_URL + `/Message/GetMessagesBySaleID?saleID=${this.saleID}`)
        .then(res => {
          var newMessages=false;
          if ( res.data.length> this.messages.length){
            newMessages=true;
          }
            this.messages=res.data;
            console.log(this.messages);
            if (newMessages)
            {
              setTimeout(()=>{
                  var bottomMark = this.$refs.bottomMark;
                  if (bottomMark)
                    bottomMark.scrollIntoView({behavior: 'smooth'}); 
              },250);
            }
        });
      },
      sendMessage(){
         this.newMassage.saleID = this.saleID;
         if(this.newMassage.messageText)
          axios.post(process.env.VUE_APP_BASE_URL + "/Message/PostMessage",this.newMassage)  
          .then((res)=>{
            this.RefreshMessages();
            this.newMassage.messageText="";
          })
          .catch(()=>{
             
            });
    }, 
    format_date(value){
         if (value) {
           return moment(String(value)).format('MM/DD/YYYY hh:mm')
          }
      },
      openPopup(){
         this.popupChatInt=true;
         this.popupKey++;
         console.log('open popup click');

     },
     closeChat(){
       this.showChat=false;
     }
   } 
 }

</script>

<style lang="scss" scoped>

</style>
