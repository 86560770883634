<template>
  <div class="container-details">
    <div>
      <label>עדכון פרטים אישיים:</label>
      <div>
        <label>אימייל:</label>
        <input v-model="newDitailse.email" />
      </div>
      <div>
        <label>סיסמה:</label>
        <input v-model="newDitailse.password" @focus="touchPassword()" />
      </div>
      <div>
        <label>פלאפון:</label>
        <input v-model="newDitailse.mobileNumber" />
      </div>
    </div>
    <button class="btn-details" @click="updateDitailse()">עדכן</button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PersonalInformation",
  data() {
    return {
      newDitailse: {
        email: "",
        password: "",
        mobileNumber: "",
        userID: "",
      },
      passwordChanged:false,
      currentUserInfo: { userID: "" },
    };
  },
  mounted() {
    this.currentUserInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.newDitailse.userID = this.currentUserInfo.userID;
    this.newDitailse.email = this.currentUserInfo.email;
    this.newDitailse.password = this.currentUserInfo.password;
    this.newDitailse.mobileNumber = this.currentUserInfo.mobileNumber;

    //  this.newDitailse.email=this.currentUserInfo.email;
    console.log("userInfo");
  },
  methods: {
    updateDitailse() {
      var formData = new FormData();
      formData.append("key", this.newDitailse.userID);
      if (!this.passwordChanged)
        this.newDitailse.password="";
      formData.append("values", JSON.stringify(this.newDitailse));
      axios
        .put(process.env.VUE_APP_BASE_URL + "/UserGrid/UpdateFromSettings", formData)
        .then((res) => {
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          this.$router.push({ path: "/app/sendIktov" });
          console.log(res.data);
        })
        .catch(() => {
          console.log("לא נשלח");
        });
    },
    touchPassword(){
        this.passwordChanged=true;
        this.newDitailse.password="";
    }
  },
};
</script>

<style lang="scss" scoped></style>
