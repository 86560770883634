<template>
    <div>
       <label>OrderManagement</label>
    </div>
</template>

<script>
    export default {
       name: 'OrderManagement',
       props: {
       msg: String
     }   
    }
</script>

<style lang="scss" scoped>

</style>