<template>
<div class="container-table">
     <div class="company-title only-desktop">
        <img   :src="mediaURL + company.logoPath"  class="logo-company-header"/>
        <span>
            <img src="@/assets/images/globe.svg" class="img-globe">
            <label>{{ company.name}} </label>
        </span>
        <span >
           <img src="@/assets/images/arrow-black.svg" class="arrow-black">
           <label class="lable-back" @click="BackAllCompanies()">חזרה לכל החברות</label>
           <button class="btn-ideal" @click="editPlan(0)">תוכנית חדשה</button>  
        </span>   
    </div>
    <div class="company-title only-mobile">
        <span  @click="BackAllCompanies()">
            <img src="@/assets/images/arrow-black.svg" class="arrow-black">
        </span>   
        <img :src="mediaURL + company.logoPath"  class="logo-company-header"/>
        <button class="btn-idl only-mobile" @click="editPlan(0)">
                  <img src="@/assets/images/fi_plus.png" class="plus">
                  <!--v-if="edit"-->
        </button> 
    </div>
    <div class="container-table-grid">
       <DxDataGrid
        :data-source="plans"
        :show-borders="false"
        :rtl-enabled="true"
        :remoteOperations="false"
        :allowColumnResizing="true"
        :show-column-lines="false"
        :show-row-lines="true"
        :column-hiding-enabled="true"
        class="tree-list"
        @exporting="onExporting"
        @exported="onExported"
       > 
        <DxExport
        :enabled="true"
      />     
       <DxFilterRow :visible="true" />
       <DxHeaderFilter :visible="true" />
       <DxColumn :width="50" cell-template="edit" data-field="planID" caption="עריכה" :allowFiltering="false">   
       </DxColumn>
       <DxColumn :width="50" cell-template="duplicate" data-field="planID" caption="שיכפול" :allowFiltering="false" :hiding-priority="8">   
       </DxColumn>
        <template #duplicate="{data}">
           <img src="@/assets/images/copy.svg" class="duplicate-icon" @click="duplicatePlan(data.value)" />
      </template>
       <DxColumn :width="130" data-field="name" caption="תוכנית"/>
       <DxColumn :width="130" data-field="isKosher" caption="מסלול כשר" :hiding-priority="5">
         <DxLookup :data-source="isKosherEnum" value-expr="value" display-expr="text" searchPlaceholder="חפש" :hiding-priority="0"/>
       </DxColumn>
       <DxColumn :width="130" data-field="price" caption=" מחיר" :hiding-priority="6" />
       <DxColumn :width="130" data-field="maximumNumberOfLines" caption="מספר קוים מירבי" :hiding-priority="4" />
       <DxColumn :width="130" data-field="websiteCode" caption="קוד באתר" :hiding-priority="3" />
       <DxColumn :width="130" data-field="rewardCode" caption="תגמול לפי קוד" :hiding-priority="0" />
       <DxColumn :min-width="100" data-field="boldPoints" caption="דגשים" :hiding-priority="1"/>
       <DxColumn :min-width="100" data-field="notes" caption="הערות" :hiding-priority="2"/>
         <template #edit="{data}">
           <img src="@/assets/images/edit-row.svg" class="custom-icon" @click="editPlan(data.value)" />
         </template>
       <DxColumn :width="70" cell-template="delete" data-field="planID" caption="מחיקה" :allowFiltering="false" :hiding-priority="7">   
       </DxColumn>
       <template #delete="{data}">
           <img src="@/assets/images/delete-row.svg" class="custom-icon"  @click="deletePlan(data.value)" />
      </template>
     </DxDataGrid>
    </div>
</div>
</template>

<script>
import axios from "axios";
import {isKosherEnum} from "../../code/enums";

import { createStore } from "devextreme-aspnet-data-nojquery";
import {
  DxDataGrid,
  DxColumn,
  DxHeaderFilter,
  DxFilterRow,
  DxLookup,
  DxExport
} from "devextreme-vue/data-grid";
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

const urlPlans = process.env.VUE_APP_BASE_URL + "/Plan";
 

    export default {
       title:'פרטי תוכניות',
       name: 'PlansSheet',
       props:{
           companyID: Number,
           value: Number,
           val:String,
           plan:{},
          
    },
     components: {
       DxDataGrid,
       DxHeaderFilter,
       DxColumn,
       DxFilterRow,
       DxLookup,
       DxExport
  },
   emits: ['update:value'],
  data() {
    return {
       plans:[],
       isKosherEnum,
       company:{ },
       mediaURL:process.env.VUE_APP_BASE_MEDIA_URL ,
       duplicate:1
       
    }
  },
  mounted() {
    this.getPlansDataSource();
  },
  methods: {
      editPlan(planID){
         this.$emit('update:value', planID);
      },
      duplicatePlan(planID){
        this.$emit('update:value', planID);
        this.$router.push({ path: "/app/settings/Plans",
                                    query: { duplicate  : this.duplicate }
                }); 
      },
      deletePlan(plan){
        let delPlan=plan;
        console.log(delPlan);
           axios.delete(process.env.VUE_APP_BASE_URL + `/Plan/DeletePlan/${delPlan}`)
           .then((res)=>{
            this.getPlansDataSource();
             console.log( res.data);

               })
             .catch(
              (e)=>{   
            
             });
      },
     getPlansDataSource(){
       let companyID=this.companyID;
        console.log(this.companyID);
        axios.get(process.env.VUE_APP_BASE_URL + `/Plan/GetCompanyWithPlans/${companyID}`)
             .then(res => {
             this.plans = res.data.plans;
             this.company = res.data;
                console.log(res.data);   
         });  
    },
      BackAllCompanies(){
        this.$emit('update:val');
    },
    onExporting(e) {
      e.component.beginUpdate();  
      e.component.columnOption(0, "visible", false);
      e.component.columnOption(1, "visible", false);  
      e.component.columnOption(2, "visible", true);  
      e.component.columnOption(3, "visible", true);
      e.component.columnOption(4, "visible", true);  
      e.component.columnOption(5, "visible", true);  
      e.component.columnOption(6, "visible", true);
      e.component.columnOption(7, "visible", true);  
      e.component.columnOption(8, "visible", true);
      e.component.columnOption(9, "visible", true);
      e.component.columnOption(10, "visible", false);  
   
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('תוכניות');

      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'תוכניות.xlsx');
        });
      });
     
      e.cancel = true; 

      //  e.component.beginUpdate();  
      //  e.component.columnOption(0, "visible", true);  

   
    }
  },
    }
</script>

<style lang="scss" scoped>

</style>