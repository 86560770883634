<template>
    <div >
      <div class="content-container">
      <UICollapsiblePanel title="אישורי ניוד (IVR)"  collapsedCss='Information-title' collapsedLoad="true" expandedCss='Information-title-open'>
         <div style="position:relative">
            <div class="open-panel">
              <label>
                  <span>בכדי להתנייד מחברה לחברה,ובכדי לאשר את הניוד, חובה לחייג מהסים הישן למספרים הבאים כדלהלן:</span>
              </label>
              <label >סלקום
                  <span> 1800071001 / 0529990333</span>
              </label>
              <label>פרטנר
                  <span>1800054005 / 0546990521 </span> <img src="@/assets/images/exclamation-mark.svg"/><label class="lbl-bold"> להמתין לשיחה חוזרת על מנת לאשר את הניוד</label>
              </label>
              <label>גולן
                  <span>0587778585</span>
              </label>
              <label>הוט מובייל 
                  <span>0535353800</span>
              </label>
              <label>פלאפון
                  <span>0505010010</span> <img src="@/assets/images/exclamation-mark.svg"/><label class="lbl-bold"> להמתין לשיחה חוזרת על מנת לאשר את הניוד</label>
              </label>
              <label>012
                  <span>1800054006 / 0546990522</span> <img src="@/assets/images/exclamation-mark.svg"/><label class="lbl-bold"> להמתין לשיחה חוזרת על מנת לאשר את הניוד</label>
              </label>
              <label> רמי לוי 
                  <span>0556666055</span> <img src="@/assets/images/exclamation-mark.svg"/><label class="lbl-bold"> להמתין לשיחה חוזרת על מנת לאשר את הניוד</label>
              </label>
              <label>010
                  <span>0525799938</span>
              </label>
          </div> 
       </div>     
    </UICollapsiblePanel> 
      <UICollapsiblePanel title="תמיכת משווקים" class="Information-title"  collapsedLoad="true" collapsedCss='Information-title' expandedCss='Information-title-open'> 
       <div class="open-panel">
        <label>סלקום 
          <span>*503</span> 
        </label>
        <label>גולן
          <span>0587779898</span> 
        </label>
        <label>012
          <span>0547814056</span> 
        </label>
        <label>רמי לוי
          <span>0556668684</span> 
        </label>
        <label>פלאפון 
          <span>0528002007</span> 
        </label>
         <label>פרטנר 
          <span>0528002008</span> 
        </label>
       </div>
    </UICollapsiblePanel> 
      <UICollapsiblePanel title="שירות לקוחות" class="Information-title" collapsedLoad="true" collapsedCss='Information-title' expandedCss='Information-title-open'>
       <div class="open-panel">
        <label>סלקום 
          <span>*123</span>
          <label>  או  </label>
          <span>1800052052 </span>
        </label>
        <label>פרטנר
          <span>*054</span>
          <label>  או  </label>
          <span>1800800054 </span>
        </label>
        <label>פלאפון
          <span>*166</span>
          <label>  או  </label>
          <span>1800050166 </span> 
        </label>
        <label>גולן
          <span>*0058</span> 
        </label>
        <label>012 
          <span>1800055888</span> 
        </label>
         <label> הוט מובייל
          <span>1800800053</span> 
        </label>
        <label> רמי לוי
          <span>076-8888-600</span> 
        </label>
       </div>
      </UICollapsiblePanel> 
      <UICollapsiblePanel title="שירות לקוחות פיננסי" class="Information-title" collapsedLoad="true" collapsedCss='Information-title' expandedCss='Information-title-open'>
       <div class="open-panel">
        <label>סלקום 
          <span>0529990147</span>
        </label>
        <label>פרטנר
          <span>*222</span>
        </label>
        <label>פלאפון
          <span>1700558122</span>
        </label>
       </div>
       </UICollapsiblePanel> 
      <UICollapsiblePanel title="שליחת הגדרות אינטרנט" class="Information-title" collapsedLoad="true" collapsedCss='Information-title' expandedCss='Information-title-open'>
        <div class="open-panel">
          <label>סלקום 
            <span>שלח מהמכשיר סמס עם המילה WAP למספר 8877 וההגדרות בדרך אליך</span>
          </label>
           <label>פרטנר / 012 
            <span>שלח מהמכשיר סמס עם המילה OTA למספר 999 וההגדרות בדרך אליך</span>
          </label>
          <label>גולן
            <span>שלח מהמכשיר סמס עם המילה APN למספר 0586275002 וההגדרות בדרך אליך</span>
          </label>
          <label>פלאפון
            <span>חייג מהמכשיר למספר 9999* ואז לחץ על שלוחה 1 וההגדרות בדרך אליך</span>
          </label>
          <label>הוט
            <span>שלח מהמכשיר סמס עם המילה APN למספר 610 וההגדרות בדרך אליך</span>
          </label>
        </div>
      </UICollapsiblePanel> 
      <UICollapsiblePanel title="מכשירים תומכים סים כשר" class="Information-title" collapsedLoad="true" expandedCss='Information-title-open'  collapsedCss='Information-title'>
       <div class="panel">
       <label>לתשומת ליבך! סים כשר עובד במכשיר כשר בלבד. לנוחיותך בנינו כלי לבדיקת מכשירים כשרים התומכים בסים כשר</label><br>
          <lable>הכנס מספר סידורי של המכשיר ( imei )</lable>
          <div>
            <input v-model="numberDevice" :class="isValidImei?'':'red-border'"/>
            <button class="btn-Information" @click="checkIsValidImei()">בדיקה</button>
          </div>
            <span :class="'code-'+ code">{{message}}</span>
            <lable>את מספר ה-imei ניתן לבדוק על ידי חיוג מהמכשיר :<br>
במידה ונתקלת בטעות, נשמח שתעדכן אותנו לטובת כלל המשתמשים : #06#*</lable>
       </div>
      </UICollapsiblePanel> 
      <UICollapsiblePanel title="שליחת אישור ניוד ללקוח" class="Information-title" collapsedLoad="true" expandedCss='Information-title-open'>
       <div class="panel">
          <div>
            <input placeholder="מספר מנוי" v-model="subscriptionNumber"/>
             <UISelect  placeholder="בחירת חברה" ref="companiesSelect" :list="companies" 
               v-model:val="company.name" v-model:value="company.companyID" :key="index"  @update:value="refreshComponent(company)">
             </UISelect>
            <button class="btn-Information" @click="send()">שליחה</button>
          </div>
       </div>
      </UICollapsiblePanel> 
      </div>
    </div>
</template>

<script>
import { Validations } from "../code/validations";
import UISelect from "./UIComponents/UISelect.vue";
 import axios from "axios";
    export default {
      title:'מידע ופעולות',
      components: { UISelect},
      name: 'InformationAndActions',
      props: {
       msg: String
  },
  data() {
      return {
        companies:[],
         company:{
          companyID:null,
          name:""
        },
        subscriptionNumber:"",
        index:1,
        isOpenPanel:false,
        numberDevice:"",
        selected:false,
        isValidImei:true,
        Validations,
        message:"",
        code:0
      }
  },
   mounted() {
         this.getAllCompanies();
     },
   methods: {
       getAllCompanies() {
          axios.get(process.env.VUE_APP_BASE_URL + "/Company/GetAllCompanies").then((res) => {
          this.companies = res.data.map(function (s) {
            return { text: s.name, key: s.companyID };
         });
           this.index++;
      });
    },
    refreshComponent(company){
       this.company.name=company.text;
       console.log(company);
       this.index++;
       this.selected=true;
    },
    checkIsValidImei(){
      var res=this.Validations.is_kosher_imei(this.numberDevice);
      // this.isValidImei=res.code;
      this.code= res.code;
      this.message=res.message;
      if(this.code==-1)
        this.isValidImei=false;
        else
         this.isValidImei=true;
    },
    send(){
        axios.post(process.env.VUE_APP_BASE_URL +`/Sale/PutCustomerMobilityApproval?companyID=${this.company.companyID}&subscriptionNumber=${this.subscriptionNumber}`) 
             .then((res)=>{
             console.log(res.data);  
               })
             .catch(
              ()=>{
                   
             });
    }
     },
 
  }       
   
</script>

<style lang="scss" scoped>

</style>