<template>
  <div class="hello">

  
  <div v-for="company in companies" :key="company.companyID">
    <div>
           {{ company.name }}
       <img v-bind:src="'/' + company.logoPath" />
    </div>
  </div>
 <DxDataGrid
    :width="800"
    :data-source="dataSource"
    :columns="columns"
    :show-borders="true"
 >
   <DxHeaderFilter
        :visible="true"
      />
 </DxDataGrid>

   
<!-- <UISelect caption="עיר"  placeholder="הקלד שם העיר" :autoComplete="true" apiAddress="City/AutoCompleteCities">
  </UISelect>

  <UISelect caption="הצגת נתונים" 
      placeholder="בחירת טווח תאריכים"
      :list="[
      { key:'1',text:'מהיום'   },
      { key:'2',text:'מאתמול'   },
      { key:'3',text:'מחודש קודם'},
      { key:'3',text:'בחירת טווח תאריכים'}
  ]">
  </UISelect> -->


  <!-- <UICollapsiblePanel title="PANEL 1" >
      <div class="collapsiblePanel">
        SOME TEXT TO HIDE/SHOW 
      </div>
  </UICollapsiblePanel> -->
  </div>
</template>

<script> 
import axios from "axios";

// import UISelect from "./UIComponents/UISelect.vue"

import { customers } from './data.js';
import {
  DxDataGrid,
  DxColumn,
  DxHeaderFilter,
  DxSearchPanel,
  DxFilterRow
} from 'devextreme-vue/data-grid';

export default { 
  
  components: {
        DxDataGrid,
        DxHeaderFilter
        

    },
    methods: {
        sayHelloWorld() {
            alert('Hello world!')
        }
    },
  name: 'HelloWorld',
  props: {
    msg: String
  }
  ,data() {
    return {
      companies: [],
      
      dataSource: customers,
      columns: ['CompanyName', 'City', 'State', 'Phone', 'Fax']
    }
  },
  mounted() {
    console.log('mounted');
    console.log(this.dataSource);
    axios.get(process.env.VUE_APP_BASE_URL + "/Company/GetAllCompanies")
      .then(res => {
        this.companies = res.data;
    console.log(this.companies);
      })
      .catch(error => {
        console.log(error)
         // Manage errors if found any
      })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
