<template>
    <div class="body">
           <div class="container-login">
           <div class="login-form" v-if="loaded">
               <img src="@/assets/images/logo.svg" class="logo-login">
               <div v-if="inactiveLink" class="message">קישור לא פעיל</div>
               <div v-if="inactiveLink" class="sign-up">
                 <a class="link" @click="GoToResetPassword()" > לחץ כאן לקבלת קישור חדש </a>
               </div>
               <div v-if="!inactiveLink" > הזן סיסמה חדשה!</div>
               <input  v-if="!inactiveLink" placeholder="סיסמה" v-model="password" type="password">
               <button  v-if="!inactiveLink" class="btn-login" @click="sendPassword()">שלח</button>
           </div>
           <div class="login-form" v-if="!loaded">
               <div class="checker" >בודק ...</div>
               </div>
           </div>
    </div>
</template>

<script>
    import axios from "axios";
    export default {
         title:'חידוש סיסמה',
         name: 'NewPassword',
         data() {
             return {
                 password:'',
                 userID: "",
                 token: "",
                 isEmailCheck:false,
                //  inactiveLink: false,
                 loaded:false
             }
         },
         mounted: function() {
           this.token = this.$route.query.token;
           this.userID = this.$route.query.userID;
           this.checkToken();
    }, 
         methods: {

        GoToResetPassword(){
                this.$router.push({ path: "/ResetPassword"});        

        },


         sendPassword(){
            axios.post(process.env.VUE_APP_BASE_URL + "/User/ResetPassword",
            {
             userID:this.userID,
             token:this.token,
             password:this.password
            }) 
            .then((res)=>{
                this.loaded=true;
                this.$router.push({ path: "/Login"});        
            })
            .catch(
              ()=>{
                this.loaded=true;
               this.inactiveLink=true;
              }); 
         },
          checkToken(){
             axios.post(process.env.VUE_APP_BASE_URL + "/User/CheckToken", {
             userID:this.userID,
             token:this.token, 
            })  
            .then((res)=>{
                this.loaded=true;
          //   this.$router.push({ path: "/NewPassword"});        
            })
            .catch(
              ()=>{
                this.loaded=true;
                 this.inactiveLink=true;
              }); 
           },
    }
    }
</script>

<style lang="scss" scoped>

</style>