<template>
<div class="body">
  <form @submit="submit">
    <div class="container-login">
        <div class="login-form">
            <!-- <img src="@/assets/images/logo.svg" class="logo-login"> -->
            <img src="@/assets/images/logo.svg" class="logo only-desktop">
            <img src="@/assets/images/logo-mobile.svg" class="logo-mobile only-mobile">
            <div v-if="haveError"> אחד מהפרטים אינו תקין !</div>
            <input  placeholder="שם משתמש" v-model="form.userName" @keyup="checkValidateUserName()" :class="isValidateUserName?'':'red-border'">
            <div v-if="isUserExists"> שם משתמש בשימוש !</div>
            <input  placeholder="סיסמה" v-model="form.password" type="password" >  
            <input  placeholder="שם מלא בעברית" v-model="form.fullName" @keyup="checkIsValidNameׁׁׂׂ()" :class="isValidName?'':'red-border'">
            <input  placeholder="שם החנות" v-model="form.storeName">
            <input  placeholder="כתובת החנות" v-model="form.storeAddress">
            <input  placeholder="דואר אלקטרוני" v-model="form.email" @keyup="checkValidEmail()" :class="isValidEmail?'':'red-border'" >
            <input  placeholder="טלפון נייד" v-model="form.mobileNumber" @keyup="checkValidMobilePhone()" :class="isValidMobilePhone?'':'red-border'">
            <input  placeholder="תעודת זהות" v-model="form.personalID" @keyup="checkIsValidIdׁׂ()" :class="isValidId?'':'red-border'">
            <button class="btn-login">הרשמה</button>
        </div>
    </div>
       </form>    
</div>
</template>

<script>
import axios from "axios";
import { Validations } from "../code/validations";
export default {
    title:'הרשמה',
   name: 'Register',
    props: {
    msg: String
  },
  data() {
    return {
      form:{
         userName:'',
         password:'',
         fullName:'',
         storeName:'',
         storeAddress:'',
         email:'',
         mobileNumber:'',
         personalID:'',
      },
      isPassword: false,
      isUserExists:false,
      haveError:false,
      Validations,
      isValidateUserName:true,
      isValidName:true,
      isValidEmail:true,
      isValidId:true,
      isValidMobilePhone:true
    }
  },
  methods: {
 
    // sendUserName(){
      
    // },
    submit(e){
       e.preventDefault();
       axios.post(process.env.VUE_APP_BASE_URL + "/User/RegisterUser",this.form)  
            .then((res)=>{
               this.$router.push({ path: "/ConfirimEmail"});  
            })
            .catch(
              ()=>{
                this.haveError=true;
              });
    },
    checkValidateUserName(){
      this.isValidateUserName=this.form.userName=='' || this.Validations.is_valid_userName(this.form.userName);
     if (this.form.userName.length>0)
         axios.get(process.env.VUE_APP_BASE_URL + "/User/CheckUserNameFree/" + this.form.userName )
              .then(res => {   
              this.isUserExists = res.data;
              this.haveError=res.data;
         });
    },
    checkIsValidNameׁׁׂׂ(){
      this.isValidName=this.form.fullName=='' || this.Validations.is_valid_text_hebrew(this.form.fullName);
    },
    checkValidEmail(){
      this.isValidEmail=this.form.email=='' || this.Validations.validateEmail(this.form.email);
    },
    checkIsValidIdׁׂ(){
      this.isValidId=this.form.personalID=='' || this.Validations.is_valid_israel_id(this.form.personalID);
    },
    checkValidMobilePhone(){
      this.isValidMobilePhone=this.form.mobileNumber=='' || this.Validations.is_valid_mobile_phone(this.form.mobileNumber);
    },
 },
}   
</script>

<style scoped>

</style>