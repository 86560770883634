<template>
    <div class="body">
      <div class="container-login">
         <div class="login-form">
            <img src="@/assets/images/logo.svg" class="logo only-desktop">
            <img src="@/assets/images/logo-mobile.svg" class=" logo-mobile only-mobile">
            <div v-if="SendLink" class="validate-email">ברגעים אלו נשלח אליכם קישור לצורך אימות האימייל</div>
            <div v-if="successfullyEmail" class="validate-email"> האימייל שלך אומת בהצלחה, המתן לאישור!</div>
            <div v-if="inactiveLink" class="validate-email">קישור לא תקין</div>
        </div>
      </div>
    </div>
</template>

<script>
   import axios from "axios";
   
    export default {
        title:'אימות אימייל',
         name: 'ConfirimEmail',
         props: {
         msg: String
        },
         data() {
             return {
               SendLink: false,
               successfullyEmail: false,
               inactiveLink: false, 
               token: ""
             }
         },
         mounted: function() {
            this.token = this.$route.query.token;  
            this.SendLink=true;
            this.CheckToken();
            
        //   CheckToken();
         },
         methods: {
             CheckToken(){
               axios.post(process.env.VUE_APP_BASE_URL + "/User/ConfirimEmail/" + encodeURI(this.token)) 
               .then((res)=>{
                  this.SendLink=false;
                  this.successfullyEmail=true;
                  this.SendLink=false
               })
               .catch(
                ()=>{
                  this.SendLink=false;
                  this.successfullyEmail=false;
                  this.SendLink=true
                }); 
    }
         },
    }
</script>

<style lang="scss" scoped>

</style>