<template>   
      <div class="content-container">
        <div class="header-container">
            <label class="main-title">בחירת חברה</label>
        </div>
        <div class="company"  >
            <div v-for="company in companies" :key="company.companyID" @click="chooseCompany(company)">
              <img :src="mediaURL + company.logoPath"  class="logo-company"/>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

    export default {
      title:'עיקרי תוכניות',
       name: 'MainPlans',
       props:{
        msg: String
    },
      data(){
        return{
           companies:[],
           token: "",
           mediaURL:process.env.VUE_APP_BASE_MEDIA_URL
        }
    },
     mounted() {
        this.GetAllcompanies();
        this.token = this.$route.query.token;

      },
     methods: {
         GetAllcompanies(){
              axios.get(process.env.VUE_APP_BASE_URL + "/Company/GetAllCompanies/")
              .then(res => {
                this.companies = res.data;
                // this.companies=res.data.map(function (s) { return  { "text":s.name , "key":s.companyID }});  
                console.log(res.data);
             
         });
         },
          chooseCompany(company){
              console.log(company.name);
                this.$router.push({ path: "/app/Company",
                                    query: { companyID  : company.companyID }
                }); 
               
            },
     }, 
        
    }
</script>

<style lang="scss" scoped>

</style>