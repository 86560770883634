<template>
        <div class="company" ref="compContainer">
          <div
            v-for="(company, index) in companies"
            :key="company.companyID"
            :ref="setCompanyDivs"
            @click="GetAllStatuses(company.companyID, index)"
            :class="selectedCompanyID == company.companyID ? 'selected-company' : 
                    (selectedCompanyID>0 ?  'only-desktop':'')"
          >
            <div class="mobile-company"   
             :style="{ 'background-image': 'url(' + mediaURL + company.logoPath + ')' }"
             >
            </div>
            <div
              v-if="selectedCompanyID == company.companyID"
              class="list-status"
              :style="{
                right: popup.right,
              }"
            > 
              <div
                v-for="status in statuses"
                :key="status.status"
                class="container-status"
                 @click="getStatus(company, status)"
              >
                <span>{{ status.amount }}</span>
                <div>
                  <span :class="'circle status-' + status.status"></span>
                  <div>
                    {{ EnumManager.GetText(SaleDetailStatusMainEnum, status.status) }}
                  </div>
                </div>
              </div>
              <div class="all-status" @click="allStatuses(company)">
                <div >
                  <a>כל הסטטוסים</a>
                  <img src="@/assets/images/chevron-right.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
</template>

<script>
import axios from "axios";
import { EnumManager, SaleDetailStatusMainEnum } from "../code/enums";

export default {
  title: "המכירות שלי",
  name: "MySales",
  props: {
    msg: String,
  },
  data() {
    return {
      companyDivs: [],
      companies: [],
      statuses: [],
      mediaURL: process.env.VUE_APP_BASE_MEDIA_URL,
      selectedCompanyID: -1,
      firstDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1,12,0),
      EnumManager,
      SaleDetailStatusMainEnum,
      popup: {
        right: "0px",
      },
    };
  },
  mounted() {
    this.GetAllcompanies();
    console.log(this.firstDate);
  },
  beforeUpdate() {
    this.companyDivs = [];
  },
  updated() {
    console.log(this.companyDivs);
  },
  methods: {
    GetAllcompanies() {
      axios
        .get(process.env.VUE_APP_BASE_URL + "/Company/GetAllCompanies/")
        .then((res) => {
          this.companies = res.data;

          console.log(res.data);
        });
    },
    setCompanyDivs(el) {
      if (el) {
        this.companyDivs.push(el);
      }
    },
     getStatus(company,status){ 
      console.log(status);
       this.$router.push({ path: "/app/BackOfficeSales",
                            query: { companyID  : company.companyID , status : status.status}
                });    
    },
   allStatuses(company){ 
           this.$router.push({ path: "/app/BackOfficeSales",
                                    query: { companyID  : company.companyID}
                }); 
    },
    GetAllStatuses(companyID, index) {
      if (companyID == this.selectedCompanyID) {
        this.selectedCompanyID = -1;
        return;
      } else {
        this.selectedCompanyID = companyID;
        var d = this.companyDivs[index];
        // var d = this.$refs.setCompanyDivs();
        var rect = d.getBoundingClientRect();
        var containerRect = this.$refs.compContainer.getBoundingClientRect();

        console.log("rects", d, rect);

        this.popup.right =
          -(containerRect.left + containerRect.width - (rect.left + rect.width)) + "px";
      }
      this.statuses = [];
      axios
        .get(
          process.env.VUE_APP_BASE_URL +
            `/Sale/GetAllDetailSammary?companyID=${
              this.selectedCompanyID
            }&firstDate=${this.firstDate.toISOString().slice(0, 10)}`
        )
        .then((res) => {
          this.statuses = res.data;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
