import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"
import titleMixin from "./mixins/titleMixin"
import UICollapsiblePanel from './components/UIComponents/UICollapsiblePanel.vue'
import UIInlineTab from './components/UIComponents/UIInlineTab.vue'
import UISelect from './components/UIComponents/UISelect.vue'
import UITabsGroup from './components/UIComponents/UITabsGroup.vue'
import UIChat from './components/UIComponents/UIChat.vue'
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import './assets/css/dx-override.css' 
import axios from "axios";

axios.defaults.headers.Authorization = localStorage.getItem('jwtToken')

console.log("init app");

axios.get(process.env.VUE_APP_BASE_URL + "/User/GetCurrentVersion")
.then(res => {
      localStorage.setItem("globalAppVersion",res.data); 
});

const app=createApp(App) 
.use(router);

// app.filter('formatDate', function(value) {
//       if (value) {
//           return moment(String(value)).format('MM/DD/YYYY hh:mm')
//       }
//   });

var loggedInInfo={ isLogged : null };


app.config.globalProperties.$loggedInInfo = loggedInInfo;

app.mixin(titleMixin);

app.component('UICollapsiblePanel',UICollapsiblePanel);
app.component('UIInlineTab',UIInlineTab);
app.component('UISelect',UISelect);
app.component('UITabsGroup',UITabsGroup);
app.component('UIChat',UIChat);

app.mount('#app');
 

router.loggedInInfo= loggedInInfo;



// axios.get(process.env.VUE_APP_BASE_URL + "/User/GetCurrentLoggedInUserID")
// .then(res => {
//     console.log("LOGIN CHECK");
//     console.log(res);
//  if (res.data==0)
//   { 
//       router.loggedInInfo.isLogged=false;
//        router.push({ path: "/Login"});
// }
//  else
// {
//     router.loggedInInfo.isLogged=true;
// }
// })
// .catch(error => {
//     router.push({ path: "/Index"});

// })