<template>
    <div>
     <div class="company-title only-mobile">
        <span @click="BackToCompanies()">
            <img src="@/assets/images/arrow-black.svg" class="arrow-black">
        </span>   
        <img :src="mediaURL + company.logoPath"  class="logo-company-header"/>
        <img src="@/assets/images/search.svg" class="icon-search"/>
     </div>
     <div class="company-title only-desktop">
        <img :src="mediaURL + company.logoPath"  class="logo-company-header"/>
        <span>
            <img src="@/assets/images/globe.svg" class="img-globe">
            <label >{{ company.name}} </label>
        </span>
        <span @click="BackToCompanies()">
            <img src="@/assets/images/arrow-black.svg" class="arrow-black">
           <label class="lable-back">חזרה לכל החברות</label>  
        </span>   
     </div>
     <div v-for="plan in company.plans" :key="plan.planID">
         <UICollapsiblePanel :title="plan.description" collapsedCss='Information-title' collapsedLoad="true" expandedCss='Information-title-open' >
              <div style="position:relative" class="container-company" >
                 <div class="open-panel">
                 <label class="lable-title">שם תוכנית
                     <span>{{plan.name}}</span>
                 </label>
                 <label>תיאור
                     <span>{{plan.description}}</span>
                 </label>
                 <label>מחיר
                     <span>{{plan.price}}</span>
                 </label>
                 <label>דגשים
                     <span>{{plan.description}}</span>
                 </label>
                 <label>הערות
                     <span>{{plan.notes}}</span>
                 </label>
               </div> 
               <button class="btn-send" @click="sendIktov(plan)"> שלח איקטוב </button>  
             </div>  
         </UICollapsiblePanel>
     </div>
    </div>
</template>

<script>
import axios from "axios";
import UICollapsiblePanel from './UIComponents/UICollapsiblePanel.vue';

    export default {
  components: { UICollapsiblePanel },
       name: 'Company',
       props: {
       msg: String
  }     ,
    data(){
            return {
               companyID : this.$route.query.companyID,
               company: { description: "", logoPath:"" ,  plans : [] , price:"" , notes:"" },
               mediaURL:process.env.VUE_APP_BASE_MEDIA_URL
            }
    },
    mounted() {
        this.getPlans();
     
    },
    methods: {
        getPlans(){
            axios.get(process.env.VUE_APP_BASE_URL + "/Plan/GetCompanyWithPlans/" + this.companyID)
              .then(res => {
                this.company = res.data; 
                // this.companies=res.data.map(function (s) { return  { "text":s.name , "key":s.companyID }});  
                console.log(res.data);
            });
        },
        BackToCompanies(){
           this.$router.push({ path: "/app/mainPlans"});  
        },
        sendIktov(plan){
            this.$router.push({ path: "/app/sendIktov",
                 query: { planID  : plan.planID }
             });  
        }
    }
    }
</script>

<style lang="scss" scoped>

</style>