<template>
  <div>
    <div class="only-mobile title" v-if="!isShowMenu">
      <img
        src="@/assets/images/menu.svg"
        class="icon-menu-desktop"
        @click="showMenu()"
      />
      <label>כותרת</label>
      <img src="@/assets/images/logo-menu.svg" class="logo-menu" />
    </div>
    <div class="main-container" @click="hideMenu()">
      <div v-if="isShowMenu" class="mobile-popup-menu" @click.stop="">
        <div class="header-mobile only-mobile">
          <div>
            <div class="icon-user" @click="updatePersonalDetails()">
              <img src="@/assets/images/user.svg" />
            </div>
            <div class="user">
              <label class="lbl-user">{{ userInfo.fullName }}</label>
              <div>
                <label class="lbl-des">
                 {{ EnumManager.GetText(UserGroupsEnum,userInfo.group) }}
                </label>
              </div>
            </div>
            <div @click="logoutSystem()">
              <img
                src="@/assets/images/logout-mobile.svg"
                class="logout-mobile"
              />
            </div>
          </div>
        </div>
        <div class="menu only-mobile">
          <img
            src="@/assets/images/logo-menu.svg"
            class="logo-menu only-desktop"
          />
          <div class="menu-center">
            <div
              @click="ClickMenuItem(mi)"
              v-for="mi in menuItems"
              :key="mi.Name"
            >
              <div
                v-if="
                  currentPath.endsWith('/app/' + mi.Name) ||
                  currentPath.includes('/app/' + mi.Name + '/')
                "
              >
                <img :src="'/images/' + mi.Image" class="icon-menu" />
              </div>
              <div v-else>
                <img :src="'/images/fi_' + mi.Image" class="icon-menu" />
              </div>
              <a>{{ mi.Caption }} {{ activeIcon }}</a>
            </div>
          </div>
          <div class="bottom-menu only-mobile div-hr">
            <hr class="hr-menu" />
            <div class="export">
              <img src="@/assets/images/export.svg" />
            </div>
            <div>
              <img src="@/assets/images/exel-mobile.svg" />
            </div>
            <div>
              <img src="@/assets/images/logo-little.svg" />
            </div>
            <div @click="contact()">
              <img src="@/assets/images/phone.svg" />
            </div>
          </div>
        </div>
      </div>

      <div class="menu only-desktop">
        <img
          src="@/assets/images/logo-menu.svg"
          class="logo-menu only-desktop"
        />
        <div class="menu-center">
          {{ activeIcon }}
          <div
            @click="ClickMenuItem(mi)"
            v-for="mi in menuItems"
            :key="mi.Name"
          >
            <div
              v-if="
                currentPath.endsWith('/app/' + mi.Name) ||
                currentPath.includes('/app/' + mi.Name + '/')
              "
            >
              <img :src="'/images/' + mi.Image" class="icon-menu" />
            </div>
            <div v-else>
              <img :src="'/images/fi_' + mi.Image" class="icon-menu" />
            </div>
            <a>{{ mi.Caption }} {{ activeIcon }}</a>
            <div
              v-if="
                currentPath.endsWith('/app/' + mi.Name) ||
                currentPath.includes('/app/' + mi.Name + '/')
              "
            >
              <img src="@/assets/images/bold-line.png" class="bold-line" />
            </div>
          </div>
        </div>
      </div>
      <div class="page-content">
        <div class="header only-desktop">
          <div class="text-ideal">
            <label class="ideal">אידיאל תקשורת</label>
            <label class="version-black"
              >מספר גירסה: {{ globalAppVersion }}</label
            >
          </div>
          <div>
            <div>
              <img src="@/assets/images/export.svg" />
            </div>
            <!--div>
              <img src="@/assets/images/exel.svg" />
            </div-->
            <div>
              <img src="@/assets/images/logo-little.svg" />
            </div>
            <div @click="contact()">
              <img src="@/assets/images/phone.svg" />
            </div>
            <div class="icon-user" @click="updatePersonalDetails()">
              <img src="@/assets/images/user.svg" />
            </div>
            <div class="user">
              <label class="lbl-user">{{ userInfo.fullName }}</label>
              <div>
                <label class="lbl-des">
                 {{ EnumManager.GetText(UserGroupsEnum,userInfo.group) }}
                </label>
              </div>
            </div>
            <div>
              <img class="line" src="@/assets/images/line.svg" />
            </div>
            <div class="bell-ring">
              <a>
                <img src="@/assets/images/bell-ring.svg" />
              </a>
            </div>
            <div @click="logoutSystem()">
              <span>
                <img src="@/assets/images/logout.svg" />
              </span>
            </div>
            <div class="only-mobile logout-mobile" @click="logout()">
              <img src="@/assets/images/logout-mobile.svg" />
            </div>
          </div>
        </div>
        <div>
          <div class="container-page">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import { menuItems} from "./menuItems.js";
import { EnumManager,UserGroupsEnum } from "../code/enums";

export default {
  name: "Layout",
  props: {
    activeTab: String,
    activeIcon: String,
  },
  data() {
    return {
      userInfo: {
        fullName: "",
        group: "",
      },
      isShowMenu: false,
      isActiveHeart: false,
      isActivefiles: false,
      menuItems: menuItems,
      currentPath: "",
      globalAppVersion: "",
      logout: 1,
      EnumManager,
      UserGroupsEnum
    };
  },
  mounted() {
    this.globalAppVersion = localStorage.getItem("globalAppVersion");
    console.log(localStorage.getItem("userInfo"));
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    console.log(this.menuItems);
    this.currentPath = window.location.href;

    if (this.$router.loggedInInfo.isLogged === null) {
      axios
        .get(process.env.VUE_APP_BASE_URL + "/User/GetCurrentLoggedInUserID")
        .then((res) => {
          if (res.data == 0) {
            this.$router.loggedInInfo.isLogged = false;
            this.$router.push({ path: "/Login" });
          } else {
            this.$router.loggedInInfo.isLogged = true;
          }
        })
        .catch((error) => {
          this.$router.push({ path: "/Index" });
        });
    }
  },
  methods: {
    ClickMenuItem(mi) {
      this.$router
        .push({
          path: "/app/" + mi.Name,
        })
        .then(() => (this.currentPath = window.location.href));
      this.isShowMenu = false;
    },
    showMenu() {
      this.isShowMenu = true;
    },
    hideMenu() {
      this.isShowMenu = false;
    },
    updatePersonalDetails() {
      this.$router.push({ path: "/app/PersonalInformation" });
      this.isShowMenu = false;
    },
   contact(){
      this.$router.push({ path: "/app/Contact" });
      this.isShowMenu = false;
   },
    logoutSystem() {
      console.log("logggin out");

      this.$router.loggedInInfo.isLogged = false;
      axios
        .post(process.env.VUE_APP_BASE_URL + "/User/Logout", "")
        .then((res) => {
          console.log("API LOG OUT");

          this.$router.push({ path: "/Login" });
        });
    },
  },
};
</script>