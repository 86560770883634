<template>
    <div class="content-container">
      <div class="container-contact-header">
        <label>צור קשר</label>
        <div class="header-contact" >
          <img src="@/assets/images/arrow-black.svg" class="arrow-black">
          <label class="lable-back" @click="Back()">חזור</label>
        </div>
      </div>
      <UICollapsiblePanel title="התחשבנות" class="Information-title"  collapsedLoad="true" expandedCss='Information-title-open'>
        <div class="container-contact">
          <label>בענין התחשבנות יש לפנות במייל</label>
          <a href="mailto:office@ideali.co.il">שלח מייל</a>
          <label>בכל שאלה ניתן לפנות להתחשבנות</label>
          <a href="tel:972799103333">0799-10-3333</a>
          <span>שלוחה מספר 3</span>
          <label>במקרים דחופים בלבד ניתן לפנות לעמנואל</label>
          <a href="tel:972528002000">052-8002000</a>
        </div>
      </UICollapsiblePanel> 
      <UICollapsiblePanel title="תפעול / חיבורי קוים" class="Information-title"  collapsedLoad="true" expandedCss='Information-title-open'>
      <div class="container-contact">
           <a href="tel:972799103333">0799-10-3333</a>
           <label>יש ללחוץ 1 לתפעול ולאחר מכן: </label>
           <div class="contact-details-companies">
             <label>סלקום</label>
             <label>שלוחה 1</label>
             <a href="mailto:cellcom@ideali.co.il">שלח מייל</a>
           </div>
           <div class="contact-details-companies">
             <label>פרטנר</label>
             <label>שלוחה 2</label>
             <a href="mailto:partner@ideali.co.il">שלח מייל</a>
           </div>
           <div class="contact-details-companies">
             <label>הוט</label>
             <label>שלוחה 3</label>
             <a href="mailto:hot@ideali.co.il">שלח מייל</a>
           </div>
           <div class="contact-details-companies">
             <label>פלאפון</label>
             <label>שלוחה 4</label>
             <a href="mailto:pelephone@ideali.co.il">שלח מייל</a>
           </div>
           <div class="contact-details-companies">
             <label>012</label>
             <label>שלוחה 5</label>
             <a href="mailto:012@ideali.co.il">שלח מייל</a>
           </div>
           <div class="contact-details-companies">
             <label>גולן</label>
             <label>שלוחה 7</label>
             <a href="mailto:golan@ideali.co.il">שלח מייל</a>
           </div>
           <div class="contact-details-companies">
             <label>רמי לוי</label>
             <label>שלוחה 8</label>
             <a href="mailto:ramilevi@ideali.co.il">שלח מייל</a>
           </div>
           <label> במקרים דחופים בכל החברות ניתן לפנות לקלמן אידלמן</label>
           <div class="contact-details-companies" >
             <a href="tel:972528002003">052-800-2003</a>
             <a href="mailto:service@ideali.co.il">שלח מייל</a>
           </div>
           <label>במקרים חריגים ובאין מענה ניתן להתקשר לעמנואל כהן</label>
           <div class="contact-details-companies">
             <a href="tel:972528002000">052-800-2000</a>
             <a href="mailto:mano@ideali.co.il">שלח מייל</a>
            </div>
        </div>
      </UICollapsiblePanel> 
      <UICollapsiblePanel title="הנהלה / הצעות/ הערות" class="Information-title"  collapsedLoad="true" expandedCss='Information-title-open'>
      <div  class="container-contact">
          <a href="mailto:service@ideali.co.il">שלח מייל</a>
        </div>
      </UICollapsiblePanel> 
    </div>
</template>

<script>
    export default {
        title:'צור קשר',
         name: 'Contact',
         props: {
         msg: String
        },
    }
</script>

<style lang="scss" scoped>

</style>