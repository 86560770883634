<template>
  <div class="container-table">
    <DxDataGrid
      :data-source="dataSource"
      :show-borders="false"
      :rtl-enabled="true"
      :remoteOperations="false"
      :allowColumnResizing="true"
      :show-column-lines="false"
      :show-row-lines="true"
      :column-hiding-enabled="true"
      class="tree-list"
      @exporting="onExporting"
      @exported="onExported"
    >
      <!-- :column-auto-width="true" -->
      <DxExport
      :enabled="true" 
      /> 
      <DxEditing
        :allow-deleting="true"
        :allow-updating="true"
        :allow-adding="true"
        mode="row"
      >
        <DxTexts
          delete-row="מחק"
          edit-row="ערוך"
          save-row-changes="אישור"
          cancel-row-changes="ביטול"
        />
      </DxEditing>
      <!-- 
   <DxSearchPanel
        :visible="true"
        :width="240"
        placeholder="חיפוש..."
      /> -->
      <DxFilterRow :visible="true" />
      <DxHeaderFilter :visible="true" />

      <DxColumn :width="100" data-field="fullName" caption="שם נציג" alignment="right"/>
      <DxColumn data-field="userName" caption="שם משתמש" alignment="right"  :hiding-priority="6"/>
      <DxColumn data-field="password" caption="סיסמה" alignment="right"  :hiding-priority="3"/>
      <DxColumn data-field="email" caption=" אימייל" alignment="right"  :hiding-priority="4"/>
      <DxColumn data-field="mobileNumber" caption="טלפון " alignment="right"  :hiding-priority="5"/>
      <DxColumn data-field="group" caption="סוג משתמש" alignment="right"  :hiding-priority="1">
        <DxLookup :data-source="UserGroupsEnum" value-expr="value" display-expr="text" searchPlaceholder="חפש"/>
      </DxColumn>
      <DxColumn
        data-field="activityTypeID"
        caption="קוד פעילות "
        :allowFiltering="false"
        :allowHeaderFiltering="true"
        searchPlaceholder="חפש"
        alignment="right"
         :hiding-priority="0"
      >
        <DxLookup
          :dataSource="ActivityTypesDataSource"
          value-expr="activityTypeID"
          display-expr="name"
        >
        </DxLookup>
      </DxColumn>
      <DxColumn data-field="managerID" caption="מנהל" alignment="right"  :hiding-priority="2">
        <DxLookup :data-source="managerDataSource" value-expr="id" display-expr="fullName"
          key="id"
         searchPlaceholder="חפש"/>
      </DxColumn>
       <DxColumn data-field="statusUser" caption="סטטוס" alignment="right" >
        <DxLookup :data-source="UserStatusEnum" value-expr="value" display-expr="text" searchPlaceholder="חפש"/>
      </DxColumn>
    </DxDataGrid>
  </div>
</template>

<script>
import axios from "axios";
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import {
  DxDataGrid,
  DxColumn,
  DxHeaderFilter,
  DxSearchPanel,
  DxFilterRow,
  DxEditing,
  DxTexts,
  DxLookup,
  DxExport
} from "devextreme-vue/data-grid";

import { createStore } from "devextreme-aspnet-data-nojquery";
import { UserGroupsEnum, UserStatusEnum } from "../code/enums";

const urlActiv = process.env.VUE_APP_BASE_URL + "/ActivityType";
const ActivityTypesDataSource = createStore({
  key: "activityTypeID",
  loadUrl: `${urlActiv}/GetAllActivityTypes`,
  onBeforeSend: (method, ajaxOptions) => {   
     ajaxOptions.headers =     { "Authorization" : localStorage.getItem('jwtToken') };
  },
});

const urlManager = process.env.VUE_APP_BASE_URL + "/User";
const managerDataSource = createStore({
  key: "manager",
  loadMode:"raw",
  cacheRawData:false,
  loadUrl: `${urlManager}/GetAllManagers`,
  onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =     { "Authorization" : localStorage.getItem('jwtToken') };
  },
});

const url = process.env.VUE_APP_BASE_URL + "/UserGrid";
const dataSource = createStore({
  key: "userID",
  loadUrl: `${url}/LoadData`,
  insertUrl: `${url}/Insert`,
  updateUrl: `${url}/Update`,
  deleteUrl: `${url}/Delete`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers =     { "Authorization" : localStorage.getItem('jwtToken') };
  },
});

export default {
  name: "UserManagement",
  props: {
    msg: String,
  },
  components: {
    DxDataGrid,
    DxHeaderFilter,
    DxColumn,
    DxExport,
    DxFilterRow,
    DxEditing,
    DxTexts,
    DxLookup,
    //  DxButton
  },
  data() {
    return {
      listUsers: [],
      userID: 0,
      managerUserID: 0,
      activityTypeID: 0,
      group: "",
      dataSource,
      UserGroupsEnum,
      UserStatusEnum,
      ActivityTypesDataSource,
      managerDataSource
    };
  },
  mounted() {
    this.GetListUsers();
  },
  methods: {
    GetListUsers() {
      axios.get(process.env.VUE_APP_BASE_URL + "/Users/GetStatusUsers").then((res) => {
        this.listUsers = res.data;
        console.log(res.data);
      });
    },
    onExporting(e) {
      e.component.beginUpdate();  
      e.component.columnOption(0, "visible", true);
      e.component.columnOption(1, "visible", true);  
      e.component.columnOption(2, "visible", false);  
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('ניהול משתמשים');

      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ניהול משתמשים.xlsx');
        });
      }); 
      e.cancel = true;    
    }
  },
};
</script>

<style lang="scss" scoped></style>
