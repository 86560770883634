<template>
    <div :key="treeRefresh">
      <div class="container-date">
     <UISelect  v-model:value="timeIctub"  ref='mainSelect' :list="[
                  { key: '1', text: 'היום' },
                  { key: '2', text: 'ביום ספציפי' },
                  { key: '3', text: 'בחר טווח תאריכים' }   
                ]"  @update:value="getCalculatedTime()" >
    </UISelect> 
           <DxDateBox
             picker-type="rollers"
           v-if="timeIctub=='2'"
           type="date"
           :width="250"
           placeholder="בחר יום"
           :rtlEnabled="true"
           class="inp-date"
           v-model:value="inputSpecificDay"
           @valueChanged="getCalculatedTime"
          />
      
           <DxDateBox
             picker-type="rollers"
           v-if="timeIctub=='3'"
           type="date"
           :width="250"
           placeholder="תאריך התחלה"
           :rtlEnabled="true"
           class="inp-date"
           v-model:value="inputStartDate"
           @valueChanged="getCalculatedTime"  
          />
         <DxDateBox
           picker-type="rollers"
            v-if="timeIctub=='3'"
            type="date"
           :width="250"
           placeholder="תאריך סיום"
          :rtlEnabled="true"
           class="inp-date"
           v-model:value="inputEndDate"
           @valueChanged="getCalculatedTime"         
          />
      
      </div>
     <div class="container-tabs" >
         <UIInlineTab v-if="timeIctub=='1'" :list="[
                         { key:'1',text:'איקטובים היום'},
                         { key:'2',text:'איקטובים אתמול'},
                         { key:'3',text:'צפי לחודש הנוכחי'},
                         { key:'4',text:'איקטובים החודש'}
                     ]" v-model:value="selTab" @update:value="getCalculatedTime()">
         </UIInlineTab>
         <!-- @update:value="" -->
          <UIInlineTab v-if="timeIctub=='2'" :list="[
                         { key:'1',text:'איקטובים ליום'}, 
                         { key:'2',text:'איקטובים לחודש'} 
                     ]"  v-model:value="selTab1" @update:value="getCalculatedTime()">
         </UIInlineTab>
          <UIInlineTab v-if="timeIctub=='3'" :list="[
                         { key:'1',text:'איקטובים לתאריכים '}           
                     ]" v-model:value="selTab2" @update:value="getCalculatedTime()">
         </UIInlineTab>
      </div>
      <div @click="exportToExcel()" class="exportButton">
          <div>ייצוא לאקסל</div>  
          <img src="@/assets/images/exel.svg" />
       </div>
      <DxTreeList 
       :data-source="data"
       :show-row-lines="true"
       :show-borders="true"
       :column-auto-width="true"
       :word-wrap-enabled="true"
       key-expr="treeKey"
       parent-id-expr="parentTreeKey"
       has-items-expr="hasItems"
       :rtl-enabled="true" 
       :column-hiding-enabled="true"
       class="tree-list"
       ref="tList"
    >
       <DxColumn
        width="105px"
        data-field="userName"
        caption="שם נציג" 
        :hiding-priority="4"

      > 
       </DxColumn>
      <DxColumn v-for="(company, index) in companies" :key="index" cell-template='companyTotal'
            :data-field="'rew' + index"
            :caption="getCompanyName(index)"
             width="105px"
             :hiding-priority="index"
             >  
             <DxFormat
                type="fixedPoint"
                :precision="2"
            />
      </DxColumn> 
     <template #companyTotal="{data: cellInfo }">
     <div style="width:100%;height:30px" v-if='cellInfo.displayValue !==0'>
      {{ cellInfo.displayValue.toFixed(2) }}
      &nbsp;
       <img src="@/assets/images/search-plus.svg" @click="showData(cellInfo)" v-if="selTab!=3" class="icon" />
     </div>
      </template>
       <DxPopup
         :key="popupKey"
        v-model:visible="popupVisible"
        :drag-enabled="false"
        :close-on-outside-click="true"
        :show-close-button="false"
        :show-title="true"
        :width="300"
        :height="breakDownDict.length * 40"
        :rtl-enabled="true"
        container=".dx-viewport"
        title="פרוט קוים"
       >
        <div v-for="(value) in breakDownDict" :key="value">
          <b>שם תוכנית : </b>{{value.planName}} ,  {{value.totalLines}} קוים * {{value.total/value.totalLines}}  ש"ח סה"כ  {{value.total.toFixed(2)}} ש"ח 
        </div>
        </DxPopup>
        <DxColumn
        width="105px"
        data-field="totalRewards"
        caption="סך הכל"
        :hiding-priority="1"  
      >   
        <DxFormat
          type="fixedPoint"
          :precision="2"
         />
        </DxColumn> 
        <DxColumn
         width="105px"
        data-field="totalLines"
        caption="מספר קווים" 
        :hiding-priority="3"
      > 
       </DxColumn>  
    </DxTreeList>
     <!--div v-for="(value) in breakDownDict" :key="value">
  
      שם חברה: {{value.planName}}   
     
      מספר קוים:{{value.totalLines}} 
      סכום: {{value.total}} 
      
      </div -->
     
    </div>

</template>

<script>
import axios from "axios";
import UISelect from "./UIComponents/UISelect.vue";
import UIInlineTab from "./UIComponents/UIInlineTab.vue";
import DxDateBox from "devextreme-vue/date-box";
import { DxTreeList, DxColumn, DxFormat } from "devextreme-vue/tree-list";
import { DxPopup, DxPosition, DxToolbarItem } from "devextreme-vue/popup";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { toRaw } from "@vue/reactivity";

export default {
  components: {
    UISelect,
    UIInlineTab,
    DxDateBox,
    DxTreeList,
    DxColumn,
    DxFormat,
    DxPopup,
  },
  title: "תגמול וצפי",
  name: "RewardAndExpectation",
  props: {
    msg: String,
  },
  data() {
    return {
      timeIctub: "1",
      inputSpecificDay: new Date(),
      inputStartDate: new Date(),
      inputEndDate: new Date(),
      selTab: "1",
      selTab1: "1",
      selTab2: "1",
      today: null,
      companies: [],
      allCompanies: [],
      data: [],
      treeRefresh: 0,
      totalLines: 0,
      totalMoney:0,
      totalMoneyToCompany: 0,
      breakDownDict: {},
      popupVisible: false,
      popupKey: 0
    };
  },
  mounted() {
    console.log("mounted");
    this.$refs.mainSelect.initialText = "mainSelect";
    this.getCalculatedTime();
    this.getAllCompanyNames();
  },
  methods: { 
    getAllCompanyNames() {
      axios
        .get(process.env.VUE_APP_BASE_URL + "/Company/GetAllCompanies/")
        .then((res) => {
          this.allCompanies = res.data;
          // var companyID=this.allCompanies.find(x=>x.companyID==companyID).name;
          this.treeRefresh++;
        });
    },
    GetRewards(start, end) {
      var url = `/Sale/GetRewards?firstDate=${start
        .toISOString()
        .slice(0, 10)}&lastDate=${end.toISOString().slice(0, 10)}`;
      console.log("chng");
      axios.get(process.env.VUE_APP_BASE_URL + url).then((res) => {
        console.log("rewLoaded");
        console.log(res);
        res.data.data.forEach((element) => {
          var index = 0;
          var totRew = 0;
          element.rewards.forEach((r) => {
            element["rew" + index.toString()] = r;
            index++;
            totRew += r;
          });
          element.totalRewards = totRew;
        });

        this.data = res.data.data;
        this.companies = res.data.companies;
        this.treeRefresh++;
      });
    },
    showData(cellInfo) {
      var index = +cellInfo.column.dataField.substr(3, 100);
      console.log(index);
      console.log(cellInfo);
      var data = cellInfo.data.companyRewardDetails[index];
      console.log("breakDownDict");
      this.breakDownDict = data.breakdown;
      this.totalLines = data.totalLines;
      this.totalMoney = data.totalMoney;
      this.popupVisible = true;
      this.popupKey++;
    },
    GetAvgRewards(start, end) {
      var url = `/Sale/GetProjectedReward?firstDate=${start
        .toISOString()
        .slice(0, 10)}&lastDate=${end.toISOString().slice(0, 10)}`;
      console.log("chng");
      axios.get(process.env.VUE_APP_BASE_URL + url).then((res) => {
        res.data.data.forEach((element) => {
          var index = 0;
          var totRew = 0;
          element.rewards.forEach((r) => {
            element["rew" + index.toString()] = r;
            index++;
            totRew += r;
          });
          element.totalRewards = totRew;
        });

        this.data = res.data.data;

        this.companies = res.data.companies;
        this.treeRefresh++;
      });
    },
    getCompanyName(index) {
      return this.allCompanies.find((x) => x.companyID == this.companies[index]).name;
    },
    exportToExcel(e) {  
      var treeList =this.$refs.tList.instance; 
      var columns = treeList.getVisibleColumns();  
      var data = treeList.getVisibleRows();
      var csvContent = "";  
      var i=0,row;
      
      for ( i = 0; i < columns.length; i++) {  
          csvContent += columns[i].caption + ",";  
          }  
      csvContent += "\r\n";
      for ( i = 0; i < data.length; i++) {  
          row = data[i].values;  
          row.forEach(function (item, index) {  
              if (item === undefined || item === null) { csvContent += ","; }  
              else { csvContent += item + ","; }  
             }  
          );  
          csvContent += "\r\n";  
      }  

      var blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {  
          navigator.msSaveBlob(blob, 'תגמול וצפי.xlsx')  
      }  
      else {  
          var link = document.createElement("a");  
          var url = URL.createObjectURL(blob);  
          link.setAttribute("href", url);  
          link.setAttribute('download', 'תגמול וצפי.xlsx');  
          document.body.appendChild(link);  
          link.click();  
          document.body.removeChild(link);  
      }
    },
    getCalculatedTime(ev) {
      console.log(ev);
      console.log("calculating");
      var start = new Date();
      var end = new Date();
      if (this.timeIctub == "1") {
        if (this.selTab == "1") {
          // start.setHours(0, 0, 0);
          // end.setHours(23, 59,59);
          end.setDate(start.getDate() + 1);
          console.log(start);
          console.log(end);
        } else if (this.selTab == "2") {
          // start.setHours(0, 0, 0);
          start.setDate(start.getDate() - 1);
          // end.setHours(0, 0, 0);
          console.log(start);
          console.log(end);
        } else if (this.selTab == "3") {
            start = new Date(start.getFullYear(), start.getMonth(), 1);
            end = new Date(end.getFullYear(), start.getMonth()+1, 1);
            console.log(start);
            console.log(end);
            this.GetAvgRewards(start, end);
          return;
        } else {
          start = new Date(start.getFullYear(), start.getMonth(), 1);
          end = new Date(end.getFullYear(), start.getMonth()+1, 1);
          console.log(start);
          console.log(end);
        }
      } else if (this.timeIctub == "2") {
        if (this.selTab1 == "1") {
          start = new Date(
            this.inputSpecificDay.getFullYear(),
            this.inputSpecificDay.getMonth(),
            this.inputSpecificDay.getDate()
          );
          end = new Date(
            this.inputSpecificDay.getFullYear(),
            this.inputSpecificDay.getMonth(),
            this.inputSpecificDay.getDate() + 1
          );
          console.log(start);
          console.log(end);
        } else {
          start = new Date(
            this.inputSpecificDay.getFullYear(),
            this.inputSpecificDay.getMonth(),
            1
          );
          end = new Date(
            this.inputSpecificDay.getFullYear(),
            this.inputSpecificDay.getMonth() + 1,
            1
          );
          console.log(start);
          console.log(end);
        }
      } else {
        start = new Date(
          this.inputStartDate.getFullYear(),
          this.inputStartDate.getMonth(),
          this.inputStartDate.getDate()
        );
        end = new Date(
          this.inputEndDate.getFullYear(),
          this.inputEndDate.getMonth(),
          this.inputEndDate.getDate() + 1
        );
        console.log(start);
        console.log("end");
        console.log(end);
      }
      this.GetRewards(start, end);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>