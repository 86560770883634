<template>
   <div class="container-table-grid">
    <DxDataGrid
      :data-source="dataSource"
      :show-borders="false"
      :remoteOperations="false"
      :allowColumnResizing="true"
      :show-column-lines="false"
      :show-row-lines="true"
      :rtl-enabled="true"
      :column-hiding-enabled="true"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      class="tree-list"
      @exporting="onExporting"
      @exported="onExported"
      > 
      <DxExport
       :enabled="true"
      />     
    >
      <DxEditing
        :allow-deleting="true"
        :allow-updating="true"
        :allow-adding="true"
        mode="row"
      >
        <DxTexts
          delete-row="מחק"
          edit-row="ערוך"
          save-row-changes="אישור"
          cancel-row-changes="ביטול"
        />
      </DxEditing>
      <DxFilterRow :visible="true" />
      <DxHeaderFilter :visible="true" />
      <DxColumn data-field="userID" caption="שם נציג" :min-width="150" alignment="right" :allowFiltering="false"
        :allowHeaderFiltering="true" >
          <DxLookup
           :dataSource="users"
           value-expr="userID"
           display-expr="fullName" 
            searchPlaceholder="חפש" 
        >
          </DxLookup>
     </DxColumn>
      <DxColumn data-field="feeID" caption="סוג תשלום" :min-width="150" alignment="right" :allowFiltering="false"
        :allowHeaderFiltering="true"  searchPlaceholder="חפש">
       <DxLookup
           :dataSource="fees"
           value-expr="feeID"
           display-expr="feeName"  
            searchPlaceholder="חפש" >
          </DxLookup>
     </DxColumn>
      <DxColumn cell-template="feeValueT" data-field="feeValue" caption="סכום" :min-width="150" alignment="right" :hiding-priority="3"/>
      <template #feeValueT="{data : rowInfo }"> 
           <span v-if="rowInfo.data.isProportional" dir="ltr" >{{(rowInfo.data.feeValue &lt; 0 ?"":"+")}}{{rowInfo.data.feeValue}}%</span> 
             <span v-else >{{rowInfo.data.feeValue}}</span> 
      </template> 
      
      <DxColumn data-field="isProportional" caption="יחסי" :min-width="150" alignment="right" :hiding-priority="2"/>
      <DxColumn data-field="fromDate" caption="מתאריך" :min-width="150" alignment="right" data-type="date" :hiding-priority="1"/>
      <DxColumn data-field="toDate" caption="עד תאריך" :min-width="150" alignment="right" data-type="date" :hiding-priority="0"/>
    </DxDataGrid>
  </div>
</template> 
<script>
import axios from "axios";
import { createStore } from "devextreme-aspnet-data-nojquery";
import {
  DxDataGrid,
  DxColumn,
  DxHeaderFilter,
  DxFilterRow,
  DxEditing,
  DxTexts, 
  DxLookup,
  DxExport
} from "devextreme-vue/data-grid";
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

const url = process.env.VUE_APP_BASE_URL + "/SpecialFeeForUserGrid";
const dataSource = createStore({
  key: "specialFeeForUserID",
  loadUrl: `${url}/LoadData`,
  insertUrl: `${url}/Insert`,
  updateUrl: `${url}/Update`,
  deleteUrl: `${url}/Delete`,
  onBeforeSend: (method, ajaxOptions) => { 
     ajaxOptions.headers =     { "Authorization" : localStorage.getItem('jwtToken') };
  },
});

const urlUser = process.env.VUE_APP_BASE_URL + "/User";
const userDataSource = createStore({
  key: "userID",
  loadUrl: `${urlUser}/GetAllUsers`,
  onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =     { "Authorization" : localStorage.getItem('jwtToken') };
  },
});

const urlFee = process.env.VUE_APP_BASE_URL + "/Fee";
const feeDataSource = createStore({
  key: "feeID",
  loadUrl: `${urlFee}/GetAllFees`,
  onBeforeSend: (method, ajaxOptions) => {  
    ajaxOptions.headers =     { "Authorization" : localStorage.getItem('jwtToken') };
  },
});
    export default {
       title:'תבניות תגמול',
       name: 'SpecialRewardTemplates',
       props: {
       msg: String
  },
  components: {
    DxDataGrid,
    DxHeaderFilter,
    DxColumn,
    DxFilterRow,
    DxEditing,
    DxTexts, 
    DxLookup,
    DxExport
  }, 
  data() {
      return {
          dataSource,
          users:[],
          fees:[]
      }
  },
  mounted() {
     axios.get(process.env.VUE_APP_BASE_URL + "/Sale/UsersUnderCurrent")
          .then(res => {
            this.users = res.data;
            console.log(res.data);  
       });
       axios.get(process.env.VUE_APP_BASE_URL + "/Fee/GetAllFees")
          .then(res => {
            this.fees = res.data;
            console.log(res.data);  
       });
  },
  methods: {
     onExporting(e) {
      e.component.beginUpdate();  
      e.component.columnOption(0, "visible", true);
      e.component.columnOption(1, "visible", true);  
      e.component.columnOption(2, "visible", true);  
      e.component.columnOption(3, "visible", true);
      e.component.columnOption(4, "visible", true);  
      e.component.columnOption(5, "visible", true);  
      e.component.columnOption(6, "visible", false);
      e.component.columnOption(7, "visible", false);  
   
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('תגמולים מיוחדים');

      exportDataGrid({
        component: e.component,  
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'תשלומים מיוחדים.xlsx');
        });
      });
     
      e.cancel = true; 
    }
  },       
    }
</script>

<style lang="scss" scoped>

</style>