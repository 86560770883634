export const menuItems=[
    {
        Caption:'מכירות שלי',
        Name:'mySales',
        Image:'mySales.svg'
    },
    {
        Caption:'תגמול וצפי',
        Name:'rewardAndExpectation',
        Image:'rewardAndExpectation.svg'
    },
    {
        Caption:'עיקרי תוכניות',
        Name:'mainPlans',
        Image:'plans.svg'
    },
    {
        Caption:'טפסים',
        Name:'forms',
        Image:'forms.svg'
    },
    {
        Caption:'שלח איקטוב',
        Name:'sendIktov',
        Image:'send.svg'
    },
    {
        Caption:'מידע ופעולות',
        Name:'informationAndActions',
        Image:'info.svg'
    },
    {
        Caption:'פאנל ניהול',
        Name:'settings',
        Image:'settings.svg'
    },
    {
        Caption:'קישורים',
        Name:'links',
        Image:'links.svg'
    },
    {
        Caption:'ניהול מלאי',
        Name:'inventoryManagement',
        Image:'inventory.svg'
    }
]