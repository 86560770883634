<template>
   <div class="container">
      <div class="container-tabs">
         <UITabsGroup :list="[
                         { key:'1',text:'בק אופיס', route:'/app/settings/backOffice'},
                         { key:'2',text:'עליות וירידות',route:'/app/settings/UpsAndDowns'},
                         { key:'3',text:'תוכניות',route:'/app/settings/Plans'},
                         { key:'4',text:'ניהול משתמשים',route:'/app/settings/UserManagement'},
                         { key:'5',text:'תבניות תגמול',route:'/app/settings/RewardPatterns'},
                         { key:'6',text:'ניהול תגמול',route:'/app/settings/RemunerationManagement'},
                         { key:'7',text:'הודעות',route:'/app/settings/Messages'},
                         { key:'8',text:'ניהול הזמנות',route:'/app/settings/OrderManagement'}
                     ]">
         </UITabsGroup>
      </div>
   </div>
      <div>
        <router-view></router-view>    
    </div>
   
</template>

<script>
import UITabsGroup from './UIComponents/UITabsGroup.vue'

    export default {
       title:'פאנל ניהול',
       components: { UITabsGroup },
       name: 'Settings',
       props: {
          msg: String
  }        
    }
</script>

<style lang="scss" scoped>

</style>