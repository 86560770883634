<template>
     <div class="content-container">
       <div class="header-container">
           <label class="main-title">קישורים לאתרים ומערכות</label>
           <button class="btn-idl only-desktop" v-if="!isEditMode && edit" @click="ChangeMode()" >
               <img src="@/assets/images/edit.svg" class="edit">
               ערוך
           </button>
           <button class="btn-idl only-desktop" v-if="isEditMode  && edit " @click="addLink()">
               <img src="@/assets/images/fi_plus.png" class="plus">
               לינק חדש
           </button>
           <button class="btn-idl only-mobile" v-if="!isEditMode  && edit" @click="ChangeMode()">
               <img src="@/assets/images/edit.svg" class="edit">
           </button>
           <button class="btn-idl only-mobile" v-if="isEditMode  && edit" @click="addLink()">
               <img src="@/assets/images/fi_plus.png" class="plus">
           </button>

    <DxPopup
      v-model:visible="popupVisible"
      :drag-enabled="false"
      :close-on-outside-click="true"
      :show-close-button="false" 
      :show-title="true"
      :width="300"
      :height="280"
      container=".id-popup"
      title="הוספת לינק" 
    >
      <p>
        שם:
        <input v-model="newLink.text">
      </p>
      <p>
        url: 
        <input v-model="newLink.url">
      </p>
      <div class="btn-popup">
        <button @click="addNewLink()"> הוסף</button>
        <button @click="closePopup()"> סגור</button>   
      </div>
    </DxPopup>

       </div>  
        <DxSortable :key="componentKey"
              :data="list"
              class="links"   
              itemorientation="horizontal"   
              @reorder="onDrop($event)" 
              :drag-direction="both"
              >
<!--    @add="onTaskDrop($event)"  -->

             <div v-for="link in GetLinkArray()" :key="link.linkID">
               <!-- <img v-bind:src="'/' + link.ImagePath" />   -->
               <div style='background-image: url("@/assets/images/")' class="container-link" >
               <button class="btn-del" @click="deleteLink(link)" v-if="edit">
                    <img src="@/assets/images/delete.svg" class="del"> 
              </button>
               </div>
               <div >
                   <img src="@/assets/images/link.png" class="img-link">
                   <a class="link-name" v-if="!isEditInput">{{link.text}}</a>
                   <input type="text" v-model="link.text" v-if="isEditInput" class="edit-input" @change="editInput()">
               </div>
           </div>         
              
            </DxSortable>
           
   </div>   
    <div  class="id-popup"></div>
</template>
<script>
import axios from "axios";
import { DxSortable} from 'devextreme-vue/sortable';
import { DxPopup } from 'devextreme-vue/popup';
    export default {
    title:'קישורים', 
    components: { 
          DxSortable,
          DxPopup
        },
    data(){
        return{
           links:[],
           componentKey:0,
           popupVisible:false,
           isEditMode:false,
           isEditInput:false,
           newLink:{
              linkId:0,
              text:'',
              url:'',
              // sortOrder:'',
              imagePath:''
            },
          edit:false
           }
        },
       mounted() {
       this.getUserGroup();
       this.getAllLinks();

      },
     methods: {
      getAllLinks(){
        axios.get(process.env.VUE_APP_BASE_URL + "/Link/GetAllLinks/")
        .then(res => {
          this.links = res.data;
          // this.links=res.data.map(function (s) { return  { "text":s.text , "key":s.linkID }});  
          console.log(res.data);       
       });   
      },  
      GetLinkArray(){
        var res= this.links.sort((a,b)=>a.sortOrder - b.sortOrder); 
        var index=1;
        res.forEach(element => {
         element.sortOrder=index++; 
        }); 
         this.links=res;
         return res;  
      },
      getUserGroup(){
        axios.get(process.env.VUE_APP_BASE_URL + "/User/GetUserGroupLoggedIn")
             .then(res => {
              console.log(res);
              if(res.data==1)
                this.edit=true;        
        });
      },
      onDrop(e){
        var i;
        console.log(e);
        if (e.fromIndex<e.toIndex){
            this.links[e.fromIndex].sortOrder=this.links[e.toIndex].sortOrder+0.1;
           } else if (e.fromIndex>e.toIndex) {
            this.links[e.fromIndex].sortOrder=this.links[e.toIndex].sortOrder-0.1;
           }
        console.log(this.links);
        this.updateLinks();
        this.componentKey++;
      },
         ChangeMode(){
             this.isEditMode=true;
             this.isEditInput=true;
         },
         editInput(){
             this.isEditInput=false;
             this.isEditMode=false;
         },
         addLink(){
          this.popupVisible=true;
         },
         closePopup(){
           this.popupVisible=false;
         },
          deleteLink(link){
           axios.delete(process.env.VUE_APP_BASE_URL + "/Link/DeleteLink/" + link.linkID)
           .then((res)=>{
              this.getAllLinks();
              console.log( res.data);
            })
            .catch(
            (e)=>{           
             });
         },
         addNewLink(){
            // this.links.push(this.newLink);
            axios.post(process.env.VUE_APP_BASE_URL + "/Link/PostLink",this.newLink) 
             .then((res)=>{
               this.popupVisible=false;
               this.getAllLinks();
               })
             .catch(
              ()=>{
                   
             });
         },
          updateLinks() {
                axios.put(process.env.VUE_APP_BASE_URL + "/Link/PutLink" , this.links)
                        .then(res => {
                            console.log('updated');
                        })
                        .catch(error => {
                            console.error(error);
                        });
            }
     }, 
    }
</script>

<style lang="scss" scoped>

</style>