<template>
   <div>  
     <div class="select"  @click="opened=!opened" >
       <img src="@/assets/images/chevron-down.svg"  v-if="!autoComplete"
        > 
        <div>
          <div >
            <label >{{caption}}</label><span style="font-size:xx-small"></span>
          </div>
            <input v-if="!autoComplete && !getSomethingSelected()" :placeholder="placeholder" >      
            <input :value="getSelectedText()" v-if="!autoComplete" />
            <input v-model="typedText" v-if="autoComplete" @keyup="bringOptionsAPI()" 
              @blur="blurAX()" :placeholder="placeholder" />  
        </div>          
   </div>
   <div style="position:relative">
     <div class="select-container" style="position:absolute; top:0"  v-if="opened">
       <div v-for="item in listToDisplay" :key="item.key" @click="select(item)">
         <label >{{item.text}}</label> 
       </div>
       <div v-if="opened &&  listToDisplay.length==0">
         <label >אין תוצאות</label>           
         </div> 
     </div> 
   </div>
   </div>
</template>

<script>
    import axios from "axios";

    export default {
         name:"UISelect",
         props: {
            caption:String,
            placeholder:String,
            value:String,
            val:String,
            apiAddress:String,
            autoComplete:Boolean,
            list:Array,
            initialTextProp:String,
        },
       
        emits: ['update:value','update:val'],
        data() {
            return {
                opened:false,
                selectedValue:"",
                selectedText:"",
                typedText:"",
                listToDisplay:[] 
            }
         },
         mounted() {
                 this.listToDisplay=this.list;                
                 if (this.autoComplete){                  
                   this.typedText=this.initialTextProp;
                } 
         },
         methods: {
         blurAX() {
            setTimeout(()=>{this.opened=false},5);
         },
              getSelectedText(){
                console.log("getSelected text");
                console.log(this.listToDisplay);
                console.log(this.value);
                  if (this.listToDisplay){
                    var resIndex=this.listToDisplay.findIndex(x=>x.key==this.value);
                    if (resIndex>=0){
                      return this.listToDisplay[resIndex].text;
                    }
                }
                  return "";
               
              },
              getSomethingSelected(){
                 if (this.listToDisplay){ 
                    var resIndex=this.listToDisplay.findIndex(x=>x.key==this.value);
                    return resIndex>=0;
                 }
                 return false;
              },
             select(item){ 
                 this.selectedText=item.text; 
                 this.typedText=item.text;
                 this.opened=false;
                 this.$emit('update:value',item.key);
                 this.$emit('update:val',item.text);

             },
             bringOptionsAPI(){
                 console.log(this.typedText);
                 if (this.typedText.length>=2)
                {
                    console.log(process.env.VUE_APP_BASE_URL + "/" + this.apiAddress + "/" + encodeURI(this.typedText));
                    axios.get(process.env.VUE_APP_BASE_URL + "/" + this.apiAddress + "/" + encodeURI(this.typedText))
                    .then(res => {
                         this.listToDisplay=res.data;
                          this.opened=true;
                    })
                    .catch(error => {
                         // Manage errors if found any
                    })
                }
                else {
                  this.opened=false;                  
                }
             }
         },
    }
</script>
