<template>
  <div class="form-container" :key="index"> 
    <div class="content-container">
    <form @submit.prevent="sendNewSale">
      <div class="container dx-viewport">
        <UICollapsiblePanel title="פרטי נציג" class="agent-details">
          <div class="panel">
            <!-- <UISelect  placeholder="שם נציג" :autoComplete="true" apiAddress="Sales/AutoCompleteUsers"
                        ref="userSelect" v-model:value="newSale.userID" >
                   </UISelect> -->
            <!-- :data-source="process.env.VUE_APP_BASE_URL + '/Sale/UsersUnderCurrent'" -->
            <lookupContainer style="width: 300px">
              <DxLookup
                :items="employeesList"
                :key="componentKey"
                searchExpr="fullName"
                searchPlaceholder="חפש"
                :rtlEnabled="true"
                class="input-css"
                v-model:value="newSale.userID"
                value-expr="userID"
                display-expr="fullName"
              >
                <DxDropDownOptions
                  :show-title="false"
                  :close-on-outside-click="true"
                />
              </DxLookup>
            </lookupContainer>
          </div>
        </UICollapsiblePanel>
        <UICollapsiblePanel title="פרטי לקוח" class="agent-details">
          <div class="panel">
            <div>
              <input placeholder="תעודת זהות" v-model="newSale.personalID" @keyup="checkIsValidIdׁׂ()" @change="isUserExist()" :class="isValidId?'':'red-border'"/>
              <input placeholder="שם פרטי" v-model="newSale.firstName" @keyup="checkIsValidFirstNameׁׂ()" :class="isValidfirstName?'':'red-border'"/>
              <input placeholder="שם משפחה" v-model="newSale.lastName" @keyup="checkIsValidLastNameׁׁׂׂ()" :class="isValidlastName?'':'red-border'"/>
              <DxAutocomplete
                :data-source="cities"
                v-model:value="newSale.city"
                placeholder=" עיר"
                :minSearchLength="2"
                value-expr="text"
                search-mode="startswith"  
                class="input-autocomplete" 
                :rtlEnabled="true"
                @change='checkCity()'
              />
              <DxAutocomplete
                :data-source="streets"
                v-model:value="newSale.street"
                placeholder=" רחוב"
                 :minSearchLength="2"
                value-expr="text"
                search-mode="startswith" 
                class="input-autocomplete" 
                 :rtlEnabled="true"
                @change='checkStreet()'
              />

               <!-- <UISelect
                placeholder="הקלד שם העיר"
                :autoComplete="true"
                v-model:value="newSale.cityID"
                apiAddress="City/AutoCompleteCities"
                @update:value="isSelectedStreet()"
                :initialTextProp="newSale.city"
              >
              </UISelect>-->
              <!-- <div v-if="errorOnCity">בחר עיר!</div> -->
              <!-- <UISelect
                placeholder="הקלד כתובת"
                :autoComplete="true"
                :apiAddress="'Street/AutoCompleteStreets/' + newSale.cityID"
                v-model:value="newSale.streetID"
                :initialTextProp="newSale.street"
              >
              </UISelect>-->
              <input placeholder="מספר בית" v-model="newSale.numStreet" @keyup="checkValidateNumStreet()" :class="isValidateNumStreet?'':'red-border'"/>
              <input placeholder="טלפון של הלקוח" v-model="newSale.mobile" @keyup="checkValidMobilePhone()" :class="isValidMobilePhone?'':'red-border'"/>
              <input placeholder="אימייל לשליחת חוזה" v-model="newSale.email" @keyup="checkValidEmail()" :class="isValidEmail?'':'red-border'" />
            </div>
          </div>
        </UICollapsiblePanel>
        <UICollapsiblePanel title="פרטי תשלום" class="agent-details">
          <div class="panel">
            <div class="container-radios">
              <label class="container-radio">
                <input
                  type="radio"
                  name="payType"
                  value="cc"
                  v-model="payType" 
                />
                <span class="radio-text">אשראי</span>
                <span class="checkmark"></span>
              </label>
              <label class="container-radio">
                <input
                  type="radio"
                  name="payType" 
                  value="bank"
                  v-model="payType"
                />
                <span class="radio-text">הוראת קבע</span>
                <span class="checkmark"></span>
              </label>
            </div>
            <div v-if="payType=='cc'">
            <input placeholder="מספר כרטיס" v-model="newSale.cC_INFO" @keyup="checkValidCard()" :class="isValidCard?'':'red-border'" />
            <input  placeholder="cvv" v-model="newSale.cvv" @keyup="checkValidCVV()" :class="isValidCVV?'':'red-border'" />
            <UISelect
              placeholder="בחירת שנה"
              :list="years"
              v-model:value="newSale.expirationYear"
              >
            </UISelect>
            <UISelect
              v-model:value="newSale.expirationMonth"
              placeholder="בחירת חודש"
              :list="[
                { key: '01', text: '01' },
                { key: '02', text: '02' },
                { key: '03', text: '03' },
                { key: '04', text: '04' },
                { key: '05', text: '05' },
                { key: '06', text: '06' },
                { key: '07', text: '07' },
                { key: '08', text: '08' },
                { key: '09', text: '09' },
                { key: '10', text: '10' },
                { key: '11', text: '11' },
                { key: '12', text: '12' },
              ]"
              >
              </UISelect>
              <div class="btn-container">
                <button class="btn-sale" type="button">
                  <img src="@/assets/images/camera.svg" />
                  צילום תעודת זהות
                </button>
              </div>
            </div>
            <div v-if="payType=='bank'">
              <UISelect
                v-model:value="newSale.bankID"
                placeholder="הקלד שם בנק"
                :initialTextProp="newSale.bank"
                :list="banks"
                @keyup="checkValidateBank()" :class="isValidateBank?'':'red-border'"
              >
              </UISelect>
              <input placeholder="סניף" v-model="newSale.branch" @keyup="checkValidateNumBranch()" :class="isValidateNumBranch?'':'red-border'"/>
              <input placeholder="מספר חשבון" v-model="newSale.accountNumber" @keyup="checkValidateAccountNumber()" :class="isValidateAccountNumber?'':'red-border'"/>
              <div class="btn-container">
                <button class="btn-sale" type="button">
                  <img src="@/assets/images/printer.svg" />
                  הדפס הוראת קבע
                </button>
                <button class="btn-sale" type="button">
                  <img src="@/assets/images/camera.svg" />
                  צילום תעודת זהות
                </button>
                <button class="btn-sale" type="button">
                  <img src="@/assets/images/camera.svg" />
                  צילום הוראת קבע
                </button>
              </div>
            </div>
          </div>
        </UICollapsiblePanel>
        <UICollapsiblePanel title="התכתבות" class="agent-details" v-if="!(saleID > 0)">
          <div class="panel">
           <textarea placeholder="הערות" class="input-comment" v-model="newSale.remark"></textarea> 
          </div>
        </UICollapsiblePanel>
        <UICollapsiblePanel title="מסלולים" class="agent-details">
          <div class="container-IVR">
            <img src="@/assets/images/exclamation-mark.svg" />
            <label> לא לשכוח לבצע IVR</label>
          </div>
          <div class="panel" :key="saleDetailsKey">
            <div v-for="(saleDetail, index) in newSale.saleDetails"
              :key="`${componentKey}-${index}`"
            >
              <!--      <DxSelectBox
                id="custom-templates"
                :data-source="companies"
                :rtlEnabled="true"
                v-model:value="saleDetail.companyID"
                class="inputCss"
                display-expr="text"
                value-expr="key"
                placeholder="חברה"
                drop-down-button-template="imageIcon"
                >
              @value-changed="(e) => GetPlansByCompanyName(e, index)"
              
                <template #imageIcon="{}">
                  <img src="@/assets/images/chevron-down.svg" class="custom-icon" />
                </template>
              </DxSelectBox>  -->
              <UISelect
                placeholder="בחירת חברה"
                ref="companiesSelect"
                :list="companies"
                v-model:value="saleDetail.companyID"
                @update:value="RefreshSaleDetails(saleDetail)"
              >
              </UISelect>
              <UISelect
                placeholder="בחירת מסלול"
                :list="getPlansByCompanyID(saleDetail.companyID)"
                v-model:value="saleDetail.planID"
              >
              </UISelect>
              <input placeholder="מספר סים" v-model="saleDetail.simNumber" @keyup="checkValidSimCard(saleDetail)" :class="saleDetail.isValidSimCard?'':'red-border'"/>
              <input placeholder="מספר לניוד" v-model="saleDetail.portNumber" @keyup="checkValidPortNumber(saleDetail)" :class="saleDetail.isValidPortNumber?'':'red-border'"/>
              <div class="container-checkbox">
                <input type="checkbox" id="checkbox" v-model="saleDetail.approvedNyud"/>
                <label for="checkbox">בוצע אישור ניוד</label>
              </div>
              <div class="container-checkbox">
                <input type="checkbox" id="checkbox1" v-model="saleDetail.sendConfirmationNyud"/>
                <label for="checkbox1">שלח אישור ניוד ללקוח </label>
              </div>
              <button
                v-if="newSale.saleDetails.length > 1"
                type="button"
                class="btn-del-sale"
                @click="DeleteCurrentLine(index, $event)"
              >
                <img src="@/assets/images/delete.svg" />
              </button>
            </div>
            <div>
              <div class="btn-container">
                <button @click="addNewLine()" type="button" class="btn-sale">
                  <img src="@/assets/images/add.svg" />
                  הוספת מסלול
                </button>     
              </div>
              <div>
               <div class="canvas-big-cont" v-show="!signed" v-if="sign=='client'">
                  <label>חתימת הלקוח</label>
                <div class="canvas-inner-cont">
                    <vueSignature ref="signature" :sigOption="sOption"></vueSignature>    
                </div>
                <!--div>
                    <button type="button" class="btn-del-sale" @click="clear()">
                      <img src="@/assets/images/delete.svg" />
                    </button>
                </div-->
                <div class="terms" @click="showTerm()">
                  <a  >תנאי התוכנית</a>
                 </div>
              </div> 
               <div>
                  <button type="button" @click="newSignature()" v-if="signed">
                     חתימה חדשה
                  </button>
                </div>
               <img :src="signaturePNG" style="width:90%;height:75%" v-if="signed">

               <div class="container-radios">
                 <div>
                  <label class="container-radio">
                    <input
                      type="radio"
                      name="radio"
                      value="client"
                      v-model="sign"  
                    />
                    <span class="checkmark"></span>
                    <label>הלקוח מאשר שקרא את תנאי התוכנית</label>
                  </label>
                 </div>
                 <div>
                  <label class="container-radio">
                    <input
                      type="radio"
                      name="radio"
                      value="representative"
                      v-model="sign" 
                  />               
                  <span class="checkmark"></span>
                  <label >הלקוח לא לידי ואני לוקח אחריות מלאה</label>
                </label>
                 </div>
              </div>
              </div>
            <DxPopup
              :key="popupKey"
              v-model:visible="popupVisible"
              :drag-enabled="false"
              :close-on-outside-click="true"
              :show-close-button="false"
              :show-title="true"
              width="50vw"
              :height="auto"
              :rtl-enabled="true"
              container=".dx-viewport"
              title="תנאי תוכנית"
              >
              <div v-for="(saleDetail, index) in newSale.saleDetails" :key="index">
                 <!--img :src="mediaURL + getLogoPath(saleDetail.companyID)"  class="logo-company-header"/-->
                 <p class="inp-term">
                  חברה:
                  {{ getCompanyName(saleDetail.companyID) }}
                 </p>
               <p class="inp-term">
                 שם תוכנית: 
                   {{ getPlanName(saleDetail.planID) }} {{getPlanPrice(saleDetail.planID)}} ש"ח
               </p>
              </div>
            </DxPopup>
            <DxPopup
              v-model:visible="invalidForm"
              :drag-enabled="false"
              :close-on-outside-click="true"
              :show-close-button="false"
              :show-title="true"
              :rtl-enabled="true"
              title="פרטים חסרים:"
              :key="popupKey"
              width="30vw"
              :height="auto"
              >
              <label> שים לב ישנה שגיאה בשדות הבאים:</label>
               <div v-if="!isValidId || newSale.personalID==''">
                 <span>מספר ת.ז</span>
              </div>
               <div v-if="!isValidfirstName || newSale.firstName==''">
                 <span>שם פרטי</span>
              </div>
              <div v-if="!isValidlastName || newSale.lastName==''">
                <label>שם משפחה</label>
              </div>
              <div v-if="!isValidateNumStreet || newSale.numStreet==''">
                 <span> מספר בית </span>
              </div>
              <div v-if="!isValidMobilePhone || newSale.mobile==''">
                 <span> מספר פלאפון </span>
              </div>
              <div v-if="!isValidEmail">
                <label> אמייל</label>
              </div> 
              <div v-if="payType=='cc'"> 
                <div v-if="!isValidCard || newSale.cC_INFO==null">
                   <span>מספר כרטיס האשראי</span>
                </div>
                <div v-if="!isValidCVV || newSale.cvv==''">
                   <span>3 ספרות בגב כרטיס האשראי</span>
                </div>
                 <div v-if="newSale.expirationYear==''">
                   <span>שנה שגויה בתוקף כרטיס אשראי</span>
                </div>
                 <div v-if="newSale.expirationMonth==''">
                   <span>חודש שגוי בתוקף כרטיס אשראי</span>
                </div>
              </div>
              <div  v-if="payType=='bank'">
                <div v-if="!isValidateBank || newSale.bankID==null">
                  <span>שם בנק</span>
                </div>
                <div v-if="!isValidateNumBranch || newSale.branch==''">
                  <span>מספר סניף</span>
                </div>
                <div v-if="!isValidateAccountNumber || newSale.accountNumber==''">
                  <span>מספר חשבון</span>
                </div>
              </div>
               <div v-for="(saleDetail, index) in newSale.saleDetails" :key="`${componentKey}-${index}`">
               <label v-if="newSale.saleDetails.length>1 && saleDetail.companyID">תוכנית מספר {{index + 1}}</label>
                <div v-for="(errorMessage,mIndex) in saleDetail.validationMessages" 
                :key="`${componentKey}-${index}-${mIndex}`">
                  <span>{{errorMessage}}</span>
                </div>
               </div>
              <label> האם ברצונך להמשיך בכל זאת?</label>
              <div class="btn-popup">
                <button type="button" @click="closePopup()"> סגור</button>
                <button type="button" @click="send()"> שלח</button>
             </div>
            </DxPopup>
            <DxPopup
                :key="popupKey"
                v-model:visible="visiblePopup"
                :drag-enabled="false"
                :close-on-outside-click="true"
                :show-close-button="false"
                :show-title="false"
                width="30vw"
                :height="auto"
                :rtl-enabled="true"
                container=".dx-viewport"
              >
              <label> הלקוח קיים במערכת האם ברצונך למשוך את פרטיו?</label>
              <div class="btn-popup">
               <button type="button" @click="pullDetails()"> משוך</button>
              <button type="button" @click="closePopup()"> סגור</button>
             </div>
            </DxPopup>
            </div>
            </div>
        </UICollapsiblePanel>
        <div class="btn-buttom">
          <button v-if="saleID > 0" class="send" type="button" @click="sendToServer(false)">עדכן</button>
          <button v-if="!(saleID > 0)" class="save" type="button" @click="sendNewSale(1)">שמור ללא שליחה</button>
          <button v-if="!(saleID > 0)" class="send" type="button" @click="sendNewSale(2)">שלח איקטוב</button>
          <button v-if="!(saleID > 0)"  class="octave" type="button" @click="sendNewSale(3)">אוקטב עצמאית</button>
        </div>
        <button  type="button" v-if="saleID > 0" @click="openChat()">chat</button>
        <UIChat v-if="saleID > 0 && showChat" :saleID="saleID"></UIChat>
        {{popupChat}}
      </div>
    </form>
    </div>
  </div>
</template>

<script>
import { Validations } from "../code/validations";
import axios from "axios";
import UICollapsiblePanel from "./UIComponents/UICollapsiblePanel.vue";
import UISelect from "./UIComponents/UISelect.vue";
import UIChat from "./UIComponents/UIChat.vue";
import { DxAutocomplete } from 'devextreme-vue/autocomplete';
import { DxLookup, DxDropDownOptions } from "devextreme-vue/lookup";
import { DxPopup} from 'devextreme-vue/popup';
import { createStore } from "devextreme-aspnet-data-nojquery"; 

import vueSignature from "vue-signature" 

const url = process.env.VUE_APP_BASE_URL + "/Sale";
const urlCities = process.env.VUE_APP_BASE_URL + "/AutocompleteController";
 

export default {
  title: "מכירה חדשה",
  components: { UICollapsiblePanel ,UIChat, DxLookup, DxDropDownOptions, UISelect,DxAutocomplete,DxPopup,vueSignature },

  name: "NewSale",
  props: {
    msg: String,
  },
  data() {
    return {
      newSale: {
        userID: "",
        personalID: "",
        firstName: "",
        lastName: "",
        cityID: 0,
        city: "",
        streetID: 0,
        street: "",
        numStreet: "",
        mobile: "",
        email: "",
        lastFourDigits: "",
        cC_INFO: "",
        cvv: "",
        expirationYear: "",
        expirationMonth: "",
        bankID: null,
        bank: "",
        branch: null,
        accountNumber: "",
        signatureSVG:"",
        signaturePNG:"",
        responsibilityByUser:false,
        remark:"",
        saleDetails: [
          {
            companyID: null,
            planID:null,
            simNumber: "",
            portNumber: "",
            saleDetailID: 0,
            plans: [],
            isValidSimCard:true,
            isValidPortNumber:true,
            plan:{},
            saleDetailStatus:0,
            approvedNyud:false,
            sendConfirmationNyud:false,
          },
        ],
      },
      plan: "",
      cityTemp: "",
      streetTemp: "",
      allPlans: [],
      payType:'cc',
      sign:'client',
      cities: [],
      streets:[],
      companies: [],
      banks: [],
      dateYear: new Date(),
      years: [],
      currentUserInfo: { fullName: "" },
      componentKey: 0,
      messageKey:0,
      index: 0,
      saleDetailsKey: 0,
      employeesList: [],
      // errorOnCity: false,
      saleID: 0,
      planID: 0,
      Validations,
      isValidCity:true,
      isValidStreet:true,
      isValidEmail:true,
      isValidId:true,
      isValidCard:true,
      isValidCVV:true,
      isValidMobilePhone:true,
      isValidfirstName:true,
      isValidlastName:true,
      isValidateNumStreet:true,
      isValidateNumBranch:true,
      isValidateAccountNumber:true,
      isValidateBank:true,
      invalidForm:false,
      mediaURL:process.env.VUE_APP_BASE_MEDIA_URL,
      sOption:{
				penColor:"rgb(0, 0, 0)",
				backgroundColor:"rgb(255,255,255)",
        onEnd: this.onEndSign 
			},
      signed:false,
      signEndHandler:0,
      popupVisible:false,
      visiblePopup:false,
      showChat:true,

    };
  },
  mounted() {
    this.index++;
    if(this.$route.query.saleID>0)
      this.saleID = this.$route.query.saleID;
     console.log( this.saleID);
    this.getAllPlans();
    this.getAllCities();
    this.getAllStreets();
    console.log("getAllCities");
    this.planID = this.$route.query.planID; 
    this.getAllCompanies();
    this.getAllBanks();
    if (this.saleID > 0) {
      this.getSale();
      this.getMessagesBySale();
      console.log("get sale");
    }
    this.getEmployeeList();
    let year = new Date().getYear() + 1900;
    for (var i = 0; i < 8; i++) {
      this.years.push({ key: String(year + i), text: String(year + i) });
    }
    this.currentUserInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.newSale.userID = this.currentUserInfo.userID;
    //this.$refs.userSelect.initialText=this.currentUserInfo.fullName;
  },
  methods: {
    onEndSign(){

      if (this.signEndHandler>0)
        clearTimeout(this.signEndHandler);
      var myThis=this;

      this.signEndHandler=setTimeout(function () {
        myThis.newSale.signatureSVG= myThis.$refs.signature.save("image/svg+xml");
        console.log( myThis.newSale.signatureSVG);
        myThis.newSale.signaturePNG= myThis.$refs.signature.save();
        myThis.signatureSVG=myThis.newSale.signatureSVG;
        myThis.signaturePNG=myThis.newSale.signaturePNG;
        myThis.refreshImageKey++;
        console.log( myThis.newSale.signaturePNG);
        if(myThis.newSale.signaturePNG)
          {
          myThis.signed=true;
          }
        else
          {
          myThis.signed=false;
          myThis.sign="representative";
          }
      },1000);
         // this.index++;
    },
    forceRerender() {
      this.componentKey++;
    },
    RefreshSaleDetails() {
      this.saleDetailsKey++;
    },
    getAllBanks() {
      axios
        .get(process.env.VUE_APP_BASE_URL + "/Bank/GetAllBanks")
        .then((res) => {
          this.banks = res.data.map(function (b) {
            return { text: b.name, key: b.bankID };
          });
        });
    },
    getAllCities() {
      axios
        .get(process.env.VUE_APP_BASE_URL + "/City/GetAllCities")
        .then((res) => {
          this.cities =res.data.map(function (c) {
            return { text: c.name, key:c.cityID };
          });
          console.log(res.data);
        });
    },
     getAllStreets() {
      axios
        .get(process.env.VUE_APP_BASE_URL + "/Street/GetAllStreets")
        .then((res) => {
          this.streets =res.data.map(function (s) {
            return { text: s.name, key:s.streetID };
          });
          console.log(res.data);
        });
    },
    getAllCompanies() {
      axios
        .get(process.env.VUE_APP_BASE_URL + "/Company/GetAllCompanies")
        .then((res) => {
          this.companies = res.data.map(function (c) {
            return { text: c.name, key:c.companyID };
          });
          this.componentKey++;
        });
    },
    getAllPlans() {
      axios
        .get(process.env.VUE_APP_BASE_URL + "/Plan/GetAllPlans")
        .then((res) => {
          this.allPlans = res.data;
          console.log("allPlans");
          console.log(this.allPlans);
          if (this.planID > 0 && this.newSale.saleID!==null)
           {
            this.setPlan();
            console.log("get plan");
            }
        });
    },
    getEmployeeList() {
      axios
        .get(process.env.VUE_APP_BASE_URL + "/Sale/UsersUnderCurrent")
        .then((res) => {
          console.log(res);
          this.employeesList = res.data;
          this.componentKey++;
        });
    },
    addNewLine() {
      this.newSale.saleDetails.push({
        companyID: null,
        planID: null,
        simNumber: "",
        portNumber: "",
        saleDetailID: 0,
        isValidSimCard:true,
        isValidPortNumber:true
      });
    },
    DeleteCurrentLine: function (index) {
      this.newSale.saleDetails.splice(index, 1);
      this.forceRerender();
    },
    addNewMessage(){
       this.newSale.messages.push({
        messageText: "",
        createdByUserID:0
      });
    },
    DeleteMessage(index){
      this.newSale.messages.splice(index, 1);
    },
    sendNewSale(number) {
      // if (!this.$refs.formElement.checkValidity())
      //   return;
      //   e.preventDefault();
      // if (!this.newSale.cityID || this.newSale.cityID == 0) {
      //   this.errorOnCity = true;
      //   return;
      // }
      var text="חובה למלא את השדות הבאים:"+"\n";
      var problem=false;
      if(!this.newSale.personalID)
      {
        problem=true;
        text+="ת.ז"+"\n";    
      }
      if(this.sign=="client" && this.$refs.signature.isEmpty() && !this.newSale.signaturePNG)
      {
        problem=true;
        text+="חובה להחתים לקוח או לקחת אחריות על המכירה!"+"\n"
      }
      else
        if(this.sign=="representative")
           this.newSale.responsibilityByUser= true;  
      if(this.newSale.saleDetails.length>0)
       {
        this.newSale.saleDetails.forEach(function (x) {
          if (x.companyID == null) 
            {
              problem=true;
              text+="חובה לבחור חברה"+"\n";
            }
          else if (x.planID == null) 
            {
              problem=true;
              text+="חובה לבחור תוכנית"+"\n";
            } 
         });
        }
      if(problem==true)
      {
        alert(text);
      }
      if(!problem){
      if(this.payType == 'cc')
        if(!this.isValidCard)
           this.newSale.cC_INFO = "";
        else
        {
          if ((this.newSale.cC_INFO ?? '').length>=4){
            this.newSale.lastFourDigits = this.newSale.cC_INFO.substring(this.newSale.cC_INFO.length - 4);
          }
          this.newSale.branch = "";
          this.newSale.accountNumber = "";
          this.newSale.bankID = null;
          this.newSale.bank = "";
          this.isValidateNumBranch=true;
          this.isValidateAccountNumber=true;
          this.isValidateBank=true;
        }
      else
        {
          this.newSale.cC_INFO = ""
          this.newSale.cvv = ""
          this.newSale.expirationYear= "";
          this.newSale.expirationMonth = "";
          this.isValidCard=true;
          this.isValidCVV=true;
        } 
        if(this.sign=="client" && this.newSale.signaturePNG)
      {
        let newThis=this;
        if(!this.isValidEmail || !this.isValidId || !this.isValidCard || !this.isValidateAccountNumber || !this.isValidCVV || !this.isValidMobilePhone || !this.isValidfirstName ||
        !this.isValidlastName || !this.isValidateNumStreet || !this.isValidateNumBranch)
      // this.newSale.expirationYear=="" || this.newSale.expirationMonth==""
        {
         this.invalidForm=true;
        }
        
      if(this.newSale.saleDetails.length>0)
      {
        this.newSale.saleDetails.forEach(function (x) {
          x.validationMessages=[];
          // if ((x.companyID ?? 0) === 0) 
          //   x.validationMessages.push("לא נבחרה חברה");
          // else
          //   if ((x.planID ?? 0 ) === 0)
          //     x.validationMessages.push("לא נבחרה תוכנית") 
            if (newThis.newSale.saleDetails.findIndex(y=>x.simNumber===y.simNumber && x!=y)>=0)
              x.validationMessages.push("ישנן כפילויות במספרי סים") 
            if (newThis.newSale.saleDetails.findIndex(y=>x.portNumber===y.portNumber && x!=y)>=0)
              x.validationMessages.push("מספר לניוד כפול");
            if  (!x.isValidSimCard || x.simNumber=='')
             x.validationMessages.push("מספר סים לא תקין");
             if  (!x.isValidPortNumber || x.portNumber=='')
             x.validationMessages.push("מספר לניוד לא תקין");
       
            newThis.invalidForm = newThis.invalidForm || x.validationMessages.length>0;
            console.log('sd validations');
            console.log(x);
         });
        }
          if (this.invalidForm )
          {
              this.popupKey++;
          }
         }
          else 
           this.sendToServer(false);
           if(number==1)
            this.newSale.saleDetails.forEach((sd) => {
            sd.saleDetailStatus = 302;
          });
           else if(number==2)
            this.newSale.saleDetails.forEach((sd) => {
            sd.saleDetailStatus = 200;
          });
          else if(number==3)
            this.newSale.saleDetails.forEach((sd) => {
            sd.saleDetailStatus = 104;
          });        
      } 
              
    },
    send(){
      this.sendToServer(true);
    },
    sendToServer(isValidaition){
    if (!this.saleID > 0) {
       axios.post(process.env.VUE_APP_BASE_URL + `/Sale/PostSale?noValidation=${isValidaition}`,this.newSale)
            .then((res) => {
            this.$router.push({ path: "/app/hello" });
            console.log(res.data);
          })
          .catch(() => {
            console.log("לא נשלח");
          });
      } else {
        axios
          .put(process.env.VUE_APP_BASE_URL + "/Sale/PutSale", this.newSale)
          .then((res) => {
            console.log(res.data);
            this.$router.push({ path: "/app/hello" });
            console.log(res.data);
          })
          .catch(() => {
            console.log("לא נשלח");
          });
      }
    },
    isSelectedStreet() {
      console.log("changed!!");
      this.newSale.streetID = null;
    },
    getPlansByCompanyID(companyID) {
      console.log("getPlansByCompanyID");
      return this.allPlans
        .filter((x) => x.companyID == companyID)
        .map(function (p) {
          return { key: p.planID, text: p.name };
        });
     
    },
    setPlan(){
      var plan= this.allPlans.find(x=>x.planID==this.planID);
      this.newSale.saleDetails[0].companyID=plan.companyID;
      this.newSale.saleDetails[0].planID  =plan.planID;
      this.saleDetailsKey++;

     },
     getCitySource(){
       console.log("citySource");
        return createStore({ 
          loadUrl: `${urlCities}/LoadData`,
          onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.headers =     { "Authorization" : localStorage.getItem('jwtToken') };
          },
        }); 
     },
    closePopup(){
       this.visiblePopup=false;
       this.invalidForm=false;
    },
    checkCity(){
       axios.get(process.env.VUE_APP_BASE_URL + "/City/Check?Name=" + encodeURI(this.newSale.city))
            .then((res) => {
                if (res.data==true) 
                  this.isValidCity=true; 
                else
                  this.isValidCity=false;
          })
          .catch(() => {
            console.log("לא נשלח");
          });
    },
    checkStreet(){
      axios.get(process.env.VUE_APP_BASE_URL + "/Street/Check?Name=" + encodeURI(this.newSale.street) +"&CityName=" + encodeURI(this.newSale.city))
            .then((res) => {
                if (res.data==true) 
                  this.isValidCity=true; 
                else
                  this.isValidCity=false;
          })
          .catch(() => {
            console.log("לא נשלח");
          });
    },
    pullDetails(){
      this.visiblePopup=false;
      axios
      .get(process.env.VUE_APP_BASE_URL + "/Customer/Find/" + this.newSale.personalID)
      .then((res) => {
        console.log( res.data);
        this.newSale.firstName=res.data.firstName;
        this.newSale.lastName=res.data.lastName;
        this.newSale.numStreet=res.data.numStreet;
        this.newSale.mobile=res.data.mobile;
        this.newSale.email=res.data.email;
        this.newSale.city = res.data.city.name;
        this.newSale.street = res.data.street.name;
        this.newSale.cC_INFO = res.data.paymentMethods.lastFourDigits;
          if (!res.data.paymentMethods.lastFourDigits)
          {
            this.payType = "bank";
            this.newSale.bankID = res.data.paymentMethods[0].bankID;
            this.newSale.branch = res.data.paymentMethods[0].branch;
            this.newSale.accountNumber = res.data.paymentMethods[0].accountNumber;
          }    
        });
    },
    checkIsValidIdׁׂ(){
      this.isValidId=this.newSale.personalID=='' || this.Validations.is_valid_israel_id(this.newSale.personalID);
    },
    isUserExist(){
       axios
      .get(process.env.VUE_APP_BASE_URL + "/Customer/Find/" + this.newSale.personalID)
      .then((res) => { 
        if(res.data.personalID)
           this.visiblePopup=true;
       });
    },
    checkIsValidFirstNameׁׂ(){
      this.isValidfirstName=this.newSale.firstName=='' || this.Validations.is_valid_text_hebrew(this.newSale.firstName);
    },
    checkIsValidLastNameׁׁׂׂ(){
      this.isValidlastName=this.newSale.lastName=='' || this.Validations.is_valid_text_hebrew(this.newSale.lastName);
    },
    checkValidEmail(){
      this.isValidEmail=this.newSale.email=='' || this.Validations.validateEmail(this.newSale.email);
    },
    checkValidCard(){
      this.isValidCard=this.newSale.cC_INFO==null || this.Validations.is_valid_card_genery(this.newSale.cC_INFO);
    },
    checkValidCVV(){
      this.isValidCVV=this.newSale.cvv=='' || this.Validations.is_valid_cvv(this.newSale.cvv);
    },
    checkValidMobilePhone(){
      this.isValidMobilePhone=this.newSale.mobile=='' || this.Validations.is_valid_mobile_phone(this.newSale.mobile);
    },
    checkValidateNumStreet(){
      this.isValidateNumStreet=this.newSale.numStreet=='' || this.Validations.validateNumber(this.newSale.numStreet);
    },
    checkValidateBank(){
      this.isValidateBank=this.newSale.bankID==null;
    },
    checkValidateNumBranch(){
      this.isValidateNumBranch=this.newSale.branch=='' || this.Validations.validateNumber(this.newSale.branch);
    },
    checkValidateAccountNumber(){
      this.isValidateAccountNumber=this.newSale.accountNumber=='' || this.Validations.validateNumber(this.newSale.accountNumber);
    },
    checkValidSimCard(saleDetail){
      let companyName=this.getCompanyName(saleDetail.companyID);
      saleDetail.isValidSimCard=saleDetail.simNumber=='' || this.Validations.is_valid_sim_card(saleDetail.simNumber,companyName);
    },
     checkValidPortNumber(saleDetail){
      saleDetail.isValidPortNumber=saleDetail.portNumber=='' || this.Validations.is_valid_port_number(saleDetail.portNumber);
    },
    showTerm(){
      this.popupVisible=true; 
    },
    getCompanyName(companyID) {
      if (this.companies.length==0 || companyID ==null || companyID == 0)
        return "";
      return this.companies.find((x) => x.key == companyID).text;
    },
    getPlanName(planID) {
      if (this.allPlans.length==0 || planID ===null || planID == 0)
        return "";
      return this.allPlans.find((x) => x.planID == planID).name;
    },
     getPlanPrice(planID) {
      if (this.allPlans.length==0 || planID ===null || planID == 0)
        return "";
      return this.allPlans.find((x) => x.planID == planID).price;
    },
    getSale() {
      axios
        .get(process.env.VUE_APP_BASE_URL + "/Sale/GetSale/" + this.saleID)
        .then((res) => {
          this.newSale = res.data;
          this.newSale.cC_INFO = this.newSale.lastFourDigits;
          if (this.newSale.lastFourDigits<=3) 
              this.payType = "bank";
          if(this.newSale.signaturePNG)
          {
            this.signed=true;
          }
            this.newSale.saleDetails.forEach((sd) => {
            sd.companyID = this.allPlans.find(
              (x) => x.planID == sd.planID
            ).companyID;
          });
          this.index++;
        });
    },
    newSignature(){
      this.signed=false;
      this.sign='client';
      this.$refs.signature.clear();
    },
    // clear(){
		// 	this.$refs.signature.clear();
		// },
    getMessagesBySale(){
      axios
      .get(process.env.VUE_APP_BASE_URL + "/Message/GetMessagesBySaleID/" + this.saleID)
      .then((res) => { 
        this.newSale.messages=res.data;
       });
    },
    openChat(){
      this.showChat=!this.showChat;
    }
    //  showPopup(){
    //     this.popupChat=true;
    //     this.popupKey++;
    //   }
  },
};
</script>

<style lang="scss" scoped></style>
