<template>
  <div :key="componentKey">
    <div class="company-title">
    <!-- <div class="container-status-company"> -->
        <img   :src="mediaURL + company.logoPath"  class="logo-company-header" v-if="companyID>0"/> 
        <img   src="@/assets/images/logo-menu.svg" class="logo-ideal-header" v-if="!companyID"/>  
         <!-- <span v-if="status">
            <label>סטטוס: </label>
            <label>{{ this.statusName }}</label>
           <button @click="allStatuses()"> לכל הסטטוסים</button>
         </span> -->
    <!-- </div> -->
        <span>
            <img  v-if="companyID>0" src="@/assets/images/globe.svg" class="img-globe">
            <label v-if="companyID>0">{{ company.name }} </label>
            <label v-if="!companyID">כל החברות</label>
        </span>
        <span @click="backToCompanies()">
            <img src="@/assets/images/arrow-black.svg" class="arrow-black">
           <label class="lable-back">חזרה לכל החברות</label>  
        </span>   
    </div>
      <div @click="getCurrentFilter()" class="exportButton">
          <div>ייצוא לאקסל</div>  
          <img src="@/assets/images/exel.svg" />
       </div>
      <div class="container-date-data" :key="monthPagerKey">
        <div :class="!(createdAtFirstSale.getMonth()+1 < currentMonth || createdAtFirstSale.getFullYear() < currentYear) ? 'container-current-month':''">
          <span @click="getDataLastMonth()" 
          v-if="(createdAtFirstSale.getMonth()+1 < currentMonth || createdAtFirstSale.getFullYear() < currentYear) " 
          >
          <img src="@/assets/images/chevron-right.svg" >חודש קודם
          </span>
        </div>
        <div>
          <span> {{currentMonth}}/{{currentYear}}</span>
        </div>
        <div :class="(currentDate.getMonth()+1 == currentMonth && currentDate.getFullYear() == currentYear)  ? 'container-current-month':''">
        <span @click="getDataNextMonth()" v-if="!(currentDate.getMonth()+1 == currentMonth && currentDate.getFullYear() == currentYear) " >חודש הבא
          <img src="@/assets/images/chevron-right.svg">
        </span>
        </div>
       </div>
   <DxTreeList
      id="tree-list" 
      :data-source="dataSource"
      :show-row-lines="true"
      :show-borders="true"
      :column-auto-width="true"
      :word-wrap-enabled="true"
      key-expr="treeKey"
      parent-id-expr="parentTreeKey"
      has-items-expr="hasItems"
      :rtl-enabled="true" 
      :column-hiding-enabled="true"
      class="tree-list"
      ref="tList"
      height="100vh"
      
    > 
      <DxFilterRow :visible="true" />
      <DxSearchPanel :visible="true" />
      <DxHeaderFilter :visible="true"/>
      
     
      <DxColumn
        width="160px"
        data-field="userName"
        caption="שם נציג" 
        :hiding-priority="8"
        alignment="right"
        cell-template="userName"
      > 
      </DxColumn>
      <!-- <DxColumn
        :min-width="120"  
        data-field="totalLines"       
      >  
      </DxColumn> -->
    <DxColumn
     :hiding-priority="3"
     width="105px"
     data-field="nameCustomer" 
     caption="שם לקוח"
     cell-template="nameCustomer"
    >
    </DxColumn>
    <DxColumn
      :hiding-priority="2"
       width="130px"
      data-field="personalID" 
      caption="תעודת זהות"
      cell-template="personalID"
    > 
    </DxColumn>
      <DxColumn
        :hiding-priority="5"
        width="105px"
        data-field="companyID" 
        caption="חברה"
        :filterValue="companyID"
      > 
        <DxLookup :data-source="companyDataSource" value-expr="companyID" display-expr="name"  searchPlaceholder="חפש"/>
      </DxColumn>
        <DxColumn data-field="saleDetailStatus" caption="סטטוס" :filterValue="status" width="105px"  :hiding-priority="7">
        <DxLookup :data-source="SaleDetailStatusMainEnum" value-expr="value" display-expr="text" searchPlaceholder="חפש"/>
      </DxColumn>   
       <DxColumn
       :hiding-priority="4"
        data-field="planID" 
        caption="תוכנית"
         width="105px"
      >
        <DxLookup :data-source="planDataSource" value-expr="planID" display-expr="name"  searchPlaceholder="חפש"/>
       </DxColumn>
        <DxColumn
        :hiding-priority="6"
         width="105px"
        data-field="totalLines" 
        caption="מספר קוים"
        headerFilter=""
        cell-template="totalLines"
      > </DxColumn>
        <DxColumn
        :hiding-priority="0"
        max-width="120px"
        data-field="createdAt"
        caption="תאריך שליחה"
        data-type="datetime"
        format="HH:mm dd-MM-yyyy"
         cell-template="createdAt"
    
      > 
      </DxColumn> 
       <DxColumn
        :hiding-priority="1"
        max-width="120px"
        data-field="lastUpdatedAt"
        caption="תאריך עדכון"
        data-type="datetime"
        format="HH:mm dd-MM-yyyy"
          cell-template="lastUpdatedAt"
    
      > 
       </DxColumn>
       <DxColumn :width="60" cell-template="edit" caption="ערוך" :allowFiltering="false" type="buttons">           
       </DxColumn>
       <template #edit="{data}">
          <img src="@/assets/images/edit.svg" class="custom-icon" @click="getSaleDetailes(data)"  v-if="data.key.substring(0,1)=='S'" />
      </template>
       <DxColumn :width="60" cell-template="delete"  caption="מחק" :allowFiltering="false" type="buttons">   
       </DxColumn>
       <template #delete="{data}">
           <img src="@/assets/images/delete-row.svg" class="custom-icon" @click="deleteSale(data)" v-if="data.key.substring(0,1)=='S'" />
      </template> 
      <template #nameCustomer="{data}">
        <span v-if="data.key.substring(0,1)=='S'">{{data.data.nameCustomer}}</span>
      </template>
      <template #createdAt="{data}" type="time">
        <span v-if="data.key.substring(0,1)!='U'">{{data.text}}</span>
      </template>  
      <template #lastUpdatedAt="{data}">
        <span v-if="data.key.substring(0,1)!='U'">{{data.text}}</span>
      </template>
      <template #personalID="{data}">
        <span v-if="data.key.substring(0,1)=='S'">{{data.data.personalID}}</span>
      </template>  
      <template #totalLines="{data}">
        <span v-if="data.key.substring(0,1)=='S' || data.key.substring(0,1)=='U'">{{data.data.totalLines}}</span>
      </template> 
       <template #userName="{data}">
        <span v-if="data.key.substring(0,1)=='S'|| data.key.substring(0,1)=='U'">{{data.data.userName}}</span>
      </template> 
    </DxTreeList>
  </div>
</template>

<script>
import axios from "axios";
import {DxLookup,DxFilterRow} from "devextreme-vue/data-grid";
import { DxTreeList, DxHeaderFilter,DxSearchPanel,DxColumn,DxRemoteOperations } from "devextreme-vue/tree-list";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { EnumManager,SaleDetailStatusMainEnum } from "../code/enums";



const url = process.env.VUE_APP_BASE_URL + "/Sale";
 

const urlPlans = process.env.VUE_APP_BASE_URL + "/Plan";
const planDataSource = createStore({
  key: "planID",
  loadUrl: `${urlPlans}/GetAllPlans`,
  onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =     { "Authorization" : localStorage.getItem('jwtToken') };
  },
});

const urlCompanies = process.env.VUE_APP_BASE_URL + "/Company";
const companyDataSource = createStore({
  key: "companyID",
  loadUrl: `${urlCompanies}/GetAllCompanies`,
  onBeforeSend: (method, ajaxOptions) => {
       ajaxOptions.headers =     { "Authorization" : localStorage.getItem('jwtToken') };
 },
});
export default {
  name: "SaleDetails",
  props: {
    msg: String,
  },
  components: {
    DxTreeList, 
    DxColumn,
    DxHeaderFilter,
    DxSearchPanel, 
    DxLookup,
    DxFilterRow
  },
  data() {
    return {
      EnumManager,
      userID:'', 
      currentUserInfo: { userID: ""}, 
      managerLevel:4,
      componentKey:0,
      planDataSource,
      companyDataSource,
      SaleDetailStatusMainEnum,
      mediaURL:process.env.VUE_APP_BASE_MEDIA_URL ,
      company: {},
      companyID:null,
      status:0,
      saleID:1056,
      firstDate: new Date(new Date().getFullYear(), new Date().getMonth(),1),
      lastDate:new Date(new Date().getFullYear(), new Date().getMonth()+1,1),
      dataSource:this.getDataSource(new Date(new Date().getFullYear(),new Date().getMonth(), 1,12,0),new Date(new Date().getFullYear(),new Date().getMonth()+1,1,12,0)),
      statusName:'',
      currentDate:new Date(),
      currentMonth:(new Date().getUTCMonth()+1),
      currentYear:(new Date().getUTCFullYear()),
      createdAtFirstSale:new Date(2000,0,1),
      monthPagerKey:0
    };
  },
  mounted() {
    console.log(this.currentYear);
    console.log(this.currentMonth);
    this.companyID = this.$route.query.companyID;
    this.status = this.$route.query.status;
    if (this.status !== undefined){
       this.statusName = EnumManager.GetText(SaleDetailStatusMainEnum, this.status);
    }
    console.log(this.status);
    this.getCompanyByID();
    this.currentUserInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.userID = this.currentUserInfo.userID;
    this.getSaleCreatedAtFirst()
    console.log(this.createdAtFirstSale.getMonth()+1);
  },
  methods: {
        log(data){
          console.log(data);
          return "";
        },
      getDataSource(first,last){
          var urlCompany='';
          if (this.$route.query.companyID>0)
              urlCompany='companyID=' + this.$route.query.companyID;
          var urlStatus='';
          if (this.$route.query.status>0)
              urlStatus='saleDetailStatus=' + this.$route.query.status;
          let firstDate=first;
          let lastDate= last;
          return createStore({
              key: "treeKey",
              loadUrl: `${url}/GetSaleDetailsGrid?${urlCompany}&firstDate=${firstDate.toISOString().slice(0, 10)}&lastDate=${lastDate.toISOString().slice(0, 10)}&${urlStatus}`,
              onBeforeSend: (method, ajaxOptions) => {
                  ajaxOptions.headers =     { "Authorization" : localStorage.getItem('jwtToken') };
              },
            });
      },
       getCurrentFilter(){
       console.log("getCurrentFilter");
       var treeList =this.$refs.tList.instance;
       console.log(treeList);
       var currentFilter = treeList.getCombinedFilter();
       console.log(currentFilter);
       var ReplacedFilter = this.RecurseFilter(currentFilter, treeList);
       var jsonFilter = JSON.stringify(ReplacedFilter);
       console.log("jsonFilter" + jsonFilter);
       var urlCompany='';
          if (this.$route.query.companyID>0)
              urlCompany='companyID=' + this.$route.query.companyID;
       var urlStatus='';
       if (this.$route.query.status>0)
           urlStatus='saleDetailStatus=' + this.$route.query.status;
        axios
         .get(process.env.VUE_APP_BASE_URL + `/Sale/ExportSaleDetailsGrid?${urlCompany}&firstDate=${this.firstDate.toISOString().slice(0, 10)}&lastDate=${this.lastDate.toISOString().slice(0, 10)}&${urlStatus}&filter=${jsonFilter}`,{responseType:'blob'})
         .then((res) => {
             const url = window.URL.createObjectURL(new Blob([res.data]));
             const link = document.createElement('a');
             link.href = url;
             link.setAttribute('download', 'מכירות.xlsx');
             document.body.appendChild(link);
             link.click();
         });
     },
      RecurseFilter(filterArray, treeList) {
         if (Array.isArray(filterArray)) {
             var result = new Array();
             for (var i = 0; i < filterArray.length; i++) {
                 if (filterArray[i].columnIndex != null && !Array.isArray(filterArray[i]))
                     result.push(treeList.getVisibleColumns()[filterArray[i].columnIndex].dataField);
                 else
                     result.push(this.RecurseFilter(filterArray[i], treeList));
             }
             return result;
         }
         else
             return filterArray;
     },
      getCompanyByID(){
         axios.get(process.env.VUE_APP_BASE_URL + "/Company/GetCompany/" + this.companyID)
          .then(res => {
            this.company = res.data;  
             console.log(res.data);
         });
     },
      backToCompanies(){
         this.$router.push({ path: "/app/MySales"});  
         },
      allStatuses(){
      
     
      },
      getSaleDetailes(data){
       let key = data.key;
       let saleID = +(key.substring(1)); 
       this.$router.push({ path: "/app/sendIktov",
                           query: { saleID :saleID } });
                           console.log(saleID); 
       
      },
        deleteSale(data){
        let key = data.key;
        let saleID = +(key.substring(1));
         axios.delete(process.env.VUE_APP_BASE_URL + `/Sale/DeleteSale/${saleID}`)
          .then((res)=>{
        
            console.log( res.data);

               })
             .catch(
              (e)=>{   
            
             });
              this.componentKey++;
      },
      getDataLastMonth(){
        console.log("prev month");
        this.firstDate= new Date(this.firstDate.getFullYear(),this.firstDate.getMonth()-1, 1,12,0);
        this.lastDate= new Date(this.firstDate.getFullYear(),this.firstDate.getMonth()+1, 1,12,0);
        this.currentMonth=( this.firstDate.getUTCMonth()+1);
        this.currentYear=(this.firstDate.getUTCFullYear());
        this.monthPagerKey++;
        this.dataSource=this.getDataSource(this.firstDate,this.lastDate);
      },
      getDataNextMonth(){
        this.firstDate= new Date(this.firstDate.getFullYear(),this.firstDate.getMonth()+1, 1,12,0);
        this.lastDate= new Date(this.firstDate.getFullYear(),this.firstDate.getMonth()+1, 1,12,0);
        this.currentMonth=(this.firstDate.getUTCMonth()+1);
        this.currentYear=(this.firstDate.getUTCFullYear());
        this.monthPagerKey++;
        this.dataSource=this.getDataSource(this.firstDate,this.lastDate);
      },
      getSaleCreatedAtFirst(){
         axios.get(process.env.VUE_APP_BASE_URL + "/sale/getSaleCreatedAtFirst/")
          .then(res => {
            this.createdAtFirstSale = new Date(res.data);  
            console.log(this.createdAtFirstSale);
         });
     },
  
  },
};
</script>

<style lang="scss" scoped></style>
