<template>
    <div>
      <div class="container-buttens">
        <button class="del-btn-ideal only-desktop" @click="delCompany()">מחיקת חברה</button>
        <button class="btn-ideal only-desktop" @click="addCompany()" >חברה חדשה</button>
        <button class="btn-idl only-mobile" @click="addCompany()" >
              <img src="@/assets/images/fi_plus.png" class="plus">
              <!--v-if="edit"-->
        </button>
      </div>
       <DxPopup
      v-model:visible="popupVisible"
      :drag-enabled="false"
      :close-on-outside-click="true"
      :show-close-button="false" 
      :show-title="true"
      :width="300"
      :height="400"
      container=".id-popup"
      title="הוספת חברה" 
       rtl-enabled="true"
    >
      <p>
        שם:
        <input v-model="newCompany.name">
      </p>
      <p>
      <DxFileUploader
        :rtl-enabled="true"
        :show-file-list="false"
        :upload-url="uploadURL + uploadQueryURL"
         @uploaded='fileReceived'
      />
      <img v-if="uploaded" :src="uploadAddress" style="max-width:200px;max-height:200px" />
       </p>
      <div class="btn-popup">
         <button @click="closePopup()"> סגור</button>
         <button @click="addNewCompany()"> הוסף</button>
      </div>
      </DxPopup>  
       <div class="company"  >
            <div v-for="company in companies" :key="company.companyID" @click="getPlansByCompany(company)">
              <img :src="mediaURL + company.logoPath"  class="logo-company"/>
            </div>
      </div>
    </div>
</template>

<script>
import axios from "axios";
import { DxFileUploader } from 'devextreme-vue/file-uploader';
import { DxPopup } from 'devextreme-vue/popup';


    export default {
      title:'חברות',
       name: 'Companies',
       props:{
        msg: String,
        value: Number,
        company: {}
    },
     components: {
   DxFileUploader,DxPopup
  },
  emits: ['update:value','update:company'],
      data(){
        return{
           companies:[],
           mediaURL:process.env.VUE_APP_BASE_MEDIA_URL,
           uploadURL:process.env.VUE_APP_BASE_URL,
           uploadQueryURL:"/FileUploader/Upload",
           uploadAddress:'',
           uploaded:false,
           popupVisible:false,
           newCompany:{
              companyId:0,
              name:'',
              logoPath:'',
            },
           edit:false

        }
    },
     mounted() {
        this.GetAllcompanies();
       

      },
     methods: {
         GetAllcompanies(){
              axios.get(process.env.VUE_APP_BASE_URL + "/Company/GetAllCompanies/")
              .then(res => {
                this.companies = res.data;
                console.log(res.data);  
         });
         },
          closePopup(){
           this.popupVisible=false;
         },
         addCompany(){
           this.popupVisible=true;
         },
           getUserGroup(){
           axios.get(process.env.VUE_APP_BASE_URL + "/User/GetUserGroupLoggedIn")
              .then(res => {
                 console.log(res);
                 if(res.data==1)
                   this.edit=true;        
         });
          },
         addNewCompany(){
            axios.post(process.env.VUE_APP_BASE_URL + "/Company/PostCompany",this.newCompany) 
             .then((res)=>{
               this.popupVisible=false;
               this.GetAllcompanies();
               })
             .catch(
              ()=>{
                   
             });
         },
           delCompany(company){
           axios.delete(process.env.VUE_APP_BASE_URL + "/Company/DeleteCompany/" + company.companyID)
           .then((res)=>{
              this.GetAllcompanies();
              
              console.log( res.data);

               })
             .catch(
              (e)=>{
             
            
             });
         },
         fileReceived(e){ 
            this.uploaded=true;
            this.uploadAddress=process.env.VUE_APP_BASE_MEDIA_URL + e.request.responseText; 
            console.log(this.uploadAddress);
            this.newCompany.logoPath=e.request.responseText;
            console.log(this.logoPath);           
         },
         getPlansByCompany(company){
                this.$emit('update:value',company.companyID);
                this.$emit('update:company', company);
         }
     }, 
        
    }
</script>

<style lang="scss" scoped>

</style>