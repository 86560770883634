<template>
    <div class="body">
       <img src="@/assets/images/logo.svg" class="logo-index"> 
    
            <div v-if="retrying">
                מנסה שוב להתחבר ...
            </div>

              <div @click="scanBC()">
          SCAN BARCODE
        </div>
           <div @click="checkNetwork()">
          CheckNetwork
        </div>
    </div>
    
</template>

<script>
import axios from "axios";

    export default {
        name: 'Index', 
         data(){
        return {
            retrying:false
        }
         },
  mounted() {
    this.tryToLogin();
  },
    methods:{
      checkNetwork(){
        alert(process.env.VUE_APP_BASE_URL);
        //fetch("http://desktop-9k6nv24/site/Login3.aspx")
      
        fetch(process.env.VUE_APP_BASE_URL + "/User/GetCurrentLoggedInUserID")
      .then(x=> alert(x))
      .catch(x=>alert(x)); 
            
      }
      ,
      scanBC(){
        if (window.cordova) {
        window.cordova.plugins.barcodeScanner.scan(
            function (result) {
                alert("We got a barcode\n" +
                      "Result: " + result.text + "\n" +
                      "Format: " + result.format + "\n" +
                      "Cancelled: " + result.cancelled);
            },
            function (error) {
                alert("Scanning failed: " + error);
            },
            {
                preferFrontCamera : true, // iOS and Android
                showFlipCameraButton : true, // iOS and Android
                showTorchButton : true, // iOS and Android
                torchOn: true, // Android, launch with the torch switched on (if available)
                saveHistory: true, // Android, save scan history (default false)
                prompt : "Place a barcode inside the scan area", // Android
                resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
                formats : "QR_CODE,PDF_417", // default: all but PDF_417 and RSS_EXPANDED
                orientation : "landscape", // Android only (portrait|landscape), default unset so it rotates with the device
                disableAnimations : true, // iOS
                disableSuccessBeep: false // iOS and Android
            }
        );
        }
      },


    tryToLogin(){
      
    console.log("trying to login");
      axios.get(process.env.VUE_APP_BASE_URL + "/User/GetCurrentLoggedInUserID")
      .then(res => {
       if (res.data==0)
         this.$router.push({ path: "Login"});
         else{
        this.$router.push({ path: "/app/sendIktov"});
         }
      })
      .catch(error => {
        alert.log(error)
        this.retrying=true;
        setTimeout(this.tryToLogin,1000);
      })
    }    
    }
    }
</script>

<style lang="scss" scoped>

</style>