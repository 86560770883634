<template>
  <div class="container-company-status"> 
    <div class="list-all-status only-desktop">
        <div
          v-for="status in statusesByUser"
          :key="status.status"
          class="container-status"
          @click="getListByStatus(status)"
         >
          <span>{{ status.amount }}</span>
          <div>
            <span :class="'circle status-' + status.status"></span>
            <div>
              {{ EnumManager.GetText(SaleDetailStatusMainEnum, status.status) }}
            </div>
          </div>
        </div>
        <div class="total-reward">
          <div>
          <div>
            <span>{{totalReward.toFixed(2)}}</span>
             <img src="@/assets/images/nis.svg" > 
          </div>
          <div>סך התגמול שלך
           <img src="@/assets/images/roundedArrow.svg"  class="roundedArrow"> 
          </div>         
          </div>
          <div class="container-expect">
            <span>צפי לחודש זה</span>
            <span>{{totalAvgReward.toFixed(2)}}</span>
          </div>
        </div>
    </div>
     <div class="list-all-status only-mobile" v-if="showStatuses" >
        <div
          v-for="status in statusesByUser"
          :key="status.status"
          class="container-status"
          @click="getListByStatus(status)"
         >
          <span>{{ status.amount }}</span>
          <div>
            <span :class="'circle status-' + status.status"></span>
            <div>
              {{ EnumManager.GetText(SaleDetailStatusMainEnum, status.status) }}
            </div>
          </div>
        </div>
      <div class="total-reward">
          <div>
            <div>
              <span>{{totalReward.toFixed(2)}}</span>
              <img src="@/assets/images/nis.svg" > 
            </div>
            <label>סך התגמול שלך</label>
          </div>
          <img src="@/assets/images/line_1.svg" > 
          <div>
            <div>
              <span>{{totalAvgReward.toFixed(2)}}</span>
              <img src="@/assets/images/nis.svg" > 
            </div>
            <label>צפי לחודש זה</label>
          </div>
      </div>
    </div>
    <div
        v-for="(company) in companies"
        :key="company.companyID" class="list-all-status only-mobile">
        <div
          v-if="selectedCompanyID == company.companyID"
          class="list-statuss">
          <div
            v-for="status in statuses"
            :key="status.status"
            class="container-status"
            @click="getStatus(company, status)"
            >
          <span>{{ status.amount }}</span>
            <div>
              <span :class="'circle status-' + status.status"></span>
                <div>{{ EnumManager.GetText(SaleDetailStatusMainEnum, status.status) }}</div>
            </div>
          </div>
          <div class="all-status">
            <div @click="allStatuses(company)" >
              <a>כל הסטטוסים</a>
              <img src="@/assets/images/chevron-right.svg" />
            </div>
          </div>
      </div>
    </div>
    <div class="lable-all-statuses" @click="forAllStatuses()">
       <label class="lable-all-statuses only-desktop">כל הסטטוסים</label> 
       <img src="@/assets/images/arrow-right.png" class="arrow-black only-desktop"> 
    </div>   
  <div  class="container-company only-mobile">
      <div class="company-header">
        <div class="company-mobile" ref="compContainer">
         <label  @click="showAllStatuses()" 
         :class="showStatuses ? 'lbl-mobile' : 'lbl-mobile-none'">הכל</label>
          <div
            v-for="(company, index) in companies"
            :key="company.companyID"
            :ref="setCompanyDivsM"
            @click="getAllStatuses(company.companyID, index,true)"
            :class="selectedCompanyID == company.companyID ? 'selected-company' : ''"
          >
            <div class="company-mobile"   
            :style="{ 'background-image': 'url(' + mediaURL + company.logoPath + ')' }">
            </div>
          </div>
        </div>
      </div>
  </div>
  <div class="container-company only-desktop">
    <div class="company-heade">
      <div class="company" ref="compContainer">
        <div v-for="(company, index) in companies"
            :key="company.companyID"
            :ref="setCompanyDivs"
            @click="getAllStatuses(company.companyID, index,false)"
            :class="selectedCompanyID == company.companyID ? 'selected-company' : ''"
        >
        <div class="company-mobile" :style="{ 'background-image': 'url(' + mediaURL + company.logoPath + ')' }">
        </div>
        <div v-if="selectedCompanyID == company.companyID"
         class="list-status" :style="{right: popup.right,}">
          <div
            v-for="status in statuses"
            :key="status.status"
            class="container-status"
            @click="getStatus(company, status)"
            >
            <span>{{ status.amount }}</span>
            <div>
              <span :class="'circle status-' + status.status"></span>
              <div>{{ EnumManager.GetText(SaleDetailStatusMainEnum, status.status) }}</div>
            </div>
          </div>
          <div class="all-status" @click="allStatuses(company)">
            <div >
              <a>כל הסטטוסים</a>
              <img src="@/assets/images/chevron-right.svg" />
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>  
</template>

<script>
import axios from "axios";
import { EnumManager, SaleDetailStatusMainEnum } from "../code/enums";

export default {
  title: "המכירות שלי",
  name: "MySales",
  props: {
    msg: String,
  },
  data() {
    return {
      companyDivs: [],
      companyDivsM: [],
      companies: [],
      statuses: [],
      statusesByUser:[],
      mediaURL: process.env.VUE_APP_BASE_MEDIA_URL,
      selectedCompanyID: -1,
      firstDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1,12,0),
      lastDate:new Date(new Date().getFullYear(), new Date().getMonth()+1,0),
      EnumManager,
      SaleDetailStatusMainEnum,
      totalReward:0,
      totalAvgReward:0,
      showStatuses:false,
      recordsByCompanyAndStatus:[],
      popup: {
        right: "0px",
      },
    };
  },
  mounted() {
    this.getAllStatusesByUserׁׁׂׂ();
    this.getAllcompanies();
    console.log(this.firstDate);
     console.log(this.lastDate);
    this.getTotalReward();
    this.getTotalAvgReward();
  },
  beforeUpdate() {
    this.companyDivs = [];
    this.companyDivsM = [];
  },
  updated() {
    console.log(this.companyDivs);
  },
  methods: {
    getAllcompanies() {
      axios
        .get(process.env.VUE_APP_BASE_URL + "/Company/GetAllCompanies/")
        .then((res) => {
          this.companies = res.data;
          console.log(res.data);
        });
    },
    setCompanyDivs(el) {
      if (el) {
        this.companyDivs.push(el);
      }
    },
     setCompanyDivsM(el) {
      if (el) {
        this.companyDivsM.push(el);
      }
    },
    allStatuses(company){ 
           this.$router.push({ path: "/app/SaleDetails",
                                    query: { companyID  : company.companyID}
                }); 
    },
    getStatus(company,status){ 
      console.log(status);
       this.$router.push({ path: "/app/SaleDetails",
                            query: { companyID  : company.companyID , status : status.status}
                });    
    },
    getListByStatus(status){
        this.$router.push({ path: "/app/SaleDetails",
                            query: {status : status.status}
                });    
    },
    forAllStatuses(){
          this.$router.push({ path: "/app/SaleDetails"});   
    },
    getAllStatusesByUserׁׁׂׂ(){
      axios
        .get(process.env.VUE_APP_BASE_URL + `/Sale/GetAllDetailSammary?firstDate=${this.firstDate.toISOString().slice(0, 10)}`)
        .then((res) => {
          this.statusesByUser = res.data;
          console.log(res.data);
        });
    },
    getAllStatuses(companyID, index,mobile) {
      if (companyID == this.selectedCompanyID) {
        this.selectedCompanyID = -1;
        return;
      } 
      else {
        if (this.showStatuses){
          this.showStatuses=false;
        }
        this.selectedCompanyID = companyID;
        if (!mobile) {
             const d= this.companyDivs[index];
              var rect = d.getBoundingClientRect();
              var containerRect = this.$refs.compContainer.getBoundingClientRect();
              console.log("rects", d, rect);
              this.popup.right =
                -(containerRect.left + containerRect.width - (rect.left + rect.width)) + "px";
          }
        console.log(this.popup);
      }
      this.statuses = [];
      axios
        .get(
          process.env.VUE_APP_BASE_URL +
            `/Sale/GetAllDetailSammary?companyID=${
              this.selectedCompanyID
            }&firstDate=${this.firstDate.toISOString().slice(0, 10)}`
        )
        .then((res) => {
          this.statuses = res.data;
          console.log(res.data);
          
        });
    },
    showAllStatuses(){
      if (!this.showStatuses){
        this.selectedCompanyID=0;
      }
      this.showStatuses=!this.showStatuses;
      
    },
     getTotalReward() {
       //send 4 months before
      var url = `/Sale/GetRewardByUser?firstDate=${this.firstDate.toISOString().slice(0, 10)}`;
      axios.get(process.env.VUE_APP_BASE_URL + url)
      .then((res) => {
       this.totalReward=res.data;
      }); 
    },
     getTotalAvgReward() {
      var url = `/Sale/GetAvgRewardByUser?firstDate=${this.firstDate.toISOString().slice(0, 10)
      }&lastDate=${this.lastDate.toISOString().slice(0, 10)}`;
      axios.get(process.env.VUE_APP_BASE_URL + url)
      .then((res) => {
       this.totalAvgReward=res.data;
      }); 
    },
  },
};
</script>

<style lang="scss" scoped></style>
