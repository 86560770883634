<template>
   <div class="container-table-grid">
    <DxDataGrid
      :data-source="dataSource"
      :show-borders="false"
      :rtl-enabled="true"
      :remoteOperations="false"
      :allowColumnResizing="true"
      :show-column-lines="false"
      :show-row-lines="true"
      :column-hiding-enabled="true"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      class="tree-list"
      @exporting="onExporting"
      @exported="onExported"
      > 
      <DxExport
      :enabled="true" 
      />     
      <DxEditing
        :allow-deleting="true"
        :allow-updating="true"
        :allow-adding="true"
        mode="row"
      >
      <DxTexts
        delete-row="מחק"
        edit-row="ערוך"
        save-row-changes="אישור"
        cancel-row-changes="ביטול"
      />
      </DxEditing>
      <DxFilterRow :visible="true" alignment="right" />
      <DxHeaderFilter :visible="true"  />
      <DxColumn data-field="feeName" caption="סוג תשלום" :min-width="150" alignment="right"/>
      <!-- <DxColumn data-field="feeValue_ManagerSystem" caption="תשלום למנהל מערכת" :width="180" alignment="right"  /> -->
      <DxColumn data-field="feeValue_Meaktev" caption="תשלום למאקטב" :min-width="150" alignment="right" :hiding-priority="4" />
      <DxColumn data-field="feeValue_Representative" caption="תשלום לנציג" :min-width="150" alignment="right" :hiding-priority="5"/>
      <DxColumn data-field="feeValue_MeaktevAllActivities" caption="תשלום למנהל כל הפעילויות" :min-width="150" alignment="right" :hiding-priority="1"/>
      <DxColumn data-field="feeValue_activityManager" caption="תשלום למנהל פעילויות" :min-width="150" alignment="right" :hiding-priority="0"/>
      <DxColumn data-field="feeValue_ManagerShop" caption="תשלום למנהל חנות" :min-width="150" alignment="right" :hiding-priority="2"/>
      <DxColumn data-field="feeValue_ManagerSystem" caption="תשלום למנהל מערכת" :min-width="150" alignment="right" :hiding-priority="3"/>
    </DxDataGrid>
  </div>
</template>

<script>
import { createStore } from "devextreme-aspnet-data-nojquery";
import {
  DxDataGrid,
  DxColumn,
  DxHeaderFilter,
  DxFilterRow,
  DxEditing,
  DxTexts,
  DxExport
} from "devextreme-vue/data-grid";
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
const url = process.env.VUE_APP_BASE_URL + "/FeeGrid";
const dataSource = createStore({
  key: "feeID",
  loadUrl: `${url}/LoadData`,
  insertUrl: `${url}/Insert`,
  updateUrl: `${url}/Update`,
  deleteUrl: `${url}/Delete`,
  onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =     { "Authorization" : localStorage.getItem('jwtToken') };
  },
});
    export default {
       title:'תבניות תגמול',
       name: 'Fees',
       props: {
       msg: String
  },
  components: {
    DxDataGrid,
    DxHeaderFilter,
    DxColumn,
    DxFilterRow,
    DxEditing,
    DxTexts,
    DxExport
  }, 
  emits: ['update:value'],
  data() {
      return {
          dataSource
      }
  },
  methods: {
      onExporting(e) {
      e.component.beginUpdate();  
      e.component.columnOption(0, "visible", true);
      e.component.columnOption(1, "visible", true);  
      e.component.columnOption(2, "visible", true);  
      e.component.columnOption(3, "visible", true);
      e.component.columnOption(4, "visible", true);  
      e.component.columnOption(5, "visible", true);  
      e.component.columnOption(6, "visible", false);
      e.component.columnOption(7, "visible", false);    
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('תבניות תגמול');

      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'תשלומים.xlsx');
        });
      }); 
      e.cancel = true;    
    }
  },       
    }
</script>

<style lang="scss" scoped>

</style>