<template>
    <div :key='componentKey' :class="collapsed?  collapsedCss:expandedCss">
        <a>
            <img src="@/assets/images/chevron-right.svg" @click="toggle()" v-if="collapsed" class="scroll">
            <img src="@/assets/images/scroll.png" @click="toggle()" v-if="!collapsed" class="scroll">
        </a>
       {{ title }}
        <div>
         <hr class="hr-border">
        </div>
        <div v-if="!collapsed">
            <slot >
            </slot>
        </div>
    </div>
</template>

<script>
    export default {
        name:'UICollapsiblePanel',
        props:{
            title:String,
            collapsedLoad:{
                type: Boolean,
                required: false
                },
            collapsedCss: {
                type: String,
                required: false
                },
                expandedCss: {
                type: String,
                required: false
                }, 
        },
        data() {
            return {
                collapsed: false,
                componentKey: 0
                }
            }        ,
            mounted() {
                this.collapsed=this.collapsedLoad;
                this.componentKey++;
            },
        methods:{
             toggle() {
                this.collapsed=!this.collapsed;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>