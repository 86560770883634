
 export class Validations {

    static validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      console.log(re);
      return re.test(email);
    }
         
    static is_valid_israel_id(str) {
      var IDnum = String(str);
      if ((isNaN(IDnum)) || (IDnum.length > 9) || (IDnum.length < 5))
          return false;
      if (IDnum.length < 9) {
          while (IDnum.length < 9) {
              IDnum = '0' + IDnum;
          }
    }
    var mone = 0,
        incNum;
    for (var i = 0; i < 9; i++) {
        incNum = Number(IDnum.charAt(i));
        incNum *= (i % 2) + 1;
        if (incNum > 9)
            incNum -= 9;
        mone += incNum;
       }
       return (mone % 10 === 0);
    }

    static is_valid_card_genery(card) {
        var credit = String(card);
        if (credit.length === 0) {
            return false;
        }
        var x, y, i;
        var sumAll = 0;
        //כרטיס בין 11 ל19 תוים נבדק לפי אלגוריתם המשמש לבדיקת תקינות של ת.ז.
        if (credit.length > 10 && credit.length < 20) {
            y = 1; //משתנה שנועד להגדיר האם להכפיל ב1 או ב2
            for (i = credit.length; i > 0; i--) {
                x = parseInt(credit.substr(i - 1, 1));
                if (y % 2 === 0) {
                    x = x * 2;
                    if (x > 9)
                        x = (x % 10) + 1;
                    sumAll += x;
                    y++;
                } else {
                    x = x * 1;
                    sumAll += x;
                    y++;
                }
            }
            sumAll = sumAll % 10;
            return (sumAll % 10 === 0);
        }
        //אם אורך המספר בין 8 ל9 תווים - מדובר בכרטיס מסוג ישראכרט והאלגוריתם שונה
        else if (credit.length == 8 || credit.length == 9) {
            y = 1; //מספר הכפל כדי לבדוק אימות כרטיס
            //אם הכרטיס באורך 8 ספרות - מוסיף לו 0 משמאל לצורך תקינות הבדיקה
            if (credit.length == 8) {
                credit = "0" + credit;
            }
    
            for (i = credit.length; i > 0; i--) {
                x = parseInt(credit.substr(i - 1, 1));
                x = x * y;
                sumAll += x;
                y++;
            }
            return (sumAll % 11 === 0);
        }
        //אם המספר ארוך מדי או קצר מדי - מדובר במס' שגוי
        else
            return false;
    }
    static validate_by_regex(str, regex) {
        return regex.test(str);
    }
    static is_valid_cvv(str) {
        return this.validate_by_regex(str, /^([0-9]{3,4})$/);
    }
    static is_valid_userName(str) {
        return this.validate_by_regex(str, /^[a-z\u05D0-\u05EA0-9\-._@+)( ']+$/i)
    }
    static is_valid_mobile_phone(str) {
        return this.validate_by_regex(str, /^([0-9]{9,10})$/);
    }
    static is_valid_port_number(str) {
        return this.validate_by_regex(str, /^([0-9]{10})$/);
    }
    static is_valid_text_hebrew(str) {
        return this.validate_by_regex(str, /^['" א-ת]+$/);
    }
    
    static is_valid_luhn(str2) {
       var str2check = String(str2);
        var total = 0;
        var alt = false;
        var digits = str2check;
        for (var i = digits.length; i > 0; i--) {
            var curDigit = parseInt(digits.substr(i - 1, 1));
            if (alt) {
                curDigit *= 2;
                if (curDigit > 9)
                    curDigit -= 9;
            }
            total += curDigit;
            alt = !alt;
        }
        return (total % 10 === 0);
    }

    static is_valid_sim_card(str2, company) {
       var str2check = String(str2);
        if (str2check.length === 0)
            return false;
        var r_valid = this.validate_by_regex(str2check, /^[0-9]{18,20}$/);
        r_valid = r_valid && this.is_valid_luhn(str2check);
        //var r_valid = true;
        if (r_valid) {
            switch (company) {
                case "גולן":

                    break;
                case "סלקום":
                    //r_valid = validate_by_regex(str2check, /(?!^[0-9]{7}(0108|0118|0138|0308|0328|0338|0378|0398|0688|0678|0178|0698)[0-9]{7}$)^[0-9]{18}/) || validate_by_regex(str2check, /^[0-9]{7}(0108|0118|0138|0308|0328|0338|0378|0398|0688|0678|0178|0698)[0-9]{7}$/);
                    r_valid = this.validate_by_regex(str2check, /(^8997202)([0-9]{11}$)/);
                    break;
                case "פרטנר":
    
                    break;
                case "הוט":
                    r_valid = this.validate_by_regex(str2check, /(^8997207)([0-9]{11,13}$)/);
                    break;
                case "פלאפון":
    
                    break;
                case "012":
    
                    break;
                default:
                    break;
            }
        }
        return r_valid;
    }

    static validateNumber(num) {
        var re = /^[0-9]\d*$/;
        return re.test(num);
    }

    static is_valid_imei(str2) {
        var str2check=str2;
        if (str2check.length === 0)
            return false;
        var r_valid = this.validate_by_regex(str2check, /^[0-9]{15}$/);
        r_valid = r_valid && this.is_valid_luhn(str2check);
        return r_valid;
    }
    
    
    static is_start_with(str, arr) {
           
        for (var i = 0; i < arr.length; i++) {
           if (str.startsWith(arr[i])) {
              return true;
        }
    }
    return false;
}

static is_kosher_imei(imei_number) {
    var all_companies_array = [
        "35642105",
        "35810205",
        "35932906",
        "352377060",
        "352382060",
        "354904070",
        "354905070",
        "35693409",
        "35769807",
        "35237706",
        "354083040",
        "35480208",
        "35238206",
        "35490407",
        "35490507",
        "35518904",
        "358102056",
        "35195305",
        "35194206",
        "35613505",
        "86079504",
        "35085960",
        "35746405"
    ];
    var c_p = ["35577802", "35360404"]; //Cellcom + Partner
    var c_p_g_h = ["35704601", "35746405"]; //Cellcom + Partner + Golan + Hot
    var c_p_g = ["35268804", "040354083"]; //Cellcom + Partner + Golan
    var c_p_r_p = ["3579110", "3579220"]; //Cellcom + Partner + Ramilevi + Pelephone

    if (!this.is_valid_imei(imei_number))
        return { message:"טעות בהקלדה - מספר לא תקין", code: -1};
    if (this.is_start_with(imei_number, all_companies_array))
        return { message:"כשר - תומך כל החברות", code: 0};
    if (this.is_start_with(imei_number, c_p))
        return { message:"כשר חלקי - תומך סלקום ופרטנר", code: 1};
    if (this.is_start_with(imei_number, c_p_g_h))
        return { message:"כשר חלקי - תומך סלקום פרטנר גולן והוט מובייל", code: 1};
    if (this.is_start_with(imei_number, c_p_g))
        return { message:"כשר חלקי - תומך סלקום פרטנר וגולן", code: 1};
    if (this.is_start_with(imei_number, c_p_r_p))
        return { message:"כשר חלקי - תומך סלקום פרטנר רמי לוי ופלאפון", code: 1};
    return { message:"מכשיר לא כשר", code: 2};
}

    
    
      
    
    }