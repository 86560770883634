<template>
  <div class="body">
    <form @submit="sendPassword">
      <div class="container-login">
      <div class="login-form">
        <img src="@/assets/images/logo.svg" class="logo only-desktop">
        <img src="@/assets/images/logo-mobile.svg" class=" logo-mobile only-mobile">
        <div class="message">הכנס שם משתשמש ואימייל</div>
        <input placeholder="שם משתמש" v-model="userName" @focus="hideMessage()" @keyup="checkValidateUserName()" :class="isValidateUserName?'':'red-border'" />
        <input placeholder="אימייל" v-model="email" @focus="hideMessage()" @keyup="checkValidEmail()" :class="isValidEmail?'':'red-border'" />
        <button class="btn-login">שלח סיסמה</button>
        <div v-if="message" class="message">
          ברגעים אלו נשלח קישור לחידוש הסיסמה לאימייל שלך
        </div>
        <div v-if="notExists" class="message">
          שם משתמש ואימייל אינם תקינים!
        </div>
      </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { Validations } from "../../code/validations";
export default {
  name: "ResetPassword",
  data() {
    return {
      userName: "",
      email: "",
      message: false,
      notExists: false,
      Validations,
      isValidateUserName:true,
      isValidEmail:true
    }
  },
   
  methods: {
    sendPassword(e) {
      e.preventDefault();
      axios
        .post(
          process.env.VUE_APP_BASE_URL +"/User/ForgotPassword/" + encodeURI(this.userName) + "/" +  encodeURI(this.email)
        )
        .then((res) => {
          console.log(res);
          this.message = true;
          this.notExists = false;

        })
        .catch((e) => {
          console.log(e);
          this.notExists = true;
          this.message = false;

        });
    },
    hideMessage(){
          this.notExists = false;
    },
     checkValidateUserName(){
      this.isValidateUserName=this.userName=='' || this.Validations.is_valid_userName(this.userName);
    },
    checkValidEmail(){
      this.isValidEmail=this.email=='' || this.Validations.validateEmail(this.email);
    },
   
  }
};
</script>

<style lang="scss" scoped>
</style>