<template>
   <div class="tabs"> 
      <div  v-for="item in listToDisplay" :key="item.key"  @click="Navigate(item.route)"
        :class="(currentPath.endsWith(item.route)? 'tab-active':'')" >
        <label>{{item.text}}</label>
      </div>
   </div> 
</template>

<script>
     export default {
        name:"UITabsGroup",
        props: {
        list:Array
        },
        data() {
            return {
                listToDisplay:[],
                currentPath: ''
            }
        },
        mounted() {
             this.listToDisplay=this.list;      
        },
        methods: {
            Navigate(route){
              this.$router.push({ path:route}).then(()=> this.currentPath= window.location.href); 
             },
        },
    }
</script>

<style lang="scss" scoped>

</style>