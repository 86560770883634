<template>
   <div class="tabs"> 
      <div  v-for="item in listToDisplay" :key="item.key"  @click="selectItem(item)"
        :class="(value==item.key? 'tab-active':'')" >
        <label>{{item.text}}</label>
      </div>
 </div> 
</template>

<script>

import {useRoute} from 'vue-router'

    export default {
        name:"UIInlineTab",
        props: {
            list:Array,
            value:String
        },
         emits: ['update:value'],
       data() {
            return {
                listToDisplay:[],
                currentPath: '',
                Refresh:0
            }
        },
        mounted() {
             this.listToDisplay=this.list;      
        },
        methods: {
           selectItem(item){
                this.$emit('update:value',item.key);
               // this.value=item.key;
           }
        },
    }
</script>

<style lang="scss" scoped>

</style>