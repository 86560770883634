 const UserGroupsEnum=[
    { value:0, text:"מאקטב"},
    { value:1, text:"מנהל מערכת"},
    { value:2, text:"נציג"},
    { value:3, text:"מנהל כל הפעילויות"},
    { value:4, text:"מאקטב כל הפעילויות"},
    { value:5, text:"מנהל פעילות"},
    { value:6, text:"מנהל חנות"}
] ;
 const UserStatusEnum=[
    { value:0,text:"ממתין לאישור"},
    { value:1,text:"מאושר"},
    { value:2,text:"דחוי"},
    { value:3,text:"לא פעיל"}
] ;
const isKosherEnum=[
    { value:false,text:"לא כשר"},
    { value:true,text:"כשר"}
] ;
const SaleDetailStatusEnum=[  
    { value:100 , text:'Activated_Complete' } ,
    { value:101 , text:'Activated_WaitForNyud'  } ,
    { value:102 , text:'Activated_NyudInLater'  } ,
    { value:103 , text:'Activated_FurtherTreatment'  } ,
    { value:104 , text:'Activated_ActivatedIndependent'  } ,
    { value:200 , text:'NotActivated_TransferredForTreatmentUser' } ,
    { value:201 , text:'NotActivated_CustomerRegretted'  } ,
    { value:300 , text:'OnActivation_WaitingForActivation'  } ,
    { value:301 , text:'OnActivation_WaitingForAutomation'  } ,
    { value:302 , text:'OnActivation_WaitingForSending'  } ,
] ;
 
const SaleDetailStatusMainEnum=[  
    { value:100 , text:'אוקטב'  } ,
     { value:200 , text:'לא אוקטב' } ,
     { value:300 , text:'בתהליך איקטוב'  } , 
] ;


export class EnumManager {

    static GetText(Enum,value) {
        return Enum.find(x=>x.value==value).text;
    }

}


export {UserGroupsEnum,UserStatusEnum,SaleDetailStatusEnum,SaleDetailStatusMainEnum,isKosherEnum}