<template>
  <div class="body">
   <label class="version">מספר גירסה: {{globalAppVersion}}</label>
    <form @submit="sendLogin">
      <div class="container-login">
      <div class="login-form">
        <img src="@/assets/images/logo.svg" class="logo only-desktop">
        <img src="@/assets/images/logo-mobile.svg" class="logo-mobile only-mobile">
        <input placeholder="שם משתמש" v-model="login" @focus="hideMessage()">
        <input placeholder="סיסמה" v-model="password" type="password" @focus="hideMessage()">
        <button class="btn-login">כניסה</button>
        <div v-if="haveError" class="message" >{{error}}</div>
        <div class="login">
          <label class="lbl" @click="forgotPassword()"> שכחתי סיסמה :(</label>
          <div class="sign-up">
            <label>אין לך חשבון?</label>
            <a class="link" @click="signUp()">לחץ כדי להרשם</a>
            <img src="@/assets/arrow.svg" class="arrow">
          </div>
        </div>
      </div>
      </div>
    </form>
  </div>
</template>

<script>
  import axios from "axios";
 
  export default {
    title:'כניסה',
    name: 'LOGIN',
    props: {
      msg: String, 
    },
    data() {
      return {
        login: '',
        password: '',
        remember: true,
        haveError: false,
        error:'',
        globalAppVersion:'',
        logout:0,
      
      }
    }, 
    mounted() {
      this.globalAppVersion =localStorage.getItem("globalAppVersion"); 
     
    },   
    methods: {
      sendLogin(e) {
        e.preventDefault();
        axios.post(process.env.VUE_APP_BASE_URL + "/User/Login", {
            userName: this.login,
            password: this.password,
            remember: this.remember
          }).then((res) => {
             console.log(res.data)
             let jwtToken=res.data.token;
             res.data.token='';
             console.log("jwtToken");
             console.log(res.token);

             localStorage.setItem("userInfo",JSON.stringify(res.data));
             localStorage.setItem("jwtToken",'Bearer ' + jwtToken);
             axios.defaults.headers.Authorization =  localStorage.getItem('jwtToken')

             this.$router.loggedInInfo.isLogged=true;
             this.$router.push({ path: "/app/sendIktov"});      
          })
          .catch(
            (e) => {
              console.log(e); 
              this.haveError = true;
              this.error=e.response.data;
              // this.forgotPassword=false;
            }
          );
      },
      signUp() {
        this.$router.push({ path:"/Register"});
      },
      forgotPassword(){
        this.$router.push({ path:"/ResetPassword"});
      },
      hideMessage(){
        this.haveError = false;
      }
              
      
      },

  }
</script>