<template>
  <div>
     <Fees v-if="state==1"  @update:value="selectedFee()" ></Fees>
     <Special-Reward-Templates > </Special-Reward-Templates>
  </div>
</template>

<script>
import Fees from './Fees.vue';
import SpecialRewardTemplates from './SpecialRewardTemplates.vue';

export default {
  components: {Fees,SpecialRewardTemplates},
      title:'תגמול',
       name: 'RewardPatterns',
       props:{
        msg: String
    },
     data() {
       return {
          state:1,
       }
     },
}
</script>

<style lang="scss" scoped>

</style>