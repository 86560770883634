<template>
    <div>
      <div class="container-radios-sendMessage">
        <div class="container-sendMessage">
          <label class="container-radio">
            <input
              type="radio"
              name="radio"
              value="allUsers"
              v-model="sendMessagesTo"  
            />
            <span class="checkmark"></span>
            <label>שליחה לכל המשתמשים</label>
          </label>
        </div>
        <div class="container-sendMessage">
          <label class="container-radio">
            <input
              type="radio"
              name="radio"
              value="activityType"
              v-model="sendMessagesTo" 
          />               
          <span class="checkmark"></span>
          <label >שליחה לפי סוג משתמש</label>
          </label>
          <UISelect v-if="sendMessagesTo=='activityType'" placeholder="בחר סוג משתמש" :list="activityTypes" 
              v-model:value="selectedActivityTypeID" 
               :key="index">
             </UISelect>
        </div>
         <div class="container-sendMessage">
          <label class="container-radio">
            <input
              type="radio"
              name="radio"
              value="sendSpecificUser"
              v-model="sendMessagesTo" 
          />               
          <span class="checkmark"></span>
          <label >שליחה למשתמש ספציפי</label>
          </label>
           <lookupContainer style="width: 300px">
              <DxLookup v-if="sendMessagesTo=='sendSpecificUser'" 
                :items="users"
                :key="componentKey"
                searchExpr="fullName"
                searchPlaceholder="חפש"
                placeholder="בחר"
                :rtlEnabled="true"
                class="input-css"
                v-model:value="selectedUserID"
                value-expr="id"
                display-expr="name"
              >
                <DxDropDownOptions
                  :show-title="false"
                  :close-on-outside-click="true"
                />
              </DxLookup>
            </lookupContainer>
        </div>
      </div>
      <div>
        <textarea placeholder="הודעה" class="input-comment" v-model="message" @click="writeMessage()" ></textarea>
        <button @click="sendMessage()" class="btn-login">שלח</button>    
      </div>
      <div v-if="haveError" class="message">חובה למלא את החלק של ההודעה !</div>
      <div v-if="successfully" class="message">ההודעה נשלחה בהצלחה:)</div>
    </div>
</template>

<script>
    import UISelect from "./UIComponents/UISelect.vue";
    import { DxLookup, DxDropDownOptions } from "devextreme-vue/lookup";
    import axios from "axios";
    export default {
      name: 'Messages',
       props: {
       msg: String
      },
     components: {
      UISelect,
      DxLookup,
      DxDropDownOptions
     },
      data() {
        return {
          users: [],
          sendMessagesTo:'allUsers',
          componentKey: 0,
          activityTypes:[],
          index:1,
          selectedActivityTypeID:null, 
          selectedUserID:null, 
          selected:false,
          message:'',
          successfully:false,
          haveError:false
        }
      }, 
      mounted() {
        this.getAllActivityTypes();
        this.getAllUsers();
      }, 
      methods: {
        getAllActivityTypes() {
          axios.get(process.env.VUE_APP_BASE_URL + "/ActivityType/GetAllActivityTypes").then((res) => {
            console.log(res.data);
          this.activityTypes = res.data.map(function (at) {
            return { text: at.name, key: at.activityTypeID };
         });
           this.index++;
        });
        },
      //   refreshComponent(activityType){
      //  this.activityType.name=activityType.text;
      //  console.log(activityType);
      //  this.index++;
      //  this.selected=true;
      //  },
    sendMessage(){
      if(this.message=="")
      {
        this.haveError=true;
        this.successfully=false;
      }
      else{
      this.haveError=false;
      var id=null;
      console.log(this.sendMessagesTo);
      if(this.sendMessagesTo=='allUsers')
        id=0;
      else if(this.sendMessagesTo=='activityType')
        id=this.selectedActivityTypeID;
      else{
        id=this.selectedUserID;
      } 
        axios.post(process.env.VUE_APP_BASE_URL + "/Notification/PostNotification",{
            senderType:this.sendMessagesTo,
            id:id,
            message:this.message
          }).then((res)=>{
            console.log( res.data);
            console.log( this.successfully);
            this.successfully=true;      
           })
           .catch(
            (e)=>{
                  
             });
     }
    },
    writeMessage(){
      this.haveError=false;
    },
    getAllUsers() {
      axios
        .get(process.env.VUE_APP_BASE_URL + "/User/GetListOfAllUsers")
        .then((res) => {
          console.log(res);
          this.users =res.data;
          this.componentKey++;
        });
    },
      },   
    }
</script>

<style lang="scss" scoped>

</style>