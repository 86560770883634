<template>
       <div class="content-container-inventory">
        <!-- <div>
            <label class="title-next-a-button">כמות כרטיסי סים במלאי</label>
            <button class="order-cards"> הזמן כרטיסים</button>
        </div>
        <div class="date-order">
            <label class="label-date">תאריך הזמנה אחרון</label>
            <label class="label-date">01/01/2020</label>
        </div>
        <div class="title-card">
            <label class="title-next-a-button">כמות כרטיסי סים כשרים</label>
            <hr class="hr-border">
        </div>
        <div class="cards-sim">
            <div>
                <div class="amount">
                    <label >6</label>
                </div>
                <div class="name-company">
                    <label >סלקום</label>
                </div>
            </div>
        <div class="title-card">
            <label class="title-next-a-button">כמות כרטיסי סים לא כשרים</label>
            <hr class="hr-border">
        </div>
       <div class="cards-sim">
            <div>
                <div class="amount">
                    <label >6</label>
                </div>
                <div class="name-company">
                    <label >סלקום</label>
                </div>
            </div>
        </div>

    </div> -->
    </div>
  
</template>

<script>
    export default {
       title:'ניהול מלאי',
       name: 'InventoryManagement',
       props: {
         msg: String
  }    
    }
</script>

<style lang="scss" scoped>

</style>