<template>
    <div>
     <div @click="exportToExcel()" class="exportButton"> 
        <div>ייצוא לאקסל</div>  
        <img src="@/assets/images/exel.svg" />
    </div>
      <DxTreeList
        :data-source="data"
        :show-row-lines="true"
        :show-borders="true"
        :column-auto-width="true"
        :word-wrap-enabled="true"
        key-expr="treeKey"
        parent-id-expr="parentTreeKey"
        has-items-expr="hasItems"
        :rtl-enabled="true"
        :column-hiding-enabled="true"
        class="tree-list"
        @cell-prepared="onCellPrepared"
        ref="tList"
       >
         <DxColumn
          width="105px"
          data-field="userName"
          caption="שם נציג"
          :hiding-priority="5"
         >
         </DxColumn>
          <DxColumn
           width="105px"
          data-field="ictobimInCurrentMonth"
          caption="איקטובים"
          :hiding-priority="4"
        >
         </DxColumn>
          <DxColumn
          width="105px"
          data-field="projIctobim"
          caption="צפי חודשי"
          :hiding-priority="3"
         >
         </DxColumn>
          <DxColumn
          width="105px"
          data-field="ictobimInLastMonth"
          caption="חודש קודם"
          :hiding-priority="2"
         >
         </DxColumn>
          <DxColumn
          width="105px"
          data-field="remainder"
          caption="הפרש"
          :hiding-priority="1"
         >
         </DxColumn>
          <DxColumn
          width="105px"
          cell-template="difference"
          data-field="relativeDifference"
          caption="הפרש יחסי"
          :hiding-priority="0"
         >
         </DxColumn>
         <template #difference="{data : rowInfo }"> 
           <span v-if='rowInfo.data.relativeDifference!=0' dir="ltr" >{{rowInfo.data.relativeDifference}}%</span> 
             <span v-else >{{rowInfo.data.relativeDifference}}</span> 
         </template>
         
    </DxTreeList>
    </div>
</template>

<script>
import axios from "axios";
import { DxTreeList,DxColumn } from 'devextreme-vue/tree-list';

export default {
  components: {DxTreeList,DxColumn},
  title:'עליות וירידות',
  name: 'UpsAndDowns',
  props: {
  msg: String
  },
  data() {
      return {
       data:[],
       start:new Date(),
         
      }
  },
  methods: {
      GetIctobimForUser(){
          var url= `/Sale/GetAllIctobimForUser?firstDate=${this.start.toISOString().slice(0, 10)}`;
          console.log("chng");
          axios.get(process.env.VUE_APP_BASE_URL + url )
          .then(res => {
            console.log("rewLoaded");
            console.log(res);  
            this.data = res.data;
     });
     },
     onCellPrepared(e){
       if(e.rowType == "data" && e.column.dataField ==="relativeDifference" ) 
         if( e.data.relativeDifference == 100 &&  e.data.ictobimInLastMonth == 0 )
            e.cellElement.style.color ="#0932a5";
     },
    exportToExcel(e) {  
            var treeList =this.$refs.tList.instance;
            // var tv = $("tree-list").dxTreeList("instance");  
            var columns = treeList.getVisibleColumns();  
            var data = treeList.getVisibleRows();  

            var csvContent = "";  
            var i=0,row;
            
            for (  i = 0; i < columns.length; i++) {  
                csvContent += columns[i].caption + ",";  
                }  
            csvContent += "\r\n";  

            for ( i = 0; i < data.length; i++) {  
                row = data[i].values;  
                row.forEach(function (item, index) {  
                    if (item === undefined || item === null) { csvContent += ","; }  
                    else { csvContent += item + ","; }  
                   }  
                );  
                csvContent += "\r\n";  
            }  

            var blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });  

            if (navigator.msSaveBlob) {  
                navigator.msSaveBlob(blob, 'עליות וירידות.xlsx')  
            }  
            else {  
                var link = document.createElement("a");  
                var url = URL.createObjectURL(blob);  
                link.setAttribute("href", url);  
                link.setAttribute('download', 'עליות וירידות.xlsx');  
                document.body.appendChild(link);  
                link.click();  
                document.body.removeChild(link);  
            }
        }  
  },
  mounted() {
      this.GetIctobimForUser();
  },  
    }
</script>

<style lang="scss" scoped>

</style>